import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { getMarketoverview } from "../../../store/actions";
import LineColumnArea from "../../AllCharts/apex/LineColumnArea";
import AdminDrivingScoreController from "../../../controller/Dashboard/AdminDasahboardController.js";
import ValueFormat from "../../../util/ValueFormat";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Loader from "../../../components/Common/loader";
import { displayText } from "../../../constant/MessageConstant";
import Converter from "../../../components/Common/EncodeDecode.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";

const DrivingBehaviour = ({ loaderCount, filter, selectedUserValue }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState("ALL");

  const { Marketoverview } = useSelector((state) => ({
    Marketoverview: state.dashboard.Marketoverview,
  }));

  const onChangeHandle = (data) => {
    setState(data);
    dispatch(getMarketoverview(data));
  };

  useEffect(() => {
    dispatch(getMarketoverview(state));
  }, [state]);

  useEffect(() => {
    dispatch(getMarketoverview("All"));
  }, [dispatch]);

  const previous = () => {
    setIndexStart((prev) => prev - pageSize);
  };
  const next = () => {
    setIndexStart((prev) => prev + pageSize);
  };

  // const [filter, setFilter] = useState("CURRENT_YEAR");
  const [xAxisData, setXAxisData] = useState([]);
  const [multiUserDrivingData, setMultiUserDrivingData] = useState([]);
  const pageSize = 25;
  const [indexStart, setIndexStart] = useState(0);
  const [dataLength, setDataLength] = useState(null);
  const [prevShow, setPrevShow] = useState();
  const [nextShow, setNextShow] = useState();
  const [showLoader, setShowLoader] = useState(false);
  const [dbComparisonData, setDbComparisonData] = useState(false);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [rideName, setRideName] = useState([]);

  const getUserBoardData = async (indexStartOfSet) => {
    setDbComparisonData(null);
    // setShowLoader(true)
    const transportType = selectedUserValue;
    loaderCount((current) => current + 1);
    var result =
      await AdminDrivingScoreController.multiUserDrivingScoreController(
        filter.durationFilter,
        indexStart,
        pageSize,
        filter.startDateTime,
        filter.endDateTime,
        transportType
      );
    //    setShowLoader(false)
    loaderCount((current) => current - 1);
    var resultJSON = JSON.parse(result);
    console.log(resultJSON,"hgfghgffxfgxfx")
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      try {
        var resultJSONData = resultJSON.data;
        // console.log("getAdminBoardData: " + JSON.stringify(resultJSONData));
        var rideList = [];
        var multiUserDrivingDataList = [];
        var multiUserDrivingObject = {};
        var multiUserData = [];
        var rideNameList = [];
        if (resultJSONData.length > 0) {
          var maxDrivingScoreCount = 0;
          // setDataLength(resultJSONData.length)
          resultJSONData.map((apiDataTemp) => {
            multiUserDrivingObject = {};
            multiUserData = [];

            var firstName = ValueFormat.parseAsString(
              ValueFormat.capitalizeAllLetter(apiDataTemp.firstName)
            );
            var drivingScoreList = ValueFormat.parseAsArray(
              apiDataTemp.drivingScoreList
            );
            var username = ValueFormat.parseAsString(apiDataTemp.username);
            var totalCounts = ValueFormat.parseAsArray(apiDataTemp.totalCount);
            setNextShow(totalCounts - indexStartOfSet > pageSize);

            if (
              drivingScoreList.length > 0 &&
              maxDrivingScoreCount < drivingScoreList.length
            ) {
              maxDrivingScoreCount = drivingScoreList.length;
            }

            drivingScoreList.map((apiDrivingData) => {
              var rideName = ValueFormat.parseAsString(
                ValueFormat.capitalizeFirstLetterAndFormat(
                  apiDrivingData.rideName
                ),
                ""
              );
              var drivingScore = ValueFormat.parseAsInt(
                apiDrivingData.calculatedDrivingScore 
              );
              multiUserData.push(drivingScore);
            });

            setDbComparisonData(
              Object.values(multiUserData).some((value) => value !== 0)
                ? true
                : false
            );

            multiUserDrivingObject = {
              name: firstName,
              data: multiUserData,
              username: username,
            };
            multiUserDrivingDataList.push(multiUserDrivingObject);
          });

          for (let mc = 0; mc < maxDrivingScoreCount; mc++) {
            rideList.push(parseFloat(mc + 1).toFixed(0));
          }

          if (indexStart == 0) {
            setPrevShow(false);
          } else if (indexStart > 0) {
            setPrevShow(true);
          }
        } else {
          setDbComparisonData(false);
        }

        setMultiUserDrivingData(multiUserDrivingDataList);
        setXAxisData(rideList);
      } catch (e) {
        // console.log("filter err ", e)
      }
    } else {
      setDbComparisonData(false);

      const code = resultJSON.error.code;
      if (code == "E1206") {
        setDbComparisonData("failed");
      }
    }
  };

  // const setFilter = (filter) => {
  //   setFilter(filter)
  // }

  useEffect(() => {
    getUserBoardData(indexStart);
  }, [
    filter.durationFilter,
    indexStart,
    filter.startDateTime,
    filter.endDateTime,
    selectedUserValue
  ]);

  return (
    <React.Fragment>
      {/* {showLoader &&<Loader /> } */}
      <Col xl={12}>
        <Card>
          <CardHeader className="cardHeader" style={{padding:"10px"}}>
            {/* <div className="d-flex flex-wrap align-items-center mb-0"> */}
              <h5 className="card-title">
                Score Comparison{" "}
                <span style={{ fontSize: "12px", fontWeight: 400 }}>
                  (All Drivers)
                </span>
              </h5>
              {/* <div className="ms-auto">
                <div style={{ verticalAlign: "middle", display: "flex" }}>
                  <div className="d-flex align-items-center">
                    <div
                      className="me-2 d-flex align-items-center"
                      style={{ fontSize: "14px" }}
                    >
                      <button
                        className="btn me-1"
                        style={{ backgroundColor: "red" }}
                      />
                      Poor (0-80)
                    </div>
                    <div
                      className="me-2 d-flex align-items-center"
                      style={{ fontSize: "14px" }}
                    >
                      <button
                        className="btn me-1"
                        style={{ backgroundColor: "#FFBF00" }}
                      />
                      Fair (80-93)
                    </div>
                    <div
                      className="d-flex align-items-center"
                      style={{ fontSize: "14px" }}
                    >
                      <button
                        className="btn me-1"
                        style={{ backgroundColor: "#19543E" }}
                      />
                      Good (93-100)
                    </div>
                  </div>
                 
                </div>
              </div> */}
            {/* </div> */}
          </CardHeader>
          {dbComparisonData === true ? (
            <CardBody>
              <Row className="align-items-center">
                <Col xl={12}>
                  <LineColumnArea
                    drivingDataList={multiUserDrivingData}
                    xAxisDataList={xAxisData}
                  />
                </Col>

                <div>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={previous}
                    style={{
                      display: prevShow ? "block" : "none",
                      width: "45px",
                      float: "left",
                    }}
                  >
                    Prev
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      display: nextShow ? "block" : "none",
                      float: "right",
                    }}
                  >
                    Next
                  </button>
                </div>
              </Row>
            </CardBody>
          ) : dbComparisonData === false ? (
            <CardBody>
              <p className="data_not_available">
                {displayText.DATA_NOT_AVAILABLE}
              </p>
              <Row className="align-items-center">
                <Col xl={12}>
                  <LineColumnArea
                    drivingDataList={multiUserDrivingData}
                    xAxisDataList={xAxisData}
                  />
                </Col>

                <div>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={previous}
                    style={{
                      display: prevShow ? "block" : "none",
                      width: "45px",
                      float: "left",
                    }}
                  >
                    Prev
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      display: nextShow ? "block" : "none",
                      float: "right",
                    }}
                  >
                    Next
                  </button>
                </div>
              </Row>
            </CardBody>
          ) : dbComparisonData === "failed" ? (
            <CardBody>
              <p className="data_not_available">{displayText.ER_1206}</p>
              <Row className="align-items-center">
                <Col xl={12}>
                  <LineColumnArea
                    drivingDataList={multiUserDrivingData}
                    xAxisDataList={xAxisData}
                  />
                </Col>

                <div>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={previous}
                    style={{
                      display: prevShow ? "block" : "none",
                      width: "45px",
                      float: "left",
                    }}
                  >
                    Prev
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      display: nextShow ? "block" : "none",
                      float: "right",
                    }}
                  >
                    Next
                  </button>
                </div>
              </Row>
            </CardBody>
          ) : (
            <CardBody>
              <p className="fetch_data">{displayText.FETCHING_DATA}</p>
              <Row className="align-items-center">
                <Col xl={12}>
                  <LineColumnArea
                    drivingDataList={multiUserDrivingData}
                    xAxisDataList={xAxisData}
                  />
                </Col>

                <div>
                  <button
                    type="button"
                    className="btn btn-outline-danger btn-sm"
                    onClick={previous}
                    style={{
                      display: prevShow ? "block" : "none",
                      width: "45px",
                      float: "left",
                    }}
                  >
                    Prev
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-success btn-sm"
                    onClick={next}
                    style={{
                      display: nextShow ? "block" : "none",
                      float: "right",
                    }}
                  >
                    Next
                  </button>
                </div>
              </Row>
            </CardBody>
          )}
        </Card>
        {/* <DateTimePickerPopup modalOpen={modalIsOpen} modalClose={setIsOpen} setStartDateTime={setStartDateTime} setEndDateTime={setEndDateTime} setFilter={setFilter} /> */}
      </Col>
    </React.Fragment>
  );
};

export default DrivingBehaviour;
