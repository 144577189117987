import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Collapse } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import withRouter from "../Common/withRouter";

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

import { SC_FCM_NOTIFICATION_COUNT , SC_FCM_VEHICLECRASHES_COUNT } from "../../constant/StorageConstant";

import ActiveDashboardIcon from "../../assets/images/ActiveDashboardIcon.svg";
import DashboardIcon from "../../assets/images/DashboardIcon.svg";

import ActiveUserIcon from "../../assets/images/ActiveUserIcon.svg";
import UserIcon from "../../assets/images/UserIcon.svg";

import ActiveUsersIcon from "../../assets/images/ActiveUsersIcon.svg";
import UsersIcon from "../../assets/images/UsersIcon.svg";

import ActiveSettingIcon from "../../assets/images/ActiveSettingIcon.svg";
import SettingIcon from "../../assets/images/SettingIcon.svg";

import Bell from "../../assets/images/bell.svg";
import ActiveBell from "../../assets/images/bellBlue.svg";

import CarCrashBlue from "../../assets/images/carCrashBlue.svg";
import CarCrash from "../../assets/images/carCrash.svg";
import classNames from "classnames";

import Badge from '@mui/material/Badge';

const AdminNavbar = (props) => {
  const [app, setApp] = useState(false);
  const [previouspath, setPreviouspath] = useState(null);
  const { pathname } = useLocation();
  const dropdownRef = useRef(null);
  const notificationValue = localStorage.getItem(SC_FCM_NOTIFICATION_COUNT) != "" ? parseInt(localStorage.getItem(SC_FCM_NOTIFICATION_COUNT)) : 0;
  const vehiclecrashValue = localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT) != "" ? parseInt(localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT)) : 0;

  const toggleAppDropdown = (e) => {
    e.preventDefault();
    setApp((prevApp) => !prevApp);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setApp(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if(pathname != previouspath) {
      if(previouspath == '/notifications') {
        if(parseInt(localStorage.getItem(SC_FCM_NOTIFICATION_COUNT)) > 0) {
          localStorage.setItem(SC_FCM_NOTIFICATION_COUNT,"0");
        }
      } else if (previouspath == '/vehicleCrashList') {
        if(parseInt(localStorage.getItem(SC_FCM_VEHICLECRASHES_COUNT)) > 0) {
          localStorage.setItem(SC_FCM_VEHICLECRASHES_COUNT,"0");
        }
      }
    }
    setPreviouspath(pathname);
  },[pathname]);

  return (
    <React.Fragment>
      <div className="topnav cardHeader">
        <div className="container-fluid ps-0">

          <nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation" >
            <Collapse isOpen={props.leftMenu} className="navbar-collapse" id="topnav-menu-content" >
              <ul className="navbar-nav">

                <li className="nav-item dropdown" style={{ textAlign: "left" }} >
                  <Link className="nav-link dropdown-toggle arrow-none p-0 m-3 ms-0" to="/adminDashboard" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname === "/adminDashboard" || pathname === "/adminDashboard/CategorySummary" || pathname === "/adminDashboard/RiskSummary" || pathname === "/adminDashboard/ActivityUsageSummary" ? (<img src={ActiveDashboardIcon} alt="ActiveDashboardIcon" height={30} width={30} />
                      ) : (
                        <img src={DashboardIcon} alt="DashboardIcon" height={30} width={30} />
                      )}
                      <span style={{ paddingLeft: "4px", color: pathname === "/adminDashboard" || pathname === "/adminDashboard/CategorySummary" || pathname === "/adminDashboard/RiskSummary" || pathname === "/adminDashboard/ActivityUsageSummary" ? "#5156be" : "#313533" }} > {props.t("Dashboard")} </span>
                    </div>
                  </Link>
                </li>

                {/* <li className="nav-item dropdown" style={{ textAlign: "center" }} >
                  <Link className="nav-link dropdown-toggle arrow-none p-0 m-3" to="/activeuserspage" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname === "/activeuserspage" ? (
                        <img src={ActiveUserIcon} alt="ActiveUserIcon" height={30} width={30} />
                      ) : (
                        <img src={UserIcon} alt="userIcon" height={30} width={30} />
                      )}
                      <span style={{ paddingLeft: "4px", color: pathname === "/activeuserspage" ? "#5156be" : "#313533" }} > {props.t("Active Users")} </span>
                    </div>
                  </Link>
                </li> */}

                {/* <li className="nav-item dropdown" style={{ textAlign: "center" }} >
                  <Link className="nav-link dropdown-toggle arrow-none  p-0 m-3" to="/myusertabledata" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname === "/myusertabledata" ? (
                        <img src={ActiveUsersIcon} alt="ActiveUsersIcon" height={30} width={30} />
                      ) : (
                        <img src={UsersIcon} alt="UsersIcon" height={30} width={30} />
                      )}
                      <span style={{ paddingLeft: "4px", color: pathname === "/myusertabledata" ? "#5156be" : "#313533" }} >
                        {props.t("All Users")}
                      </span>
                    </div>
                  </Link>
                </li> */}

                {/* <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle arrow-none  p-0 m-3" to="/notifications" >
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                        {pathname == "/notifications" ? (
                          <>
                            {notificationValue > 0 ? (
                              <Badge badgeContent={`${notificationValue}`} color="error">
                                <img src={ActiveBell} alt="notifications" height={30} width={30} />
                              </Badge>
                            ) : (
                              <img src={ActiveBell} alt="notifications" height={30} width={30} />
                            )}
                          </>
                        ) : (
                          <>
                            {notificationValue > 0 ? (
                              <Badge badgeContent={`${notificationValue}`} color="error">
                                <img src={Bell} alt="Notifications" height={30} width={30} />
                              </Badge>
                            ) : (
                              <img src={Bell} alt="Notifications" height={30} width={30} />
                            )}
                          </>
                        )}
                        <span style={{ paddingLeft: "4px", color: pathname === "/notifications" ? "#5156be" : "#313533" }} > {props.t("Notifications")} </span>
                      </div>
                    </Link>
                </li> */}

                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle arrow-none  p-0 m-3" to="/vehicleCrashList" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                      {pathname == "/vehicleCrashList" ? (
                        <>
                        { vehiclecrashValue > 0 ?(
                          <Badge badgeContent={`${vehiclecrashValue}`} color="error">
                            <img src={CarCrashBlue} alt="crashride" height={30} width={30} />
                          </Badge>
                        ) : (
                          <img src={CarCrashBlue} alt="crashride" height={30} width={30} />
                        )
                        }
                      </>
                      ) : (
                        <>
                          { vehiclecrashValue > 0 ?(
                            <Badge badgeContent={`${vehiclecrashValue}`} color="error">
                              <img src={CarCrash} alt="crashride" height={30} width={30} />
                            </Badge>
                          ) : (
                            <img src={CarCrash} alt="crashride" height={30} width={30} />
                          )
                          }
                        </>
                      )}
                      <span style={{ paddingLeft: "4px", color: pathname === "/vehicleCrashList" ? "#5156be" : "#313533" }} > {props.t("Vehicle Crashes")} </span>
                    </div>
                  </Link>
                </li>

                {/* <li className="nav-item" ref={dropdownRef}>
                  <Link to="/#" onClick={toggleAppDropdown} className="nav-link dropdown-togglez arrow-none p-0 m-3" >
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className="nav-item dropdown">
                      {pathname === "/mySubscription" || pathname === "/shiftConfiguration" || pathname === "/appBootSettings" ? (
                        <img src={ActiveSettingIcon} alt="ActiveSeetingIcon" height={30} width={30} />
                      ) : (
                        <img src={SettingIcon} alt="SettingIcon" height={30} width={30} />
                      )}
                      <span style={{ paddingLeft: "4px", color: pathname === "/mySubscription" || pathname === "/shiftConfiguration" || pathname === "/appBootSettings" ? "#5156be" : "#313533" }} >
                        {props.t("Settings")}{" "}
                      </span>
                      <div className="arrow-down"></div>
                    </div>
                  </Link>
                  <div className={classNames("dropdown-menu", { show: app })}>
                    <Link to="/mySubscription" className="dropdown-item" onClick={() => setApp(false)} >
                      <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                        {props.t("User Licenses")}
                      </div>
                    </Link>
                   
                  </div>
                </li> */}

              </ul>
            </Collapse>
          </nav>

        </div>
      </div>
    </React.Fragment>
  );
};

AdminNavbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(AdminNavbar))
);
