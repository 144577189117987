import React, { useState } from "react";
import LoaderPdf from "../../Common/loaderPdf";
import { Document, Line, PDFViewer, Page, Rect, Svg, Text, View, Image } from "@react-pdf/renderer";
import PdfFooterV1 from "../Components/pdfFooterV1";
import PdfHeaderV1 from "../Components/pdfHeaderV1";
import PdfHeading from "../Components/pdfHeading";
import ValueFormat from "../../../util/ValueFormat";
import UtilDateTime from "../../../util/UtilDateTime";

import rank from "../../../assets/images/adminDashboard/pdf/pdf_rank.png";
import firstName from "../../../assets/images/adminDashboard/pdf/pdf_firstname.png";
import username from "../../../assets/images/adminDashboard/pdf/pdf_username.png";
import efficiency from "../../../assets/images/adminDashboard/pdf/pdf_efficiency.png";
import totalRides from "../../../assets/images/adminDashboard/pdf/pdf_total_rides.png";
import drivingScore from "../../../assets/images/adminDashboard/pdf/pdf_driving_score.png";
import anticipation from "../../../assets/images/adminDashboard/pdf/pdf_anticipation.png";
import selfConfidence from "../../../assets/images/adminDashboard/pdf/pdf_self_confidence.png";
import drivingSkill from "../../../assets/images/adminDashboard/pdf/pdf_driving_skill.png";
import drivingStyle from "../../../assets/images/adminDashboard/pdf/pdf_driving_style.png";
import drivingState from "../../../assets/images/adminDashboard/pdf/pdf_driver_state.png";
import mobScreen from "../../../assets/images/adminDashboard/pdf/pdf_mobile_screen.png";
import mobCall from "../../../assets/images/adminDashboard/pdf/pdf_mobile_call.png";
import kmsDriven from "../../../assets/images/adminDashboard/pdf/pdf_kms_driven.png";
import stressStrain from "../../../assets/images/adminDashboard/pdf/pdf_stress_strain.png";
import tdistance from "../../../assets/images/adminDashboard/pdf_distance.png";
import tduration from "../../../assets/images/adminDashboard/pdf_duration.png";
import i5 from "../../../assets/images/adminDashboard/pdf_high_alerts.png";
import i6 from "../../../assets/images/adminDashboard/pdf_medium_alerts.png";
import i7 from "../../../assets/images/adminDashboard/pdf_overspeed.png";
import i8 from "../../../assets/images/adminDashboard/pdf_mobile_call.png";
import i9 from "../../../assets/images/adminDashboard/pdf_mobile_screen.png";
import i10 from "../../../assets/images/adminDashboard/pdf_acceleration.png";
import i11 from "../../../assets/images/adminDashboard/pdf_braking.png";
import i12 from "../../../assets/images/adminDashboard/pdf_cornering.png";
import crash from "../../../assets/images/adminDashboard/pdf/pdf_crash.png";
import { displayText } from "../../../constant/MessageConstant";
import { globalPDF } from "../../../assets/css/commonCssTextForPdf";

export default function AdOverallSummaryyPdf({
  ADTopRowItemsTemp,
  ADRideBoxCount,
  summaryData,
  aggregateData1,
  totalDays1,
  filtersADOS
}) {
  const [showLoader, setShowLoader] = useState();
  const heading = "Admin Dashboard (Overall Summary)";
  const fontBold = { fontFamily: "Helvetica-Bold" };
  const months1 = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  var currentDate = new Date();
  const reversedDateFormat =
    currentDate.getDate().toString().padStart(2, "0") +
    "-" +
    months1[currentDate.getMonth()] +
    "-" +
    currentDate.getFullYear();
  let period = "";
  let startDate = "";
  let endDate = "";
  if (filtersADOS.durationFilter === "CURRENT_YEAR") {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = UtilDateTime.getCurrentYear(filtersADOS.startDateTime);
    endDate = "";
  } else if (filtersADOS.durationFilter === "CURRENT_MONTH") {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = UtilDateTime.getCurrentMonth(filtersADOS.startDateTime);
    endDate = "";
  } else if (filtersADOS.durationFilter === "CURRENT_WEEK") {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = UtilDateTime.getCurrentWeek1(filtersADOS.startDateTime);
    endDate = "";
  } else if (filtersADOS.durationFilter === "CURRENT_DAY") {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = UtilDateTime.getCurrentDay(filtersADOS.startDateTime);
    endDate = "";
  } else {
    period = ValueFormat.smallLettersFormat(filtersADOS.durationFilter);
    startDate = filtersADOS.startDateTime.split(" ")[0];
    endDate = filtersADOS.endDateTime.split(" ")[0];
  }
  const periodFormat = [period, startDate, endDate];

  const widget1 = ADTopRowItemsTemp.map((item) => {
    const totalValue = ADTopRowItemsTemp.length > 0 ? ADTopRowItemsTemp[0].totalCount : 0;
    const percentage = totalValue !== 0 ? ((item.totalCount / totalValue) * 100).toFixed(2) : 0;
    return {
      title: item.title,
      value: item.totalCount,
      desc: item.description,
      percentage: percentage,
      totalValue: totalValue,
    };
  });
  const modifiedWidget1 = () => {
    const maxVal = widget1.length > 0 ? widget1[0].value : 0;
    return widget1.map((item) => {
      // Create a new object to avoid modifying the original data
      const sItem = { ...item };

      // Apply your conditions to each item
      if (sItem.value > 0) {
        if (sItem.value < maxVal) {
          sItem.value = ((sItem.value / maxVal) * 250).toFixed(1);
        } else {
          sItem.value = 250;
        }
      } else {
        sItem.value = 0;
      }

      return sItem;
    });
  };
  const w1 = modifiedWidget1();
  const maxValW2 = ADRideBoxCount.totalRides;
  const categoryData = () => {
    return ADRideBoxCount.count.map((item, index) => {
      return { total: item.totalRides, percentage: item.percentage };
    });
  };
  const categoryCircle = categoryData();
  const modifiedWidget2 = () => {
    return categoryCircle.map((item) => {
      // Create a new object to avoid modifying the original data
      const sItem = { ...item };

      // Apply your conditions to each item
      if (sItem.total > 0) {
        if (sItem.total < maxValW2) {
          sItem.total = ((sItem.total / maxValW2) * 230).toFixed(1);
        } else {
          sItem.total = 230;
        }
      } else {
        sItem.total = 0;
      }

      return sItem;
    });
  };
  const w2 = modifiedWidget2();
  const titleHead = ["Units", "Safe Driving", "Sportive Driving", "Quite Safe Driving", "Reckless Driving", "Quite Dangerous Driving", "Dangerous Driving"];
  const T1 = {
    title: titleHead.map((title) => ({ title })),
    totalKm: [
      "Kilometers",
      ...(ADRideBoxCount.count || []).map((item) =>
        item && !isNaN(item.totalKm) ? Math.round(item.totalKm) : 0
      ),
    ],
    totalTt: [
      "Minutes",
      ...(ADRideBoxCount.count || []).map((item) =>
        item && !isNaN(item.totalTravelTime)
          ? Math.round(item.totalTravelTime)
          : 0
      ),
    ],
  };
  const titleHead1 = [
    "Total Days",
    "Total Rides",
    "Total Distance",
    "Total Duration",
    "Total Red Alerts",
    "Total Amber Alerts",
  ];
  const T2 = {
    title: titleHead1.map((title) => ({ title })),
    data: [
      totalDays1 || 0,
      aggregateData1.totalRides || 0,
      aggregateData1.totalKilometer
        ? aggregateData1.totalKilometer.toFixed(2) + " kms"
        : 0 + " kms",
      aggregateData1.totalTravelTime
        ? (aggregateData1.totalTravelTime / 60).toFixed(2) + " hrs"
        : 0 + " hrs",
      aggregateData1.totalHighRiskCount || 0,
      aggregateData1.totalMediumRiskCount || 0,
    ],
  };

  const originalData = summaryData.data || [
    {
      ABCPoint: "-",
      DSP: "-",
      DSTP: "-",
      drivingScore: "-",
      calculatedDrivingScore:"-",
      efficiency: "0",
      efficiencyTooltipValue: "0",
      firstName: "-",
      mobileScreenPointNew: "-",
      mobileUsePointNew: "-",
      overallAnticipation: "-",
      overallDrivingSkill: "-",
      drivingBehaviour:"-",
      overallSelfConfidence: "-",
      rank: "-",
      totalKmSPoint: "-",
      totalRides: "-",
      userId: "-",
      username: "-",
      tKiloMeterTV: "-",
      tTravelTimeTV: "-",
      totalHighRiskCount: "-",
      totalMediumRiskCount: "-",
      totalOverSpeedDurationTV: "-",
      totalOverSpeedCount: "-",
      totalMobileScreenScreenOnDurationTV: "-",
      totalMobileScreenScreenOnCount: "-",
      totalMobileUseInAcceptedDurationTV: "-",
      totalMobileUseInAcceptedCount: "-",
      totalAccelerationCount: "-",
      totalBrakingCount: "-",
      totalCorneringCount: "-",
    },
  ];
  const dupData = [...originalData, ...originalData, ...originalData]
  const data = originalData.map((item) => {
    // Extract and parse the necessary values
    const efficiencyValues = [
      parseFloat(item.calculatedDrivingScore),
      parseFloat(item.overallAnticipation),
      parseFloat(item.overallSelfConfidence),
      parseFloat(item.overallDrivingSkill),
      parseFloat(item.drivingBehaviourScore),
      parseFloat(item.DSTP),
      parseFloat(item.DSP),
      parseFloat(item.mobileScreenPointNew),
      parseFloat(item.mobileUsePointNew),
      parseFloat(item.totalKmSPoint),
      parseFloat(item.ABCPoint),
    ];

    // Calculate the sum of efficiency values
    const sum = efficiencyValues.reduce((total, value) => total + (value || 0), 0);

    // Determine rank based on sum
    let rank;
    if (parseFloat(item.calculatedDrivingScore) >= 0 && parseFloat(item.calculatedDrivingScore) <= 80) {
      rank = "Poor";
    } else if (parseFloat(item.calculatedDrivingScore) >= 81 && parseFloat(item.calculatedDrivingScore) <= 93) {
      rank = "Fair";
    } else {
      rank = "Good";
    }

    return {
      ...item,
      efficiency: `${item.calculatedDrivingScore}`,
      rank, // Update rank
      drivingScore: `${item.drivingScore}`,
      overallAnticipation: `${item.overallAnticipation}`,
      overallSelfConfidence: `${item.overallSelfConfidence}`,
      overallDrivingSkill: `${item.overallDrivingSkill}`,
      drivingBehaviour: `${item.drivingBehaviourScore}`,
      DSTP: `${item.DSTP}`,
      DSP: `${item.DSP}`,
      mobileScreenPointNew: `${item.mobileScreenPointNew}`,
      mobileUsePointNew: `${item.mobileUsePointNew}`,
      totalKmSPoint: `${item.totalKmSPoint}`,
      ABCPoint: `${item.ABCPoint}`,
    };
  });

  const chunks = [];
  const firstChunkSize = 36;
  const subsequentChunkSize = 55;

  for (let i = 0; i < data.length; i += subsequentChunkSize) {
    if (i === 0) {
      chunks.push(data.slice(0, firstChunkSize));
      i = firstChunkSize - subsequentChunkSize;
    } else {
      chunks.push(data.slice(i, i + subsequentChunkSize));
    }
  }

  const drCol = [
    "rank",
    "efficiency",
    "firstName",
    "username",
    "totalRides",
    "drivingScore",
    "overallAnticipation",
    "overallSelfConfidence",
    "overallDrivingSkill",
    "drivingBehaviour",
    "DSTP",
    "DSP",
    // "overSpeedPoint",
    "mobileScreenPointNew",
    "mobileUsePointNew",
    "totalKmSPoint",
    "ABCPoint",
  ];
  const drTitle = {
    rank: "\n \n",
    efficiency: "\n \n \n",
    firstName: "\n\n",
    username: "\n\n",
    totalRides: "\n \n ",
    drivingScore: "\nDriving \nRisk",
    overallAnticipation: "\n \nAnticipation",
    overallSelfConfidence: "\nSelf Confidence\n",
    overallDrivingSkill: "\n \nDriving Skill",
    drivingBehaviour: "\nDriving \nBehaviour",
    DSTP: "\n \nDriving Style",
    DSP: "\n \nDriver State",
    // overSpeedPoint: "\nOverspeed\nPoints",
    mobileScreenPointNew: "\nMobile Screen\nUsage",
    mobileUsePointNew: "\nMobile Call\nUsage",
    totalKmSPoint: "\nOverspeed \nKMS Driven",
    ABCPoint: "\n \nAcc / Brak / Cor",
  };

  const drColTwo = [
    "rating",
    "drivingScore", 
    "name",
    "username",   
    "totalRides",
    "drivingRiskData",
    "drivingBehaviorData",
 
];



const drTitleTwo = {
 rating: "Rating",
 drivingScore: "Driving Score",  
 name:"Name",
 username:"Username",  
 totalRides: "Total Rides",
 drivingRiskData: "Driving Risk Data",
 drivingBehaviorData: "Driving Behavior Data",
 
};

  const drImages = {
    rank: rank,
    firstName: firstName,
    username: username,
    efficiency: efficiency,
    totalRides: totalRides,
    drivingScore: drivingScore,
    overallAnticipation: anticipation,
    overallSelfConfidence: selfConfidence,
    overallDrivingSkill: drivingSkill,
    drivingBehaviour: drivingSkill,
    DSTP: drivingStyle,
    DSP: drivingState,
    // overSpeedPoint: i7,
    mobileScreenPointNew: mobScreen,
    mobileUsePointNew: mobCall,
    totalKmSPoint: kmsDriven,
    ABCPoint: stressStrain,
  };

  const chunksViolation = [];
  const firstChunkSizeViolation = 56;
  const subsequentChunkSizeViolation = 56;

  for (let i = 0; i < data.length; i += subsequentChunkSizeViolation) {
    if (i === 0) {
      chunksViolation.push(data.slice(0, firstChunkSizeViolation));
      i = firstChunkSizeViolation - subsequentChunkSizeViolation;
    } else {
      chunksViolation.push(data.slice(i, i + subsequentChunkSizeViolation));
    }
  }

  const violationCol = [
    "rank",
    "efficiency",
    "firstName",
    "username",
    "totalRides",
    "tKiloMeterTV",
    "tTravelTimeTV",
    "crashCount",
    "totalHighRiskCount",
    "totalMediumRiskCount",
    "totalOverSpeedDurationTV",
    "totalOverSpeedCount",
    "totalMobileScreenScreenOnDurationTV",
    "totalMobileScreenScreenOnCount",
    "totalMobileUseInAcceptedDurationTV",
    "totalMobileUseInAcceptedCount",
    "totalAccelerationCount",
    "totalBrakingCount",
    "totalCorneringCount"
  ];

  const violationTitle = {
    rank: "\n \nRating",
    efficiency: "\n Driving \nScore",
    firstName: "\n\nFirst Name",
    username: "\n\nUsername",
    totalRides: "\nTotal \nRides",
    tKiloMeterTV: "Total \nDistance\n (kms)",
    tTravelTimeTV: "Total\n Duration \n(Mins)",
    crashCount: "\nCrash\n(Count)",
    totalHighRiskCount: "\nHigh Risk\n (Count)",
    totalMediumRiskCount: "\nMedium Risk\n (Count)",
    totalOverSpeedDurationTV: "\nOverspeed\n (Mins)",
    totalOverSpeedCount: "\nOverspeed\n (Count)",
    totalMobileScreenScreenOnDurationTV: "Mobile\nScreen\n(Mins)",
    totalMobileScreenScreenOnCount: "Mobile\nScreen\n(Count)",
    totalMobileUseInAcceptedDurationTV: "\nMobile Call \n(Mins)",
    totalMobileUseInAcceptedCount: "\nMobile Call \n(Count)",
    totalAccelerationCount: "\nAcceleration\n (Count)",
    totalBrakingCount: "\nBraking\n (Count)",
    totalCorneringCount: "\nCornering \n(Count)",
  };

  const violationImages = {
    rank: rank,
    firstName: firstName,
    username: username,
    efficiency: efficiency,
    totalRides: totalRides,
    tKiloMeterTV: tdistance,
    tTravelTimeTV: tduration,
    crashCount: crash,
    totalHighRiskCount: i5,
    totalMediumRiskCount: i6,
    totalOverSpeedDurationTV: i7,
    totalOverSpeedCount: i7,
    totalMobileScreenScreenOnDurationTV: i9,
    totalMobileScreenScreenOnCount: i9,
    totalMobileUseInAcceptedDurationTV: i8,
    totalMobileUseInAcceptedCount: i8,
    totalAccelerationCount: i10,
    totalBrakingCount: i11,
    totalCorneringCount: i12

  };


  return (
    <React.Fragment>
      {showLoader > 0 ? <LoaderPdf /> : ""}

      <PDFViewer
        style={{
          height: "96%",
          width: "100%",
          marginTop: "20px",
          marginBottom: "5px",
        }}
      >
        <Document style={{ marginTop: "15px", marginBottom: "5px" }}>
          {chunks.map((chunk, pageIndex) => (
            <Page key={pageIndex} size="A2">
              <PdfHeaderV1 />
              <View style={{ padding: 30, paddingTop: 10 }}>
                <PdfHeading
                  heading={heading}
                  periodFormat={periodFormat}
                  reversedDateFormat={reversedDateFormat}
                />

                {pageIndex === 0 && (
                  <View style={{
                    marginTop: 10,
                    marginBottom: 5,
                    paddingVertical: 15,
                  }}>

                    <View
                      style={{
                        display: "flex",
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                      }}
                    >
                      <View style={{
                        width: '49.5%',
                        // backgroundColor: globalPDF.GREY_COLOR, 
                        border: '1px solid lightgrey',
                        borderRadius: 10, paddingLeft: 24, paddingTop: 10
                      }}>
                        <Text style={{ fontSize: 13, marginBottom: 25 }}>
                          1. User Summary
                        </Text>

                        <Svg
                          width="100%"
                          height="161"
                          style={{ marginTop: 10, paddingLeft: 2 }}
                        >
                          <Line
                            x1="102"
                            y1="0"
                            x2="102"
                            y2="150"
                            stroke="black"
                            strokeWidth="1"
                          />
                          {w1.slice(1).map((item, index) => (
                            <Rect
                              key={index}
                              x="103"
                              y={index * 40}
                              width={item.value || 0}
                              height={25}
                              fill={
                                index === 0
                                  ? "#00AA63"
                                  : index === 1
                                    ? "#4285F4"
                                    : index === 2
                                      ? "#FCB632"
                                      : "#E36E2C"
                              }
                            />
                          ))}
                          {w1.slice(1).map((item, index) => (
                            <Text
                              key={index}
                              x={355}
                              y={index * 40 + 16}
                              style={{ fontSize: 9, color: "black" }}
                            >
                              {widget1[index + 1].percentage +
                                "%" +
                                " (" +
                                widget1[index + 1].desc +
                                ")"}
                            </Text>
                          ))}
                          {widget1.slice(1).map((item, index) => (
                            <Text
                              key={index}
                              x={0}
                              y={index * 40 + 16}
                              style={{ fontSize: 9, textAlign: "right" }}
                              fill="black"
                            >
                              {item.title || ""}
                            </Text>
                          ))}
                        </Svg>

                        <View style={{ width: '90%', textAlign: 'center' }}>
                          <Svg
                            viewBox="0 0 600 20"
                            style={{
                              flexDirection: "row",
                              marginTop: 10,
                              marginBottom: 0,
                            }}
                          >
                            <Rect
                              x="3"
                              rx="2"
                              ry="2"
                              width="20"
                              height="12"
                              fill="#00AA63"
                            />
                            <Text
                              x="30"
                              y="50%"
                              style={{ height: "10", fontSize: "11px" }}
                            >
                              {widget1[1].title +
                                " (" +
                                widget1[1].value +
                                "/" +
                                widget1[1].totalValue +
                                ")"}
                            </Text>
                            <Rect
                              x="145"
                              rx="2"
                              ry="2"
                              width="20"
                              height="12"
                              fill="#4285F4"
                            />
                            <Text
                              x="170"
                              y="50%"
                              style={{ height: "10", fontSize: "11px" }}
                            >
                              {widget1[2].title +
                                " (" +
                                widget1[2].value +
                                "/" +
                                widget1[1].totalValue +
                                ")"}
                            </Text>
                            <Rect
                              x="295"
                              rx="2"
                              ry="2"
                              width="20"
                              height="12"
                              fill="#FCB632"
                            />
                            <Text
                              x="320"
                              y="50%"
                              style={{ height: "10", fontSize: "11px" }}
                            >
                              {widget1[3].title +
                                " (" +
                                widget1[3].value +
                                "/" +
                                widget1[1].totalValue +
                                ")"}
                            </Text>
                            <Rect
                              x="435"
                              rx="2"
                              ry="2"
                              width="20"
                              height="12"
                              fill="#E36E2C"
                            />
                            <Text
                              x="460"
                              y="50%"
                              style={{ height: "10", fontSize: "11px" }}
                            >
                              {widget1[4].title +
                                " (" +
                                widget1[4].value +
                                "/" +
                                widget1[1].totalValue +
                                ")"}
                            </Text>
                          </Svg>
                        </View>
                      </View>

                      <View style={{
                        width: '49.5%',
                        // backgroundColor: globalPDF.GREY_COLOR,
                        border: '1px solid lightgrey',
                        borderRadius: 10, paddingLeft: 24, paddingTop: 10
                      }}>
                        <Text style={{ fontSize: 13, marginBottom: 7 }}>
                          2. Zone Summary
                        </Text>

                        <Text style={{ fontSize: 12 }}>2.1. Green Zone</Text>

                        <Svg width="100%" height="60" style={{ marginTop: 0 }}>
                          <Text x="0" y="17" style={{ fontSize: 10 }}>
                            {"(C1) Safe Driving" +
                              " (" +
                              (categoryCircle.length > 0 &&
                                `${categoryCircle[0].total} / ${ADRideBoxCount.totalRides}`) +
                              ")"}
                          </Text>
                          <Rect x="0" y="30" width="230" height="20" fill="#d9d9d9" />
                          <Rect
                            x="0"
                            y="30"
                            width={w2[0].total}
                            height="20"
                            fill="#2ab57d"
                          />
                          <Text
                            x={115}
                            y={44}
                            textAnchor="middle"
                            alignmentBaseline="middle"
                            style={{ fontSize: "10px" }}
                          >
                            {categoryCircle[0].percentage + "%"}
                          </Text>
                          <Text x="275" y="17" style={{ fontSize: 10 }}>
                            {"(C2) Sportive Driving" +
                              " (" +
                              (categoryCircle.length > 0 &&
                                `${categoryCircle[1].total} / ${ADRideBoxCount.totalRides}`) +
                              ")"}
                          </Text>
                          <Rect x="275" y="30" width="230" height="20" fill="#d9d9d9" />
                          <Rect
                            x="275"
                            y="30"
                            width={w2[1].total}
                            height="20"
                            fill="#2ab57d"
                          />
                          <Text
                            x={390}
                            y={44}
                            textAnchor="middle"
                            alignmentBaseline="middle"
                            style={{ fontSize: "10px" }}
                          >
                            {categoryCircle[1].percentage + "%"}
                          </Text>
                        </Svg>

                        <Text style={{ fontSize: 12 }}>2.2. Amber Zone</Text>

                        <Svg width="100%" height="60" style={{ marginTop: 0 }}>
                          <Text x="0" y="17" style={{ fontSize: 10 }}>
                            {"(C3) Quite Safe Driving" +
                              " (" +
                              (categoryCircle.length > 0 &&
                                `${categoryCircle[2].total} / ${ADRideBoxCount.totalRides}`) +
                              ")"}
                          </Text>
                          <Rect x="0" y="30" width="230" height="20" fill="#d9d9d9" />
                          <Rect
                            x="0"
                            y="30"
                            width={w2[2].total}
                            height="20"
                            fill="#ffbf53"
                          />
                          <Text
                            x={115}
                            y={44}
                            textAnchor="middle"
                            alignmentBaseline="middle"
                            style={{ fontSize: 10 }}
                          >
                            {categoryCircle[2].percentage + "%"}
                          </Text>
                          <Text x="275" y="17" style={{ fontSize: 10 }}>
                            {"(C4) Reckless Driving" +
                              " (" +
                              (categoryCircle.length > 0 &&
                                `${categoryCircle[3].total} / ${ADRideBoxCount.totalRides}`) +
                              ")"}
                          </Text>
                          <Rect x="275" y="30" width="230" height="20" fill="#d9d9d9" />
                          <Rect
                            x="275"
                            y="30"
                            width={w2[3].total}
                            height="20"
                            fill="#ffbf53"
                          />
                          <Text
                            x={390}
                            y={44}
                            textAnchor="middle"
                            alignmentBaseline="middle"
                            style={{ fontSize: 10 }}
                          >
                            {categoryCircle[3].percentage + "%"}
                          </Text>
                        </Svg>

                        <Text style={{ fontSize: 12 }}>2.3. Red Zone</Text>

                        <Svg width="100%" height="60" style={{ marginTop: 0 }}>
                          <Text x="0" y="17" style={{ fontSize: 10 }}>
                            {"(C5) Quite Dangerous Driving" +
                              " (" +
                              (categoryCircle.length > 0 &&
                                `${categoryCircle[4].total} / ${ADRideBoxCount.totalRides}`) +
                              ")"}
                          </Text>
                          <Rect x="0" y="30" width="230" height="20" fill="#d9d9d9" />
                          <Rect
                            x="0"
                            y="30"
                            width={w2[4].total}
                            height="20"
                            fill="#fd625e"
                          />
                          <Text
                            x={115}
                            y={44}
                            textAnchor="middle"
                            alignmentBaseline="middle"
                            style={{ fontSize: 10 }}
                          >
                            {categoryCircle[4].percentage + "%"}
                          </Text>
                          <Text x="275" y="17" style={{ fontSize: 10 }}>
                            {"(C6) Dangerous Driving" +
                              " (" +
                              (categoryCircle.length > 0 &&
                                `${categoryCircle[5].total} / ${ADRideBoxCount.totalRides}`) +
                              ")"}
                          </Text>
                          <Rect x="275" y="30" width="230" height="20" fill="#d9d9d9" />
                          <Rect
                            x="275"
                            y="30"
                            width={w2[5].total}
                            height="20"
                            fill="#fd625e"
                          />
                          <Text
                            x={390}
                            y={44}
                            textAnchor="middle"
                            alignmentBaseline="middle"
                            style={{ fontSize: 10 }}
                          >
                            {categoryCircle[5].percentage + "%"}
                          </Text>
                        </Svg>
                      </View>
                    </View>

                    <View
                      style={{
                        marginTop: 15,
                        marginBottom: 0,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <View
                        style={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        {T1.title.map((item, index) => (
                          <View
                            style={{
                              flex: 2,
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              backgroundColor: globalPDF.GREY_COLOR,
                              padding: 5,
                              paddingLeft: 10,
                              alignItems: "center",
                              fontSize: "9px",
                              border: "1px solid lightgrey",
                              borderLeft:
                                index === 0 ? "1px solid lightgrey" : "none",
                            }}
                            key={index}
                          >
                            <Text style={fontBold}>
                              {item.title}
                            </Text>
                          </View>
                        ))}
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        {T1.totalKm.map((item, index) => (
                          <View
                            style={{
                              flex: 2,
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              padding: 5,
                              paddingLeft: 10,
                              fontSize: 9,
                              border: "1px solid lightgrey",
                              borderTop: "none",
                              borderLeft:
                                index === 0 ? "1px solid lightgrey" : "none",
                            }}
                            key={index}
                          >
                            <Text break>{item}</Text>
                          </View>
                        ))}
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        {T1.totalTt.map((item, index) => (
                          <View
                            style={{
                              flex: 2,
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              padding: 5,
                              paddingLeft: 10,
                              fontSize: 9,
                              border: "1px solid lightgrey",
                              borderTop: "none",
                              borderLeft:
                                index === 0 ? "1px solid lightgrey" : "none",
                            }}
                            key={index}
                          >
                            <Text break>{item}</Text>
                          </View>
                        ))}
                      </View>
                    </View>

                    <View
                      style={{
                        marginTop: 15,
                        marginBottom: 0,
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <View style={{ flexDirection: "row" }}>
                        {T2.title.map((item, index) => (
                          <View
                            style={{
                              flex: 2,
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              padding: 5,
                              paddingLeft: 10,
                              backgroundColor: globalPDF.GREY_COLOR,
                              alignItems: "center",
                              fontSize: "9px",
                              border: "1px solid lightgrey",
                              borderLeft:
                                index === 0 ? "1px solid lightgrey" : "none",
                            }}
                            key={index}
                          >
                            <Text style={fontBold} break>
                              {item.title}
                            </Text>
                          </View>
                        ))}
                      </View>
                      <View style={{ flexDirection: "row" }}>
                        {T2.data.map((item, index) => (
                          <View
                            style={{
                              flex: 2,
                              flexDirection: "row",
                              justifyContent: "flex-start",
                              padding: 5,
                              paddingLeft: 10,
                              fontSize: 9,
                              border: "1px solid lightgrey",
                              borderTop: "none",
                              borderLeft:
                                index === 0 ? "1px solid lightgrey" : "none",
                            }}
                            key={index}
                          >
                            <Text
                              break
                            >
                              {item}
                            </Text>
                          </View>
                        ))}
                      </View>
                    </View>

                  </View>
                )}

                <View
                  style={{
                    marginBottom: 5,
                    paddingTop: 7,
                  }}
                >
                  <Text style={{ fontSize: 13, marginBottom: 7 }}>
                    3. Driving Behaviour Rating
                  </Text>

                  <Text style={{ fontSize: 10, marginBottom: 7 }}>{displayText.DRIVER_BEHAVIOUR_DESCRIPTION}</Text>
                  <View>
                  <View
                                            style={{
                                                flexDirection: "row",
                                                height: "30px",
                                                marginTop: 5,
                                            }}
                                        >
                                            {drColTwo.map((column, index) => (
                                                <>
                                                <View
                                                    key={index}
                                                    style={{
                                                        textAlign: 'center',
                                                        flex: column === "rating" ? 0.54 : column === "drivingScore" ? 0.55 : column === "name" ? 0.93 : column === "username" ? 0.54 :  column === "totalRides" ? 0.55 : column === "drivingRiskData" ? 2.2 : 3.87,
                                                        justifyContent: "center",
                                                        backgroundColor: globalPDF.GREY_COLOR,
                                                        // alignItems: "flex-start",
                                                        // paddingLeft: 5,
                                                        fontSize: "9px",
                                                        border: "1px solid lightgrey",
                                                        borderBottom:"none",
                                                        borderLeft:
                                                            index === 0 ? "1px solid lightgrey" : "none",
                                                        flexDirection: 'column'
                                                    }}
                                                >
                                                    <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5, textAlign: "center" }}>
                                                        <View>
                                                            <Text style={fontBold} break> {drTitleTwo[column]} </Text>
                                                        </View>                                                       
                                                    </View>
                                                </View>
                                                </>
                                            ))}
                                        </View>
                    <View
                      style={{
                        flexDirection: "row",
                        height: "70px",
                        marginTop: 0,
                      }}
                    >
                      {drCol.map((column, index) => (
                        <View
                          key={index}
                          style={{
                            flex: column === "firstName" ? 1.7 : 1,
                            justifyContent: "center",
                            backgroundColor: globalPDF.GREY_COLOR,
                            // alignItems: "flex-start",
                            // paddingLeft: 5,
                            fontSize: "9px",
                            border: "1px solid lightgrey",
                            borderLeft:
                              index === 0 ? "1px solid lightgrey" : "none",
                            flexDirection: 'column'
                          }}
                        >
                          <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5, textAlign: "center" }}>
                            <View style={{ height: '45px', paddingTop: 7 }}>
                              <Text style={fontBold} break> {drTitle[column]} </Text>
                            </View>
                            <View style={{ height: '25px' }}>
                              <Image src={drImages[column]} style={{ width: "15px", height: "15px" }} />
                            </View>
                          </View>
                        </View>
                      ))}
                    </View>
                    {chunk.map((item, rowIndex) => (
                      <View
                        key={rowIndex}
                        style={{ flexDirection: "row", height: "25px" }}
                      >
                        {drCol.map((column, colIndex) => (
                          <View
                            key={colIndex}
                            style={{
                              flex: column === "firstName" ? 1.7 : 1,
                              justifyContent: "center",
                              fontSize: 9,
                              backgroundColor: column === "drivingScore" || column === "drivingBehaviour"  ?  globalPDF.LIGHT_BLUE_COLOR : "transparent",
                              borderLeft:
                                item[column] !== undefined
                                  ? "1px solid lightgrey"
                                  : "none",
                              borderRight:
                                colIndex === 6
                                  ? item[column] !== undefined
                                    ? "1px solid lightgrey"
                                    : "none"
                                  : colIndex === drCol.length - 1
                                    ? "1px solid lightgrey"
                                    : "none",
                              borderBottom:
                                item[column] !== undefined
                                  ? "1px solid lightgrey"
                                  : "none",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 9,
                                textAlign: "left",
                                paddingLeft: 10
                              }}
                              break
                            >
                              {item[column]}
                            </Text>
                          </View>
                        ))}
                      </View>
                    ))}
                  </View>
                </View>
              </View>
              <PdfFooterV1 pageNumber={pageIndex + 1} totalPages={chunks.length + chunksViolation.length} />
            </Page>
          ))}

          {chunksViolation.map((chunk, pageIndex) => (
            <Page key={pageIndex} size="A2">
              <PdfHeaderV1 />
              <View style={{ padding: 30, paddingTop: 10 }}>
                <PdfHeading
                  heading={heading}
                  periodFormat={periodFormat}
                  reversedDateFormat={reversedDateFormat}
                />
                {/* New Violation Dashboard Table */}
                <View style={{ marginBottom: 5, paddingVertical: 15 }}>
                  <Text style={{ fontSize: 13, marginBottom: 7 }}>
                    4. Dashboard for Violation
                  </Text>
                  <View>
                    <View
                      style={{
                        flexDirection: "row",
                        height: "70px",
                        marginTop: 5,
                      }}
                    >
                      {violationCol.map((column, index) => (
                        <View
                          key={index}
                          style={{
                            flex: column === "firstName" ? 1.7 : 1,
                            textAlign: 'center',
                            justifyContent: "center",
                            backgroundColor: globalPDF.GREY_COLOR,
                            fontSize: "9px",
                            border: "1px solid lightgrey",
                            borderLeft:
                              index === 0 ? "1px solid lightgrey" : "none",
                          }}
                        >
                          <View style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 5, textAlign: "center" }}>
                            <View style={{ height: '45px', paddingTop: 7 }}>
                              <Text style={fontBold} break> {violationTitle[column]} </Text>
                            </View>
                            <View style={{ height: '25px' }}>
                              <Image src={violationImages[column]} style={{ width: "15px", height: "15px" }} />
                            </View>
                          </View>
                        </View>
                      ))}
                    </View>
                    {chunk.map((item, rowIndex) => (
                      <View
                        key={rowIndex}
                        style={{ flexDirection: "row", height: "25px" }}
                      >
                        {violationCol.map((column, colIndex) => (
                          <View
                            key={colIndex}
                            style={{
                              flex: column === "firstName" ? 1.7 : 1,
                              justifyContent: "center",
                              fontSize: 9,
                              backgroundColor: column === "totalRides" || column === "tKiloMeterTV" || column === "tTravelTimeTV" || column === "firstName" || column === "username" ? globalPDF.LIGHT_BLUE_COLOR : "transparent",
                              borderLeft:
                                item[column] !== undefined
                                  ? "1px solid lightgrey"
                                  : "none",
                              borderRight:
                                colIndex === 6
                                  ? item[column] !== undefined
                                    ? "1px solid lightgrey"
                                    : "none"
                                  : colIndex === violationCol.length - 1
                                    ? "1px solid lightgrey"
                                    : "none",
                              borderBottom:
                                item[column] !== undefined
                                  ? "1px solid lightgrey"
                                  : "none",
                            }}
                          >
                            <Text
                              style={{
                                fontSize: 9,
                                textAlign: "left",
                                paddingLeft: 10
                              }}
                              break
                            >
                              {item[column]}
                            </Text>
                          </View>
                        ))}
                      </View>
                    ))}
                  </View>
                </View>

              </View>
              <PdfFooterV1 pageNumber={chunks.length + pageIndex + 1} totalPages={chunks.length + chunksViolation.length} />
            </Page>
          ))}
        </Document>
      </PDFViewer>
    </React.Fragment>
  );
}