import React, { useEffect, useState } from "react";
//import Breadcrumbs
import Breadcrumbs from "../../components/Common/Breadcrumb";

//redux
import { useDispatch } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Progress,
  Row,
  Table,
} from "reactstrap";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";
import { RideSetailsTopRowItems } from "../../common/data/ridedetaillist";
import RiskAlertsItems from "../../common/data/riskalertsdetails.json";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import CountUp from "react-countup";
import ReactApexChart from "react-apexcharts";
import Gauge from "../AllCharts/echart/gaugechart";
import Guages from "../AllCharts/echart/gaugeChartSpeed";
import GuagesTwo from "../AllCharts/echart/gaugeChartSpeedTwo";
import GuagesThree from "../AllCharts/echart/gaugeChartThree";
import GuagesNumber from "../AllCharts/echart/geugeChartNumber";
import Doughnut from "../AllCharts/echart/doughnutchart";
import Knob from "../AllCharts/knob/knob";
import Doughnuts from "../AllCharts/echart/doughnucharttwo";
import LineApexChart from "../AllCharts/apex/chartapex";
import ColumnWithDataLabels from "../AllCharts/apex/ColumnWithDataLabels";
import LineTwo from "../AllCharts/echart/linecharttwo";
import LineColumnAreaTwo from "../AllCharts/apex/lineColumnAreaTwo";
import CardImg1 from "../../assets/images/t_animal_crossing.png";
import Caution from "../../assets/images/t_caution.png";
import Curve from "../../assets/images/t_curves.png";
import Round from "../../assets/images/Round-about.svg";
import Hillimg from "../../assets/images/t_hill.png";
import HillDownWards from "../../assets/images/t_hill_downwards.png";
import hillUp from "../../assets/images/t_hill_upwards.png";
import icyConditions from "../../assets/images/t_icy_conditions.png";
import Intersection from "../../assets/images/t_intersection.png";
import LaneMerge from "../../assets/images/t_lane_merge.png";
import LowGearArea from "../../assets/images/t_low_gear_area.png";
import MobileUse from "../../assets/images/t_mob_use.png";
import NarrowRoad from "../../assets/images/t_narrow_road.png";
import NoOverTaking from "../../assets/images/t_no_overtaking.png";
import OverSpeed from "../../assets/images/t_over_speed.png";
import PedestrianCrossing from "../../assets/images/t_pedestrian_crossing.png";
import Priority from "../../assets/images/t_priority.png";
import RailwayCrossing from "../../assets/images/t_railway_crossing.png";
import RiskOfGrounding from "../../assets/images/t_risk_of_grouping.png";
import School from "../../assets/images/t_school_zone.png";
import SlipperyRoads from "../../assets/images/t_slippery_road.png";
import Stopimg from "../../assets/images/t_stop.png";
import NoOvertakingTrucks from "../../assets/images/No-overtaking-trucks.svg";
import TrafficLight from "../../assets/images/t_traffic_light.png";
import WindImg from "../../assets/images/t_wind.png";
import WindingRoad from "../../assets/images/t_winding_road.png";
import YieldImg from "../../assets/images/t_yield.png";
import Anticipative from "../../assets/images/Ride_Details/Anticipative.png";
import ExpertImg from "../../assets/images/Ride_Details/Expert.png";
import RiskyImg from "../../assets/images/Ride_Details/Risky.png";
import UnknownImg from "../../assets/images/Ride_Details/Unknown.png";
import NormalImg from "../../assets/images/Ride_Details/Normall.png";
import TiredImg from "../../assets/images/Ride_Details/Tired.png";
import AngryImg from "../../assets/images/Ride_Details/Angry.png";
import LineColumnAreaThree from "../AllCharts/apex/lineColumnareaThree";
import DriverStateDetection from "../AllCharts/apex/driverStateDetection";
import DrivingStyleEstimation from "../AllCharts/apex/drivingStyleEstimation";
import SportiveImge from "../../assets/images/Ride_Details/Normal.png";
import EcoImg from "../../assets/images/Ride_Details/Eco.png";
import ChaoticImg from "../../assets/images/Ride_Details/Chaotic.png";
import AggressiveImg from "../../assets/images/Ride_Details/Aggressive.png";
import info from "../../assets/images/information-unscreen.gif";
import faq from "../../assets/images/faq-unscreen.gif";
import Avatar from "react-avatar";
import severeAccelerationGreen from "../../assets/images/circular-speedometer-green.svg";
import severeAccelerationYellow from "../../assets/images/circular-speedometer-yellow.svg";
import severeAccelerationRed from "../../assets/images/circular-speedometer-red.svg";
import sCorneringGreen from "../../assets/images/sCornering-green.svg";
import sCorneringYellow from "../../assets/images/sCornering-yellow.svg";
import sCorneringRed from "../../assets/images/sCornering-red.svg";
import sBrakingGreen from "../../assets/images/sudden-braking-green.svg";
import sBrakingYellow from "../../assets/images/sudden-braking-yellow.svg";
import sBrakingRed from "../../assets/images/sudden-braking-red.svg";
import "../../pages/RideDetails/rideDetailstyle.css";
import { useLocation } from "react-router-dom";
import RedAlertsBubble from "../AllCharts/echart/redAlertsBubble";
import MyRideDetailController from "../../controller/MyRideDetail/MyRideDetail.js";
import ValueFormat from "../../util/ValueFormat";
import UtilDateTime from "../../util/UtilDateTime.js";
import AnalyticalController from "../../controller/Analytical/AnalyticalController";
import { alertDataType } from "../../constant/ModelConstant.js";
import Loader from "../../components/Common/loader";
import ExportScreenPdf from "../../components/ExportScreenPdf/index.js";
import { overSpeedDataTemps } from "./RideDetailOtherAlertData";
import RideDetailOverSpeedLocations from "./RideDeatilOverSpeed";
import CategoryLine from "../AllCharts/echart/lineChartCategory";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet.heat/dist/leaflet-heat.js";
import { useSelector } from "react-redux";
import { allOverSpeedData } from "../../store/actions";
import allRideData from "../../store/chartsData/reducer";
import { accDataTemps } from "./AccCorBrakingData";

import helmet from "../../assets/images/safetyProtection/helmet.png";
import seatBelt from "../../assets/images/safetyProtection/seatBelt.png";
import justHelmet from "../../assets/images/safetyProtection/just-helmet.png";
import justSeatbelt from "../../assets/images/safetyProtection/just-seatBelt.png";
import userWithHelmet from "../../assets/images/safetyProtection/user-with-helmet.png";
import userWithOutHelmet from "../../assets/images/safetyProtection/user-without-helmet.png";
import userWithOutseatBelt from "../../assets/images/safetyProtection/user-without-seatBelt.png";
import userWithseatBelt from "../../assets/images/safetyProtection/user-with-seatBelt.png";
import tick from "../../assets/images/safetyProtection/tick-icon.png";
import Noimage from "../../assets/images/safetyProtection/no-image.png";
import blankImage from "../../assets/images/safetyProtection/blank_image.png";

import {
  SC_LOGIN_USER_FIRST_NAME,
  SC_USER_FIRST_NAME,
  SC_USER_LOGIN_TYPE,
} from "../../constant/StorageConstant";
import { SC_DIVISION_ID, SC_USER_ID } from "../../constant/StorageConstant";
import UserDrivingScoreController from "../../controller/Dashboard/UserDashboardController";
import HereMap from "./Heremap";
import html2canvas from "html2canvas";
import CSVRideData from "./csvRideData";
import { jsPDF } from "jspdf";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { displayText } from "../../constant/MessageConstant";
import ToastService from "../../components/Common/toastService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Heremap from "./Heremap";
import { protectionText } from "../../constant/protectionConstant.js";
// import CarSimulation from "./Heremap";
// import "html2canvas/dist/html2canvas.min.css";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ViewPdf from "../../components/ExportScreenPdf/indexReact2Pdf.js";
import SpeedandRiskViolation from "./SpeedandRiskViolation.js";
import ProgressBar from "react-bootstrap/ProgressBar";
import Converter from "../../components/Common/EncodeDecode.js";
import FuelSpeedData from "./FuelSpeedData.js";
import TimeBatteryEventJourneyView from "./TimeBatteryEventJourneyView.js";

import locationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIcon.svg";
import minsIcon from "../../assets/images/adminDashboard/myRidesPageIcons/myRidesMins.svg";
import OverSpeedIcon from "../../assets/images/adminDashboard/myRidesPageIcons/overSpeedIconNew.svg";
import subTypeIcons from "../../assets/images/adminDashboard/myRidesPageIcons/subTypeIcons.svg";
import AccelerationIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AccelerationIcon.svg";
import BreakingIcon from "../../assets/images/adminDashboard/myRidesPageIcons/BrakingIcon.svg";
import CorneringIcon from "../../assets/images/adminDashboard/myRidesPageIcons/CorneringIcon.svg";
import ScreenCountIcon from "../../assets/images/adminDashboard/myRidesPageIcons/ScreenCountIcon.svg";
import CallCountIcon from "../../assets/images/adminDashboard/myRidesPageIcons/CallCountIcon.svg";
import CountImage from "../../assets/images/adminDashboard/myRidesPageIcons/CountImage.svg";
import CallCountIconNewOne from "../../assets/images/adminDashboard/myRidesPageIcons/CallCountIconNew.svg";
import steeringWheel from "../../assets/images/adminDashboard/steering-wheel.svg";
import UserNameIcon from "../../assets/images/adminDashboard/myRidesPageIcons/AdminUserNameIcon.svg";

import TripIconNew from "../../assets/images/adminDashboard/myRidesPageIcons/tripIsnoNew.svg";
import poiIconNew from "../../assets/images/adminDashboard/myRidesPageIcons/poiIconNew.svg";
import zipCodeIconNew from "../../assets/images/adminDashboard/myRidesPageIcons/zipCodeIconNew.svg";
import alertIconNew from "../../assets/images/adminDashboard/myRidesPageIcons/alertIconNew.svg";

import UBIAnticipation from "../../assets/images/adminDashboard/myRidesPageIcons/AnticipationIcon.svg";
// import UBISelfConfidence from "../../assets/images/Ubi Images/UBISelfConfidence.svg";
import UBISelfConfidence from "../../assets/images/adminDashboard/myRidesPageIcons/SelfConfidenceIcon.svg";
// import UBIDrivingSkill from "../../assets/images/Ubi Images/UBIDrivingSkill.svg";
import UBIDrivingSkill from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingSkillIcon.svg";
// import UBIDrivingStyle from "../../assets/images/Ubi Images/UBIDrivingStyle.svg";
import UBIDrivingStyle from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingStyleIcon.svg";
// import UBIDrivingState from "../../assets/images/Ubi Images/UBIDrivingState.svg";
import UBIDrivingState from "../../assets/images/adminDashboard/myRidesPageIcons/DrivingStateIcon.svg";
import UBIMobileScreenUse from "../../assets/images/Ubi Images/UBIMobileScreenUse.svg";
// import UBIMobileCallUse from "../../assets/images/Ubi Images/UBIMobileCallUse.svg";
import UBIMobileCallUse from "../../assets/images/adminDashboard/myRidesPageIcons/MobileUsageIcon.svg";
// import UBIKmsDriven from "../../assets/images/Ubi Images/UBIKmsDriven.svg";
// import UBIStressStrain from "../../assets/images/Ubi Images/UBIStressStrain.svg";
import UBIStressStrain from "../../assets/images/adminDashboard/myRidesPageIcons/AccBrakingCorIcon.svg";
import UBIInfoIcon from "../../assets/images/adminDashboard/myRidesPageIcons/infoIcon.svg";
import DownloadIcon from "../../assets/images/adminDashboard/DownloadIconNew.svg";

import clock from "../../assets/images/adminDashboard/myRidesPageIcons/clockIcon.svg";
import battery from "../../assets/images/adminDashboard/myRidesPageIcons/chargerIcon.svg";
import DetailViewd from "../../assets/images/adminDashboard/myRidesPageIcons/journeyViewIconNew.svg";
import { Stack } from "@mui/material";

import dayjs from "dayjs";
import ExportScreenDownloadPdf from "../../components/ExportScreenPdf/exportScreenDownloadPdf.js";

const TableStyle = {
  table: {
    width: "100%",
    display: "table",
    borderSpacing: 0,
    border: "0.2px",
    margin: "0px",
  },
  th: {
    top: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: "#fff",
    height: "0px",
    fontWeight: 600,
    fontSize: "12px",
  },
};


const options = {
  chart: {
    height: "100px",
    type: "line",
    toolbar: { show: false },
  },
  colors: ["#D4A017"],
  stroke: {
    curve: "smooth",
    width: 1,
  },
  xaxis: {
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    labels: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

const optionsTwo = {
  chart: {
    height: "100px",
    type: "line",
    toolbar: { show: false },
  },
  colors: ["#19543E"],
  stroke: {
    curve: "smooth",
    width: 1,
  },
  xaxis: {
    title: {
      text: "Kms",
      position: "bottom",
    },
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    title: {
      text: "Risk",
    },
    type: "category",
    categories: [],
    tickAmount: 5,
    tickPlacement: "between",
    min: 0,
    max: 100,
    range: undefined,
    floating: false,
    decimalsInFloat: undefined,
    overwriteCategories: undefined,
    labels: {
      show: true,
      rotate: 0,
      rotateAlways: false,
      hideOverlappingLabels: true,
      showDuplicates: false,
      trim: false,
      minHeight: undefined,
      maxHeight: 120,
      style: {
        colors: [],
        fontSize: "12px",
        fontFamily: "Helvetica, Arial, sans-serif",
        fontWeight: 400,
        cssClass: "apexcharts-xaxis-label",
      },
      offsetX: 0,
      offsetY: 0,
      format: undefined,
      formatter: undefined,
      datetimeUTC: true,
      datetimeFormatter: {
        year: "yyyy",
        month: "MMM 'yy",
        day: "dd MMM",
        hour: "HH:mm",
      },
    },
    group: {
      groups: [],
      style: {
        colors: [],
        fontSize: "12px",
        fontWeight: 400,
        fontFamily: undefined,
        cssClass: "",
      },
    },
    axisBorder: {
      show: true,
      color: "#DFE0E1",
      height: 1,
      width: "2px",
      offsetX: 0,
      offsetY: 0,
    },
    axisTicks: {
      show: true,
      borderType: "solid",
      color: "#DFE0E1",
      height: 6,
      offsetX: 0,
      offsetY: 0,
    },

    crosshairs: {
      show: true,
      width: 1,
      position: "back",
      opacity: 0.9,
      stroke: {
        color: "#b6b6b6",
        width: 0,
        dashArray: 0,
      },
      fill: {
        type: "solid",
        color: "#B1B9C4",
        gradient: {
          colorFrom: "#D8E3F0",
          colorTo: "#BED1E6",
          stops: [0, 100],
          opacityFrom: 0.4,
          opacityTo: 0.5,
        },
      },
      dropShadow: {
        enabled: false,
        top: 0,
        left: 0,
        blur: 1,
        opacity: 0.4,
      },
    },
    tooltip: {
      enabled: true,
      formatter: undefined,
      offsetY: 0,
      style: {
        fontSize: 0,
        fontFamily: 0,
      },
    },
  },

  tooltip: {
    fixed: {
      enabled: false,
    },
    x: {
      show: false,
    },
    y: {
      title: {
        formatter: function (seriesName) {
          return "";
        },
      },
    },
    marker: {
      show: false,
    },
  },
};

const data = [
  {
    type: "ANIMAL_CROSSING",
    title: "Animal Crossing",
    overallData: 0,
    currentMonthGraphData: [0],
    image: CardImg1,
  },
  {
    type: "CAUTION",
    title: "Caution",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Caution,
  },
  {
    type: "CURVE",
    title: "Curve",
    overall: 0,
    currentMonthGraphData: [0],
    image: Curve,
  },
  {
    type: "ROUND",
    title: "Round About",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Round,
  },
  {
    type: "HILL",
    title: "Hill",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Hillimg,
  },
  {
    type: "HILL_DOWN",
    title: "Hill Downwards",
    overallData: 0,
    currentMonthGraphData: [0],
    image: HillDownWards,
  },
  {
    type: "HILL_UP",
    title: "Hill Upwards",
    overallData: 0,
    currentMonthGraphData: [0],
    image: hillUp,
  },
  {
    type: "ICY",
    title: "Icy Conditions",
    overallData: 0,
    currentMonthGraphData: [0],
    image: icyConditions,
  },
  {
    type: "INTERSECTION",
    title: "Intersection",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Intersection,
  },
  {
    type: "LANE_MERGE",
    title: "Lane Merge",
    overallData: 0,
    currentMonthGraphData: [0],
    image: LaneMerge,
  },
  {
    type: "LOW_GEAR",
    title: "Low Gear Area",
    overallData: 0,
    currentMonthGraphData: [0],
    image: LowGearArea,
  },

  {
    type: "NARROW_ROAD",
    title: "Narrow Road",
    overallData: 3,
    currentMonthGraphData: [0],
    image: NarrowRoad,
  },
  {
    type: "OVERTAKING",
    title: "No Overtaking",
    overallData: 0,
    currentMonthGraphData: [0],
    image: NoOverTaking,
  },

  {
    type: "CROSSING",
    title: "Pedestrian Crossing",
    overallData: 0,
    currentMonthGraphData: [0],
    image: PedestrianCrossing,
  },
  {
    type: "PRIORITY",
    title: "Priority",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Priority,
  },
  {
    type: "RAILWAY",
    title: "Railway Crossing",
    overallData: 0,
    currentMonthGraphData: [0],
    image: RailwayCrossing,
  },
  {
    type: "RISK_GROUNDING",
    title: "Risk Of Grounding",
    overallData: 0,
    currentMonthGraphData: [0],
    image: RiskOfGrounding,
  },
  {
    type: "SCHOOL",
    title: "School",
    overallData: 0,
    currentMonthGraphData: [0],
    image: School,
  },
  {
    type: "SLIPPERY_ROADS",
    title: "Slippery Roads",
    overallData: 0,
    currentMonthGraphData: [0],
    image: SlipperyRoads,
  },
  {
    type: "STOP",
    title: "Stop",
    overallData: 0,
    currentMonthGraphData: [0],
    image: Stopimg,
  },

  {
    type: "TRAFFIC_LIGHT",
    title: "Traffic Light",
    overallData: 0,
    currentMonthGraphData: [0],
    image: TrafficLight,
  },
  {
    type: "WIND",
    title: "Wind",
    overallData: 0,
    currentMonthGraphData: [0],
    image: WindImg,
  },
  {
    type: "WINDING_ROAD",
    title: "Winding Road",
    overallData: 0,
    currentMonthGraphData: [0],
    image: WindingRoad,
  },
  {
    type: "YIELD",
    title: "Yield",
    overallData: 0,
    currentMonthGraphData: [0],
    image: YieldImg,
  },
];

const otherDataList = [
  {
    type: "OVER_SPEED",
    name: "Overspeed",
    img: "overspeed.svg",
    count: 0,
    duration: 0,
    kilometer: 0,
  },
  {
    type: "MOBILE_CALL_USE",
    name: "Mobile call use",
    img: "mobilecallImage.svg",
    count: 0,
    duration: 0,
    kilometer: 0,
  },
  {
    type: "MOBILE_SCREEN_USE",
    name: "Mobile screen use",
    img: "mobileScreen.svg",
    count: 0,
    duration: 0,
    kilometer: 0,
  },
];

const GOOGLE_MAP_API_KEY = "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE";

// load google map script
const loadGoogleMapScript = (callback) => {
  if (
    typeof window.google === "object" &&
    typeof window.google.maps === "object"
  ) {
    callback();
  } else {
    const googleMapScript = document.createElement("script");
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}&libraries=geometry`;
    window.document.body.appendChild(googleMapScript);
    googleMapScript.addEventListener("load", callback);
  }
};

const RideDetails = () => {
  const history = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [redRiskDistanceData, setRedRiskDistanceData] = useState(0);
  const [dataLength, setDataLength] = useState(null);
  const [apiData, setApiData] = useState([]);
  const [rideNameData, setRideNameData] = useState("");


  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleLeave = () => {
    setIsHovered(false);
  };

  // const location = useLocation();

  const [overSpeedDataList, setOverSpeedDataList] =
    useState(overSpeedDataTemps);
  const [loginType, setLoginType] = useState(
    localStorage.getItem(SC_USER_LOGIN_TYPE)
  );
  const [accCorBrak, setAccCorBrak] = useState(accDataTemps);
  const [locationParams, setLocationParams] = useState({
    rideId: "",
    userId: "",
    divisionId: "",
  });
  const [mapData, setMapData] = useState({
    tripLatLngArray: [],
    tripLatLongList: [],
    tripStartLatLong: "",
    tripEndLatLong: "",
    subCategory: [],
    category: [],
    tripLatLngObject: {},
  });
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_LOGIN_USER_FIRST_NAME)
  );
  const [userName, setUserName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  useEffect(() => {
    setUserFirstName(localStorage.getItem(SC_LOGIN_USER_FIRST_NAME));
  }, [userFirstName]);

  const WScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
  };

  useEffect(() => {
    WScroll();
  }, []);

  const Location = useLocation();
  const [rideCategory, setRideCategory] = useState(""); // ridecategory added

  useEffect(() => {
    const queryParams = new URLSearchParams(Location.search);

    const UID = queryParams.get("userId");
    const DID = queryParams.get("divisionId");
    const RID = queryParams.get("rideId");
    const RIDE_CATEGORY = queryParams.get("rideCategory"); // ridecategory added

    const decodedUID = Converter.decrypt(decodeURIComponent(UID));
    const decodedDID = Converter.decrypt(decodeURIComponent(DID));
    const decodedRID = Converter.decrypt(decodeURIComponent(RID));
    const decodedRIDE_CATEGORY = Converter.decrypt(decodeURIComponent(RIDE_CATEGORY));
    setRideCategory(decodedRIDE_CATEGORY); // ridecategory added
    setLocationParams((prev) => {
      return {
        ...prev,
        rideId: decodedRID,
        userId: decodedUID,
        divisionId: decodedDID,
      };
    });
  }, [Location]);
  // _______________________________________________________
  const userType = localStorage.getItem(SC_USER_LOGIN_TYPE);
  const [barOption, setBarOption] = useState(options);
  const [filterDays, setFilterDays] = useState("CURRENT_YEAR");
  const [otherData, setOtherData] = useState([]);
  const [otherDataLabel, setOtherDataLabel] = useState([]);

  const [mobileUseDataList, setMobileUseDataList] = useState([]);
  const [mobileScreenDataList, setMobileScreenDataList] = useState([]);
  const [userId, setUserId] = useState(localStorage.getItem(SC_USER_ID));
  const [divisionId, setDivisionId] = useState(
    localStorage.getItem(SC_DIVISION_ID)
  );
  const [indexStart, setIndexStart] = useState(0);
  const [riskAlertData, setRiskAlertData] = useState([]);
  const [stressData, setstressData] = useState([]);
  const [riskAlertsDataDisplay, setRiskAlertsDataDisplay] = useState(false);
  const [mapDisplay, setMapDisplay] = useState(false);
  const [categoryLineChart, setCategoryLineChart] = useState(null);
  const [drivingStyleDataCount, setDrivingStyleDataCount] = useState(null);
  const [safetyData, setSafetyData] = useState({
    mainImage: null,
    subImage: "no-image.png",
    tickImage: null,
    text: null,
    color: null,
  });
  const passLatlog = () => {
    const element = document.getElementById("mapContainerDisplay");

    if (element) {
      element.scrollIntoView({
        behavior: "smooth", // You can use 'auto' instead of 'smooth' if you prefer
        block: "start", 
      });
    }
  };

  let getAlertSummaryy = async () => {
    setShowLoader((current) => current + 1);
    try {
      var alertsType = "";
      var result = await UserDrivingScoreController.stressStrainSummary(
        indexStart,
        locationParams.rideId,
        locationParams.divisionId,
        locationParams.userId,
        rideCategory // ridecategory added
      );
      var resultJSON = JSON.parse(result);
      // console.log(resultJSON,"resultJSONNew")
      setShowLoader((current) => current - 1);
      var status = resultJSON.status;
      var updatedTableData = [];
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log(
        //   "userDashboardSummaryData: ",
        //   JSON.stringify(resultJSONData)
        // );
        var alertSummeryXAxis = [];
        var alertSummeryData = [];
        var alertValueHighRisk = [];
        var highRiskValueData = [];
        var latLong = [];
        var tripRiskList = [];
        var tripRiskLatLongList = [];
        var overSpeedData = [];
        var mobileScreenData = [];
        var stressStrainDataList = [];
        var stressStrainDataTemp = [];
        var mobileUseData = [];
        // var centerLatLong = { lat: 11.004556, lng: 76.961632 };
        var tripStartLatLong = "";
        var tripEndLatLong = "";
        var tripRiskSigList = [];
        // setDataLength(resultJSONData.length)
        var alertImage = "";
        resultJSONData.map((value, i) => {
          alertValueHighRisk = [];
          var deviceDataField = ValueFormat.parseAsObject(
            value.deviceDataField
          );
          var latitudeTemp = ValueFormat.parseAsString(
            deviceDataField.latitude
          );
          var longitudeTemp = ValueFormat.parseAsString(
            deviceDataField.longitude
          );
          var category =
            value.category != "" && value.category != null
              ? value.category
              : "";

          alertsType =
            value.category != "" && value.category != null
              ? ValueFormat.capitalizeFirstLetterAndFormat(value.category)
              : "";
          var subCategoryTemp =
            value.subCategory != "" && value.subCategory != null
              ? value.subCategory
              : "";
          var subCategory =
            value.subCategory != "" && value.subCategory != null
              ? ValueFormat.capitalizeFirstLetterAndFormat(value.subCategory)
              : "";
          var kiloMeter =
            value.kilo_meter != "" && value.kilo_meter != null
              ? value.kilo_meter
              : "";
          var subType = ValueFormat.parseAsString(value.subType);
          var startDate = ValueFormat.parseAsString(value.createdAtToTimeZone);
          var rideName = ValueFormat.parseAsString(value.name);
          
          var rideId = ValueFormat.parseAsString(value.deviceId);
          var deviceDataField = value.deviceDataField;
          var speed =
            value.speed != "" && value.speed != null ? value.speed : "0";
          var subCategoryLevel = ValueFormat.parseAsString(
            value.subCategoryLevel
          );
          var speedLimit = ValueFormat.parseAsFloat(value.speedLimit, 2);
          var latLongTempMap = latitudeTemp + "," + longitudeTemp;

          if (subCategory.length > 0) {
            tripRiskSigList.push(subCategory);
          } else {
            tripRiskSigList.push(category);
          }

          if (i == 0) {
            tripStartLatLong = latLongTempMap;
          } else if (i == resultJSONData.length - 1) {
            tripEndLatLong = latLongTempMap;
          } else {
            tripRiskLatLongList.push(latLongTempMap);
          }

          var xAxisLabel = "-";
          if (category == "START_DATA" || category == "END_DATA") {
            xAxisLabel = alertsType;
          } else if (
            category == "ALERT_DATA" ||
            category == "STRESS_STRAIN_DATA" ||
            category == "MANUAL_DATA"
          ) {
            xAxisLabel = subCategory;
          } else if (category == "DISTANCE_DATA") {
            xAxisLabel = kiloMeter + " Km";
          }

          var overSpeedDataResult = {
            rideId: rideId,
            rideName: rideName,
            date: startDate,
            distance: kiloMeter,
            speedLimit: speedLimit,
            speed: speed,
          };
          var mobileScreenDataResult = {
            rideId: rideId,
            rideName: rideName,
            date: startDate,
            distance: kiloMeter,
            speed: speed,
          };
          var stressStrainData = {
            rideId: rideId,
            rideName: rideName,
            date: startDate,
            type: subCategory,
            subType: subType,
            distance: kiloMeter,
            speed: speed,
            subCategoryLevel: subCategoryLevel,
          };
          if (subCategory == "Over speed") {
            overSpeedData.push(overSpeedDataResult);
          } else if (subCategory == "Mobile screen") {
            mobileScreenData.push(mobileScreenDataResult);
          } else if (subCategory == "Mobile use") {
            mobileUseData.push(mobileScreenDataResult);
          } else if (category == "STRESS_STRAIN_DATA") {
            // console.log(stressStrainData,"stressStrainData")
            stressStrainDataTemp.push(stressStrainData);
          }
          alertSummeryXAxis.push(xAxisLabel);
          //alertSummeryData.push(risk);
          alertSummeryData.push(speed);
        });
        setAcbTableData(
          Object.values(stressStrainDataTemp).some((value) => value !== 0)
        );

        // console.log("ssdata", stressStrainDataTemp);
        setstressData(stressStrainDataTemp);
        setRiskAlertData(tripRiskList);
        setOverSpeedDataList(overSpeedData);
        setMobileUseDataList(mobileUseData);
        setMobileScreenDataList(mobileScreenData);


      } else {
        var code = resultJSON.error.code;
        if (code == "E1206") {
          setAcbTableData("failed");
        }
      }
    } catch (e) {
      // console.log("otherAlertsErr" + e);
    }
  };
  useEffect(() => {
    //   getTripRiskData();
    getAlertSummaryy();
    // console.log(apiData,"apidataaa")
  }, [locationParams]);
  // _______________________________________________________

  const [topWidgetData, setTopWidgetData] = useState(RideSetailsTopRowItems);  
  const [topWidgetDataPdf, setTopWidgetDataPdf] = useState({
    rideName: null,
    driverBehaviorScore: null,
    drivingPoints: null,
    selfConfidencePoints: null,
    drivingSkillPoints: null,
    anticipationPoints: null,
    driverCategory: null,
    ratings: null,
    travelTime: null,
    startTime: null,
    endTime: null,
    riskAlertCount: null,
    otherAlertCount: null,
    redRiskHistogram: null,
    redRiskDuration: null,
    redRiskDistance: null,
    cppValue: null,
    cppLevel: null,
    batteryLevelStart: null,
    batteryLevelEnd: null,
    event: null,
    rideSummary: null,
    distance: null,

  });
  const [value, setvalue] = useState(35);
  const [value_cur, setvalue_cur] = useState(29);
  const [drivingStatus, setDrivingStatus] = useState({
    drivingScore: 0,
    selfConfidence: 0,
    anticipation: 0,
    drivingSkill: 0,
    totalKilometer: 0,
  });
  const [rideNameTemp, setRideNameTemp] = useState();

  const [summary, setSummary] = useState("N/A");
  const [dayNightPercentage, setDayNightPercentage] = useState({
    day: 0,
    night: 0,
    rural: 0,
    highway: 0,
    urban: 0,
    totalKilometer:0,
    urbanKilometers: 0,
    ruralKilometers: 0,
    highwayKilometers: 0,
  });
  const [timeBatteryEventJourneyViewObj, setTimeBatteryEventJourneyViewObj] = useState({
    batteryLevelStart: 0,
    batteryLevelEnd: 0,
    startDateTimeToTimeZone: "N/A",
    endDateTimeToTimeZone: "N/A",
    subType: "N/A",
    accidentType: "",
    userId: "",
    divisionId: "",
    rideId: "",
    rideNo: ""
  });
  const [alertSummaryXAxis, setAlertSummaryXAxis] = useState({
    xaxis: [],
    data: [],
  });
  const [distributionData, setDistributionData] = useState({
    xaxis: [],
    data: [],
    customData: [],
  });
  const [driverData, setDriverData] = useState({
    xaxis: [],
    riskStyleData: [],
    driverStateData: [],
    driverStyleData: [],
  });
  const [riskStyleList, setRiskStyleList] = useState({
    riskStyleAnticipation: 0,
    riskStyleExpert: 0,
    riskStyleRisky: 0,
    riskStyleUnknown: 0,
  });
  const [driverStateList, setDriverStateList] = useState({
    driverNormal: 0,
    driverTried: 0,
    driverAngry: 0,
    driverUnknown: 0,

    distanceStateUnknown: 0,
    distanceStateNormal: 0,
    distanceStateTired: 0,
    distanceStateAngry: 0,

    durationStateUnknown: 0,
    durationStateNormal: 0,
    durationStateTired: 0,
    durationStateAngry: 0,

    lastKmValue: 0,
    totalStateCount: 0,
  });
  const [driverStyleList, setDriverStyleList] = useState({
    drivingSportive: 0,
    drivingAggressive: 0,
    drivingChaotic: 0,
    drivingNormal: 0,
    drivingEco: 0,
    drivingUnknown: 0,

    distanceStyleSportive: 0,
    distanceStyleAggressive: 0,
    distanceStyleChaotic: 0,
    distanceStyleNormal: 0,
    distanceStyleEco: 0,
    distanceStyleUnknown: 0,

    durationStyleSportive: 0,
    durationStyleAggressive: 0,
    durationStyleChaotic: 0,
    durationStyleNormal: 0,
    durationStyleEco: 0,
    durationStyleUnknown: 0,

    lastKmValue: 0,
    totalStyleCount: 0,
  });

  const [redBubblePlot, setRedBubblePlot] = useState([]);
  // console.log("redBubblePlot", redBubblePlot);
  const [riskDataTable, setRiskDataTable] = useState([]);
  // console.log("riskDataTable",riskDataTable);
  const addEndDataIfMissing = (data, lastDData) => {
    if (data != undefined && data.length > 0) {
      const lastData = data[data.length - 1];

      if (lastData.category !== "END_DATA") {
        const endDataCopy = {
          ...lastData,
          category: "END_DATA",
          alertImage: "finish.png",
          tripIcon: "alert_end.png",
          timeVal: UtilDateTime.formatTimeAsHHMMSS(
            lastDData.createdAtToTimeZone
          ),
          distanceVal: ValueFormat.parseAsFloat(lastDData.kiloMeter, 2),
          pointOfInterest: ValueFormat.parseAsFloat(
            lastDData.pointOfInterest,
            2
          ),
          speedVal: ValueFormat.parseAsFloat(lastDData.speed, 0),
          zipcodeVal: ValueFormat.parseAsFloat(lastDData.zipCode, 0),
        };
        setRiskDataTable(() => [...data, endDataCopy]);
      } else {
        setRiskDataTable(data);
      }
    }
  };

  function splitArrayIntoChunks(arr) {
    const chunkSize = 2;
    const result = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      result.push(arr.slice(i, i + chunkSize));
    }
    return result;
  }
  const [riskDataList, setRiskDataList] = useState(splitArrayIntoChunks(data) || []);
  // console.log(riskDataList, "riskDataListkriskDataList1")
  useEffect(() => {

    const array = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    // const splitArray = ;
    // console.log(splitArray);
  }, [])

  const [riskDataListleng, setRiskDataListleng] = useState(null);
  const [alertCount, setAlertCount] = useState(0);
  const [alertValidate, setAlertValiDate] = useState(false);
  // console.log("ppop", alertCount);
  const [stressStrainData, setStressStrainData] = useState({
    sCorneringLow: 0,
    sCorneringMedium: 0,
    sCorneringHigh: 0,
    sAccelerationLow: 0,
    sAccelerationMedium: 0,
    sAccelerationHigh: 0,
    sBrakingLow: 0,
    sBrakingMedium: 0,
    sBrakingHigh: 0,
  });
  const [accelerationData, setAccelerationData] = useState(null);
  const [totalAlerts, setTotalAlerts] = useState(0);
  const [heatMapData, setHeatmapData] = useState([]);
  const [loadMap, setLoadMap] = useState(false);
  const [otherRiskData, setOtherRiskData] = useState(otherDataList);
  const [showLoader, setShowLoader] = useState(0);
  const [categoryType, setCategoryType] = useState({
    safety: "",
    behaviour: "",
  });
  const [histogramData, setHistogramData] = useState(false);
  const [summaryData, setSummaryData] = useState(false);
  const [acbTableData, setAcbTableData] = useState(false);
  const [detectionData, setDetectionData] = useState(false);
  const [styleEstimationData, setStyleEstimationData] = useState(false);
  const [getPanelId, setPanelId] = useState(null);
  const [getMapConater, setMapContainer] = useState(null);
  const [getMapUi, setMapUi] = useState(null);
  const [driverStateDetectionData, setDriverStateDetection] = useState(null);
  const [mapToolTipData, setMapTooltipData] = useState({
    drivingScore: 0,
    rideNo: "",
  });
  useEffect(() => {
    loadGoogleMapScript(() => {
      setLoadMap(true);
    });
  }, []);

 

  const cppFunction = (data) => {
    let value = "";
    let colorCode = "";
    let levelDescription = "";

    if (data > 0 && data <= 50) {
      value = "L1";
      colorCode = "#ff9b9b";
      levelDescription = "Range (1-50)";
    } else if (data > 50 && data <= 100) {
      value = "L2";
      colorCode = "#ff8585";
      levelDescription = "Range (50-100)";
    } else if (data > 100 && data <= 150) {
      value = "L3";
      colorCode = "#ff6e6e";
      levelDescription = "Range (100-150)";
    } else if (data > 150 && data <= 200) {
      value = "L4";
      colorCode = "#ff5757";
      levelDescription = "Range (150-200)";
    } else if (data > 200 && data <= 250) {
      value = "L5";
      colorCode = "#ff4040";
      levelDescription = "Range (200-250)";
    } else if (data == 0) {
      value = "L0";
      colorCode = "#7ABA78";
      levelDescription = "Range (0)";
    } else if (data > 250) {
      value = "L6";
      colorCode = "#ff2929";
      levelDescription = "Range (>250)";
    } else {
      value = "-";
      colorCode = "#ffff";
      levelDescription = "No point available";
    }

    return { value, colorCode, levelDescription };
  };
  const [cppValue, setCppValue] = useState(0);

  const leaderBoardData = async () => {
    // setShowLoader((current) => current + 1);
    // var result = await MyRideDetailController.myRideDetailUserLeaderBoardWithRideCategory(
    var result = await MyRideDetailController.rideDtailTopApi(
      locationParams.rideId,
      locationParams.divisionId,
      locationParams.userId,
      rideCategory // ridecategory added
    );
    // setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    var updatedTableData = [];
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      setDataLength(resultJSONData.length);
      // console.log(resultJSONData, "resultJSONDataresultJSONData");
      
      var setBehavior = "";

      // console.log("leaderBoardData: ",resultJSONData);
      resultJSONData.map((value) => {
        var rideBox = ValueFormat.parseAsInt(value.rideBox.rideBox);
        var drivingScore = Math.round(ValueFormat.parseAsInt(value.drivingScore));
        var drivingScoreFullValue = ValueFormat.parseAsInt(value.drivingScore);
        var riskSlot10count = ValueFormat.parseAsInt(value.riskSlot10count);
        var riskHistogramDuration = riskSlot10count / 900;
        var riskSlot10Distance = ValueFormat.parseAsFloat(value.riskSlot10Distance,2);
        var riskSlot10Duration = ValueFormat.parseAsFloat(value.riskSlot10Duration,2);
        var riskHistogramDurationdata = ValueFormat.parseAsFloat(riskHistogramDuration, 2);
        var selfConfidence = Math.round(ValueFormat.parseAsInt(value.selfConfidence));
        var anticipation = Math.round(ValueFormat.parseAsInt(value.anticipation));
        var kiloMeter = ValueFormat.parseAsFloat(value.kiloMeter, 2);
        var drivingSkill = Math.round(ValueFormat.parseAsInt(value.drivingSkill));
        // var scoreSummary = drivingScore >= 93 ? " (Good Score)" : "";
        var analyticServerStatus = ValueFormat.parseAsString(value.analyticServerStatus);
        var deviceSafetyField = ValueFormat.parseAsObject(value.deviceSafetyField);
        var helmetAloneCount = ValueFormat.parseAsInt(deviceSafetyField.helmetAloneCount);
        var userWithHelmetCount = ValueFormat.parseAsInt(deviceSafetyField.userWithHelmetCount);
        var userWithoutHelmetCount = ValueFormat.parseAsInt(deviceSafetyField.userWithoutHelmetCount);
        var userWithSeatbeltCount = ValueFormat.parseAsInt(deviceSafetyField.userWithSeatbeltCount);
        var userWithoutSeatbeltCount = ValueFormat.parseAsInt(deviceSafetyField.userWithoutSeatbeltCount);
        var cpp = value.crashProbabilityScore;

        setCppValue(cpp);

        if (analyticServerStatus == "SUCCESS") {
          if (userWithHelmetCount > 0) {
            setSafetyData((prev) => {
              return {
                ...prev,
                subImage: "user-with-helmet.png",
                text: protectionText.user_with_helmet,
                color: "black",
              };
            });
          } else if (userWithHelmetCount == 0 && userWithSeatbeltCount > 0) {
            setSafetyData((prev) => {
              return {
                ...prev,
                subImage: "user-with-seatBelt.png",
                text: protectionText.user_with_seatbelt,
                color: "black",
              };
            });
          } else if (userWithHelmetCount == 0 && userWithSeatbeltCount == 0 && helmetAloneCount > 0) {
            setSafetyData((prev) => {
              return {
                ...prev,
                subImage: "just-helmet.png",
                text: protectionText.just_helmet,
                color: "red",
              };
            });
          } else if (userWithoutHelmetCount > 0) {
            setSafetyData((prev) => {
              return {
                ...prev,
                subImage: "user-without-helmet.png",
                text: protectionText.user_without_helmet,
                color: "red",
              };
            });
          } else if (userWithoutHelmetCount == 0 && userWithoutSeatbeltCount > 0) {
            setSafetyData((prev) => {
              return {
                ...prev,
                subImage: "user-without-seatBelt.png",
                text: protectionText.user_without_seatbelt,
                color: "red",
              };
            });
          } else if (userWithHelmetCount == 0 && userWithSeatbeltCount == 0 && userWithoutHelmetCount == 0 && userWithoutSeatbeltCount == 0 && helmetAloneCount == 0) {
            setSafetyData((prev) => {
              return {
                ...prev,
                subImage: "blank_image.png",
                text: protectionText.image_without_data,
                color: "red",
              };
            });
          }
        } else {
          setSafetyData((prev) => {
            return {
              ...prev,
              subImage: "no-image.png",
              text: protectionText.no_image,
              color: "red",
            };
          });
        }

        var antiCalc = 100 - anticipation;
        if (
          antiCalc >= 0 &&
          antiCalc <= 6 &&
          drivingSkill >= 0 &&
          drivingSkill <= 50
        ) {
          setBehavior = "Incautious";
        } else if (
          antiCalc >= 0 &&
          antiCalc <= 6 &&
          drivingSkill > 50 &&
          drivingSkill <= 100
        ) {
          setBehavior = "Expert";
        } else if (
          antiCalc >= 7 &&
          antiCalc <= 16 &&
          drivingSkill >= 0 &&
          drivingSkill <= 50
        ) {
          setBehavior = "Less Adequate";
        } else if (
          antiCalc >= 7 &&
          antiCalc <= 16 &&
          drivingSkill > 50 &&
          drivingSkill <= 100
        ) {
          setBehavior = "Adequate";
        } else if (
          antiCalc >= 17 &&
          antiCalc <= 100 &&
          drivingSkill >= 0 &&
          drivingSkill <= 50
        ) {
          setBehavior = "unProfessional";
        } else if (
          antiCalc >= 17 &&
          antiCalc <= 100 &&
          drivingSkill > 50 &&
          drivingSkill <= 100
        ) {
          setBehavior = "Cautious";
        }

        setCategoryType((pre) => {
          return { ...pre, behaviour: setBehavior };
        });
        var summaryRide = "";
        if (drivingScore > 90) {
          setSummary(
            "You drive safely. You have excellent driving practice and you have a nature of very cautious"
          );
          summaryRide = "You drive safely. You have excellent driving practice and you have a nature of very cautious"
        } else if (drivingScore > 60 && drivingScore <= 90) {
          setSummary(
            "You are surprised from time to time by the singularities of the road poorly anticipated. Yet you have good driving practice and a normal self-confidence. Learn how to focus your attention on key places that correspond to alerts and you will lower your risk."
          );
          summaryRide = "You are surprised from time to time by the singularities of the road poorly anticipated. Yet you have good driving practice and a normal self-confidence. Learn how to focus your attention on key places that correspond to alerts and you will lower your risk."
        } else {
          setSummary(
            "Your driving is risky. You have good driving practice and a normal self-confidence. You have probably taken some bad habits to urgently fix to lower your risk."
          );
          summaryRide = "Your driving is risky. You have good driving practice and a normal self-confidence. You have probably taken some bad habits to urgently fix to lower your risk."
        }
        var rideName = ValueFormat.parseAsString(value.name).slice(5);
        var travelTime = ValueFormat.parseAsFloat(value.travelTime, 2);
        var startDateTimeZone = value.startDateTimeToTimeZone;
        var startTime = UtilDateTime.formatTimeAsHHMM(startDateTimeZone);

        var endTime = UtilDateTime.formatTimeAsHHMM(value.endDateTimeToTimeZone);
        var alertDataCount = ValueFormat.parseAsString(value.alertDataCount);
        var manualDataCount = ValueFormat.parseAsString(value.manualDataCount);
        var subCategory = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.subCategory, "-"));
        var category = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.category, "-"));

        setMapTooltipData((prev) => { return { ...prev, drivingScore: drivingScore, rideNo: rideName }; });

        var driverCategory =
          rideBox == 1
            ? "Safe Driver"
            : rideBox == 2
              ? "Sportive Driver"
              : rideBox == 3
                ? "Quite Safe Driver"
                : rideBox == 4
                  ? "Reckless Driver"
                  : rideBox == 5
                    ? "Quite Dangerous Driver"
                    : "Dangerous Driver";

        setCategoryType((pre) => { return { ...pre, safety: driverCategory }; });

        var dayPercentage = ValueFormat.parseAsFloat(value.dayPercentage, 0);
        var nightPercentage = 100 - dayPercentage;

        var ruralPercent = ValueFormat.parseAsFloat(value.ruralPercent, 0);

        var urbanPercent = ValueFormat.parseAsFloat(value.urbanPercent, 0);
        var urbanKilometer = ValueFormat.parseAsFloat(value.urbanKilometer, 2);
        var ruralKilometer = ValueFormat.parseAsFloat(value.ruralKilometer, 2);
        var highwayPercent = ValueFormat.parseAsFloat(value.highwayPercent, 0);
        var highwayKilometer = ValueFormat.parseAsFloat(value.highwayKilometer, 2);

        // ------------------------------------------------------------------------------------------------------------- //
        var batteryLevelStart = ValueFormat.parseAsFloat(value.batteryLevelStart, 0) || 0;
        var batteryLevelEnd = ValueFormat.parseAsFloat(value.batteryLevelEnd, 0) || 0;
        var startDateTimeToTimeZone = ValueFormat.parseAsString(value.startDateTimeToTimeZone) || "2024-01-01 00:00:00 (IST)";
        var endDateTimeToTimeZone = ValueFormat.parseAsString(value.endDateTimeToTimeZone) || "2024-01-01 00:00:00 (IST)";
        var subType = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.subType) || "N/A");
        var accidentType = ValueFormat.parseAsString(value.accidentType) || "";
        var userId = ValueFormat.parseAsString(value.userId);
        var divisionId = ValueFormat.parseAsString(value.divisionId);
        var rideId = ValueFormat.parseAsString(value.deviceId);
        var rideNo = ValueFormat.parseAsString(value.name);


        setTimeBatteryEventJourneyViewObj({
          batteryLevelStart: batteryLevelStart,
          batteryLevelEnd: batteryLevelEnd,
          startDateTimeToTimeZone: startDateTimeToTimeZone,
          endDateTimeToTimeZone: endDateTimeToTimeZone,
          subType: subType,
          accidentType: accidentType,
          userId: userId,
          divisionId: divisionId,
          rideId: rideId,
          rideNo: rideNo

        });

        var totalEfficiency =
          parseFloat(ValueFormat.parseAsFloat(value.drivingScorePoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.anticipationPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.selfConfidencePoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.drivingSkillPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.drivingStylePoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.drivingStatePoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.kmsDrivenPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.mobileCallPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.mobileScreenPoint, 1)) +
          parseFloat(ValueFormat.parseAsFloat(value.accBrakCorPoint, 1));
          
        var scoreSummary = totalEfficiency >= 93 ? " (Good Score)" : "";


        var ratings = "";
        if (totalEfficiency >= 0 && totalEfficiency <= 80) {
          ratings = "Poor";
        } else if (totalEfficiency >= 81 && totalEfficiency <= 93) {
          ratings = "Fair";
        } else if (totalEfficiency >= 94 && totalEfficiency <= 100) {
          ratings = "Good";
        } else {
          ratings = "N/A"
        }

        // ---------------------------------------------------------------------------------------------------------------------------------------------- //
        var ObjectResult = {
          drivingScore: drivingScore,
          selfConfidence: selfConfidence,
          drivingSkill: drivingSkill,
          anticipation: anticipation,
          totalKilometer: kiloMeter,
        };

        // New


        var rideName = ValueFormat.parseAsString(value.name);
        setRideNameData(rideName)
        var drivingCategory = ValueFormat.parseAsString(ValueFormat.capitalizeFirstLetterAndFormat(value.calculatedDrivingCategory));
        var drivingBehaviourScore =
          ValueFormat.parseAsFloat(value.drivingBehaviourScore, 0) ||
          0;
        var calculatedDrivingScore =
          ValueFormat.parseAsFloat(
            value.calculatedDrivingScore,
            0
          ) || 0;
          // console.log(value.calculatedDrivingScore,"calculatedDrivingScore")
        var userId = ValueFormat.parseAsString(value.userId);
        var overallDrivingScore =
          ValueFormat.parseAsFloat(value.drivingScorePoint, 0) || 0;
        var overallAnticipation =
          ValueFormat.parseAsFloat(value.anticipationPoint, 0) || 0;
        var overallSelfConfidence =
          ValueFormat.parseAsFloat(value.selfConfidencePoint, 0) ||
          0;
        var overallDrivingSkill =
          ValueFormat.parseAsFloat(value.drivingSkillPoint, 0) || 0;
        var dStyleValue =
          ValueFormat.parseAsFloat(value.drivingStylePoint, 0) || 0;
        var dStateValue =
          ValueFormat.parseAsFloat(value.drivingStatePoint, 0) || 0;
        var kmsDriven =
          ValueFormat.parseAsFloat(value.kmsDrivenPoint, 0) || 0;
        var mobileCallValue =
          ValueFormat.parseAsFloat(value.mobileCallPoint, 2) || 0;
        var mobileScreenValue =
          ValueFormat.parseAsFloat(value.mobileScreenPoint, 2) || 0;
        var mobileUsage =
          parseFloat(value.mobileCallPoint) +
          parseFloat(value.mobileScreenPoint);
        var mobileUsageTotal = ValueFormat.parseAsFloat(mobileUsage, 0) || 0
        // console.log(mobileCallValue, mobileScreenValue, "mobileScreenValue");
        var abcPoint =
          ValueFormat.parseAsFloat(value.accBrakCorPoint, 0) || 0;
        var cpp =
          ValueFormat.parseAsFloat(value.crashProbabilityScore, 0) ||
          0;
        var crashProbabilityScore =
          ValueFormat.parseAsFloat(value.crashProbabilityScore, 0) ||
          0;
        // console.log(cpp, value.crashProbabilityScore, "cppppp");

        var totalEfficiencyRaw =
          parseFloat(
            ValueFormat.parseAsFloat(value.drivingScorePoint, 2)
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(value.anticipationPoint, 2)
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(value.selfConfidencePoint, 2)
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(value.drivingSkillPoint, 2)
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(value.drivingStylePoint, 2)
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(value.drivingStatePoint, 2)
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(value.kmsDrivenPoint, 2)
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(value.mobileCallPoint, 2)
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(value.mobileScreenPoint, 2)
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(value.accBrakCorPoint, 2)
          );

        var totalEfficiency = ValueFormat.parseAsFloat(totalEfficiencyRaw, 0);
        var totalEfficiencyTV = ValueFormat.parseAsFloat(
          totalEfficiencyRaw,
          2
        );
        var totalEfficiencyTVNew = ValueFormat.parseAsFloat(
          value.calculatedDrivingScore,
          2
        );

        var overallDrivingScoreTooltipValue =
          ValueFormat.parseAsFloat(value.drivingScorePoint, 2) ||
          "-";
        var overallAnticipationTooltipValue =
          ValueFormat.parseAsFloat(value.anticipationPoint, 2) ||
          "-";
        var overallSelfConfidenceTooltipValue =
          ValueFormat.parseAsFloat(value.selfConfidencePoint, 2) ||
          "-";
        var overallDrivingSkillTooltipValue =
          ValueFormat.parseAsFloat(value.drivingSkillPoint, 2) ||
          "-";
        var dStyleValueTooltipValue =
          ValueFormat.parseAsFloat(value.drivingStylePoint, 2) ||
          "-";
        var dStateValueTooltipValue =
          ValueFormat.parseAsFloat(value.drivingStatePoint, 2) ||
          "-";
        var kmsDrivenTooltipValue =
          ValueFormat.parseAsFloat(value.kmsDrivenPoint, 2) || "-";
        var mobileCallValueTooltipValue =
          ValueFormat.parseAsFloat(value.mobileCallPoint, 2) || "-";
        var mobileScreenValueTooltipValue =
          ValueFormat.parseAsFloat(value.mobileScreenPoint, 2) ||
          "-";
        var abcPointTooltipValue =
          ValueFormat.parseAsFloat(value.accBrakCorPoint, 2) || "-";

        var efficiency =
          ValueFormat.parseAsFloat(value.drivingEfficiency, 0) ||
          "-";
        var efficiencyTooltipValue =
          ValueFormat.parseAsFloat(value.drivingEfficiency, 2) ||
          "-";

        var totalSevereAccelerationLowCount =
          ValueFormat.parseAsFloat(
            value.severeAccelerationLowCount,
            2
          ) || "-";
        var totalSevereAccelerationMediumCount =
          ValueFormat.parseAsFloat(
            value.severeAccelerationMediumCount,
            2
          ) || "-";
        var totalSevereAccelerationHighCount =
          ValueFormat.parseAsFloat(
            value.severeAccelerationHighCount,
            2
          ) || "-";

        var totalAccelerationCount =
          parseFloat(
            ValueFormat.parseAsFloat(
              value.severeAccelerationLowCount,
              0
            )
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(
              value.severeAccelerationMediumCount,
              0
            )
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(
              value.severeAccelerationHighCount,
              0
            )
          );

        var totalSevereBrakingLowCount =
          ValueFormat.parseAsFloat(value.severeBrakingLowCount, 2) ||
          "-";
        var totalSevereBrakingMediumCount =
          ValueFormat.parseAsFloat(
            value.severeBrakingMediumCount,
            2
          ) || "-";
        var totalSevereBrakingHighCount =
          ValueFormat.parseAsFloat(
            value.severeBrakingHighCount,
            2
          ) || "-";

        var totalBrakingCount =
          parseFloat(
            ValueFormat.parseAsFloat(value.severeBrakingLowCount, 0)
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(
              value.severeBrakingMediumCount,
              0
            )
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(value.severeBrakingHighCount, 0)
          );

        var totalSevereCorneringLowCount =
          ValueFormat.parseAsFloat(
            value.severeCorneringLowCount,
            2
          ) || "-";
        var totalSevereCorneringMediumCount =
          ValueFormat.parseAsFloat(
            value.severeCorneringMediumCount,
            2
          ) || "-";
        var totalSevereCorneringHighCount =
          ValueFormat.parseAsFloat(
            value.severeCorneringHighCount,
            2
          ) || "-";

        var totalCorneringCount =
          parseFloat(
            ValueFormat.parseAsFloat(
              value.severeCorneringLowCount,
              0
            )
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(
              value.severeCorneringMediumCount,
              0
            )
          ) +
          parseFloat(
            ValueFormat.parseAsFloat(
              value.severeCorneringHighCount,
              0
            )
          );

        var totalMobileUseInAcceptedCount =
          ValueFormat.parseAsFloat(
            value.mobileUseInAcceptedCount,
            0
          ) || "-";
        var totalMobileUseInAcceptedDistance =
          ValueFormat.parseAsFloat(
            value.mobileUseInAcceptedDistance,
            0
          ) || "-";
        const parsedNum = parseFloat(
          value.mobileUseInAcceptedDuration
        );
        var totalMobileUseInAcceptedDuration = Number.isInteger(parsedNum)
          ? parsedNum
          : ValueFormat.parseAsFloat(
              value.mobileUseInAcceptedDuration,
              2
            ) || "-";
        var totalMobileUseInAcceptedDurationTV =
          ValueFormat.parseAsFloat(
            value.mobileUseInAcceptedDuration,
            2
          ) || "-";

        var totalMobileScreenScreenOnCount =
          ValueFormat.parseAsFloat(
            value.mobileScreenScreenOnCount,
            0
          ) || "-";
        var totalMobileScreenScreenOnDistance =
          ValueFormat.parseAsFloat(
            value.mobileScreenScreenOnDistance,
            0
          ) || "-";
        const parsedNumMobileScreen = parseFloat(
          value.mobileScreenScreenOnDuration
        );
        var totalMobileScreenScreenOnDuration = Number.isInteger(
          parsedNumMobileScreen
        )
          ? parsedNumMobileScreen
          : ValueFormat.parseAsFloat(
              value.mobileScreenScreenOnDuration,
              2
            ) || "-";
        var totalMobileScreenScreenOnDurationTV =
          ValueFormat.parseAsFloat(
            value.mobileScreenScreenOnDuration,
            2
          ) || "-";

        var totalOverSpeedCount =
          ValueFormat.parseAsFloat(value.overSpeedCount, 0) || "-";
        const parsedNumOverSpeed = parseFloat(
          value.overSpeedDuration
        );
        var totalOverSpeedDuration = Number.isInteger(parsedNumOverSpeed)
          ? parsedNumOverSpeed
          : ValueFormat.parseAsFloat(value.overSpeedDuration, 2) ||
            "-";
        var totalOverSpeedDurationTV =
          ValueFormat.parseAsFloat(value.overSpeedDuration, 2) ||
          "-";
        var totalMediumRiskCount =
          ValueFormat.parseAsFloat(value.mediumRiskCount, 0) || "-";
        var totalHighRiskCount =
          ValueFormat.parseAsFloat(value.highRiskCount, 0) || "-";

        var tKiloMeter =
          ValueFormat.parseAsFloat(value.kiloMeter, 2) || "-";
        var tKiloMeterTV =
          ValueFormat.parseAsFloat(value.kiloMeter, 2) || "-";
        var tTravelTime =
          ValueFormat.parseAsFloat(value.travelTime, 2) || "-";
        var tTravelTimeTV =
          ValueFormat.parseAsFloat(value.travelTime, 2) || "-";

        var crashCount =
          ValueFormat.parseAsString(value.crashCount) || "-";
        var categoryObject = ValueFormat.parseAsObject(
          value.rideBox
        );
        var categoryTemp = ValueFormat.parseAsInt(categoryObject.rideBox);
        var categoryTooltip =
          categoryTemp == 1
            ? "Safe Driving"
            : categoryTemp == 2
            ? "Sportive Driving"
            : categoryTemp == 3
            ? "Quite Safe<br/>Driving" // Adding a line break before "Driving"
            : categoryTemp == 4
            ? "Reckless Driving"
            : categoryTemp == 5
            ? "Quite Dangerous<br/>Driving" // Adding a line break before "Driving"
            : "Dangerous Driving";
        var category =
          categoryTemp == 1
            ? "C1"
            : categoryTemp == 2
            ? "C2"
            : categoryTemp == 3
            ? "C3"
            : categoryTemp == 4
            ? "C4"
            : categoryTemp == 5
            ? "C5"
            : "C6";

        var status = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetterAndFormat(value.status)
        );
        var rideId = ValueFormat.parseAsString(value.deviceId);
        var statusTemp = "Completed";
        
        var divisionId = ValueFormat.parseAsString(value.divisionId);

        var rideCategory = ValueFormat.parseAsString(value.category);

        var startTime =
          ValueFormat.parseAsString(value.startDateTimeToTimeZone) ||
          "-";
        var endTime =
          ValueFormat.parseAsString(value.endDateTimeToTimeZone) ||
          "-";
        var batterylevelStart =
          ValueFormat.parseAsFloat(value?.batteryLevelStart, 0) ||
          "-";
        var batterylevelEnd =
          ValueFormat.parseAsFloat(value?.batteryLevelEnd, 0) || "-";
        var longEvent = ValueFormat.parseAsString(
          ValueFormat.capitalizeFirstLetterAndFormat(value.subType)
        );
        var event = ValueFormat.parseAsString(value.subType) || "";
        var shortEvent = "";
        if (event === "BACKGROUND_AUTO") {
          shortEvent = "BGA";
        } else if (event === "BACKGROUND_MANUAL") {
          shortEvent = "BGM";
        } else if (event === "FOREGROUND_AUTO") {
          shortEvent = "FGA";
        } else if (event === "FOREGROUND_MANUAL") {
          shortEvent = "FGM";
        } else {
          shortEvent = "-";
        }

        var totalCount = ValueFormat.parseAsInt(value.totalCount);
        // setTotalPageCount(Math.ceil(totalCount / pageSize));
        // setNextShow(totalCount - indexStartOfSet > pageSize);

        let totalBehaviourScore = (ValueFormat.formatDecimalIfRounded(dStyleValue) + ValueFormat.formatDecimalIfRounded(dStateValue) + parseFloat(mobileUsageTotal) + ValueFormat.formatDecimalIfRounded(kmsDriven) + ValueFormat.formatDecimalIfRounded(abcPoint));
        
        var summaryRide = "";

        if(calculatedDrivingScore >= 0 && calculatedDrivingScore <= 50){
          summaryRide = "-"
        }else if(calculatedDrivingScore > 50 && calculatedDrivingScore <= 80){
           summaryRide = "-"
        }else if(calculatedDrivingScore > 80 && calculatedDrivingScore <= 93){
           summaryRide = "-"
        }else if(calculatedDrivingScore > 93 && calculatedDrivingScore <= 100){
           summaryRide = "-"
        }else{
           summaryRide = "-"
        }
       
        // if (calculatedDrivingScore > 90) {         
        //  summaryRide = "You drive safely. You have excellent driving practice and you have a nature of very cautious"
        // } else if (calculatedDrivingScore > 60 && calculatedDrivingScore <= 90) {          
        //  summaryRide = "You are surprised from time to time by the singularities of the road poorly anticipated. Yet you have good driving practice and a normal self-confidence. Learn how to focus your attention on key places that correspond to alerts and you will lower your risk."
        // } else {         
        //  summaryRide = "Your driving is risky. You have good driving practice and a normal self-confidence. You have probably taken some bad habits to urgently fix to lower your risk."
        // }
        var dayPercentage = ValueFormat.parseAsFloat(value.dayPercentage, 0);
        var nightPercentage = 100 - dayPercentage;
        
        var urbanKilometer = ValueFormat.parseAsFloat(value.urbanKilometer, 2);
        var ruralKilometer = ValueFormat.parseAsFloat(value.ruralKilometer, 2);          
        var highwayKilometer = ValueFormat.parseAsFloat(value.highwayKilometer, 2);
        
        var summaryData = "";
        if (calculatedDrivingScore > 90) {          
          summaryData = "You drive safely. You have excellent driving practice and you have a nature of very cautious"
        } else if (calculatedDrivingScore > 60 && calculatedDrivingScore <= 90) {          
          summaryData = "You are surprised from time to time by the singularities of the road poorly anticipated. Yet you have good driving practice and a normal self-confidence. Learn how to focus your attention on key places that correspond to alerts and you will lower your risk."
        } else {         
          summaryData = "Your driving is risky. You have good driving practice and a normal self-confidence. You have probably taken some bad habits to urgently fix to lower your risk."
        }
       
        var resultObjectNewOne = {
          rideName: rideName,
          drivingCategory:drivingCategory,
            drivingBehaviourScore: totalBehaviourScore,
            calculatedDrivingScore: calculatedDrivingScore,
            totalEfficiencyTVNew: totalEfficiencyTVNew,
            category: category,
            categoryTooltip: categoryTooltip,
            drivingScore: overallDrivingScore,
            rideId: rideId,
            userId: userId,
            divisionId: divisionId,
            status: status,
            StatusTemp: statusTemp,
            ABCPoint: abcPoint,
            mobileScreenPointNew: mobileScreenValue,
            mobileUsePointNew: mobileCallValue,
            overallAnticipation: overallAnticipation,
            overallSelfConfidence: overallSelfConfidence,
            overallDrivingSkill: overallDrivingSkill,
            totalKmSPoint: kmsDriven,
            DSP: dStateValue,
            DSTP: dStyleValue,
            efficiency: efficiency,
            totalEfficiency: totalEfficiency,
            efficiencyTooltipValue: efficiencyTooltipValue,
            crashCount: crashCount,
            overallDrivingScoreTooltipValue: overallDrivingScoreTooltipValue,
            overallAnticipationTooltipValue: overallAnticipationTooltipValue,
            overallSelfConfidenceTooltipValue:
              overallSelfConfidenceTooltipValue,
            overallDrivingSkillTooltipValue: overallDrivingSkillTooltipValue,
            dStyleValueTooltipValue: dStyleValueTooltipValue,
            dStateValueTooltipValue: dStateValueTooltipValue,
            kmsDrivenTooltipValue: kmsDrivenTooltipValue,
            mobileCallValueTooltipValue: mobileCallValueTooltipValue,
            mobileScreenValueTooltipValue: mobileScreenValueTooltipValue,
            abcPointTooltipValue: abcPointTooltipValue,
            totalAccelerationCount: totalAccelerationCount,
            totalBrakingCount: totalBrakingCount,
            totalCorneringCount: totalCorneringCount,
            totalOverSpeedDuration: totalOverSpeedDuration,
            totalMobileUseInAcceptedCount: totalMobileUseInAcceptedCount,
            totalMobileUseInAcceptedDistance: totalMobileUseInAcceptedDistance,
            totalMobileUseInAcceptedDuration: totalMobileUseInAcceptedDuration,
            totalMobileScreenScreenOnCount: totalMobileScreenScreenOnCount,
            totalMobileScreenScreenOnDistance:
              totalMobileScreenScreenOnDistance,
            totalMobileScreenScreenOnDuration:
              totalMobileScreenScreenOnDuration,
            totalOverSpeedCount: totalOverSpeedCount,
            totalMediumRiskCount: totalMediumRiskCount,
            totalHighRiskCount: totalHighRiskCount,
            tKiloMeter: tKiloMeter,
            tTravelTime: tTravelTime,
            rideCategory: rideCategory,
            startTime: startTime,
            endTime: endTime,
            batterylevelStart: batterylevelStart,
            batterylevelEnd: batterylevelEnd,
            batterylevelTotalValue: (batterylevelEnd - batterylevelStart),
            event: shortEvent,
            longEvent: longEvent,
            totalOverSpeedDurationTV: totalOverSpeedDurationTV,
            totalMobileScreenScreenOnDurationTV:
              totalMobileScreenScreenOnDurationTV,
            totalMobileUseInAcceptedDurationTV:
              totalMobileUseInAcceptedDurationTV,
            tKiloMeterTV: tKiloMeterTV,
            tTravelTimeTV: tTravelTimeTV,
            totalEfficiencyTV: totalEfficiencyTV,
            cppLevel: cppFunction(cpp).value,
            cppLevelColor: cppFunction(cpp).colorCode,
            crashProbabilityScore: crashProbabilityScore,
            mobileUsage: ValueFormat.parseAsFloat(mobileUsage, 0),
            summaryRide:summaryRide,
            day: dayPercentage,
            night: nightPercentage,           
            urbanKilometers: urbanKilometer,
            ruralKilometers: ruralKilometer,
            highwayKilometers: highwayKilometer,
            summaryData:summaryData,
            riskSlot10count:riskSlot10count,
            riskSlot10Distance:riskSlot10Distance,
            riskSlot10Duration:riskSlot10Duration,
            cpp:cpp,
            riskHistogramDurationdata:riskHistogramDurationdata,
            redRiskDistance: redRiskDistanceData.toFixed(2),
        };

        // console.log(resultObjectNewOne,"resultObjectNewOne")
        updatedTableData.push(resultObjectNewOne);
      
        setApiData(updatedTableData);
        setDrivingStatus(ObjectResult);

        setDayNightPercentage({
          day: dayPercentage,
          night: nightPercentage,
          rural: ruralPercent,
          highway: highwayPercent,
          urban: urbanPercent,
          totalKilometer:kiloMeter,
          urbanKilometers: urbanKilometer,
          ruralKilometers: ruralKilometer,
          highwayKilometers: highwayKilometer,
        });

        setUserName((prev) =>
          loginType == "ADMIN" || loginType == "SUPER_ADMIN"
            ? ValueFormat.parseAsString(value.name)
            : prev + "- " + ValueFormat.parseAsString(value.name)
        );
        setTopWidgetDataPdf({
          rideName: rideName,
          driverBehaviorScore: totalEfficiency,
          drivingPoints: drivingScore,
          selfConfidencePoints: selfConfidence,
          drivingSkillPoints: drivingSkill,
          anticipationPoints: anticipation,
          driverCategory: driverCategory,
          ratings: ratings,
          travelTime: travelTime,
          startTime: startTime,
          endTime: endTime,
          riskAlertCount: alertDataCount,
          otherAlertCount: manualDataCount,
          redRiskHistogram: riskSlot10count,
          redRiskDuration: riskHistogramDurationdata,
          redRiskDistance: redRiskDistanceData.toFixed(2),
          cppValue: cpp,
          cppLevel: cppFunction(cpp).value,
          batteryLevelStart: batteryLevelStart,
          batteryLevelEnd: batteryLevelEnd,
          event: subType,
          rideSummary: summaryRide,
          distance: kiloMeter
        })
        setTopWidgetData(
          (topWidgetData || []).map((staticObject) => {
            var isMatched = false;
            var overallData = 0;
            var overallDataPostFix = "";
            var bottomValue = "";
            var tooltip = "";
            if (staticObject.type === "SCORE") {
              isMatched = true;
              overallData = totalEfficiency;
              tooltip = drivingScoreFullValue;
              overallDataPostFix = scoreSummary;
              bottomValue = ratings;
            } else if (staticObject.type === "RIDE") {
              isMatched = true;
              overallData = rideName;
              setRideNameTemp(overallData);
              overallDataPostFix = "";
              bottomValue = driverCategory;
            } else if (staticObject.type === "RANK") {
              isMatched = true;
              overallData = travelTime;
              overallDataPostFix = "";
              bottomValue = "Time: " + startTime + " to " + endTime;
            } else if (staticObject.type === "RISK") {
              isMatched = true;
              overallDataPostFix = "";
              overallData = alertDataCount;
              bottomValue = "Other Alerts: " + manualDataCount;
            } else if (staticObject.type === "OTHERALERTS") {
              isMatched = true;
              overallDataPostFix = "";
              overallData = riskHistogramDurationdata;
              bottomValue = "Red Risk Kms: " + redRiskDistanceData.toFixed(2);
            } else if (staticObject.type === "RISKHISTOGRAM") {
              isMatched = true;
              overallDataPostFix = "";
              overallData = riskSlot10count;
              bottomValue = "CPP Level: " + cppFunction(cpp).value;
            }

            if (isMatched === true) {
              var updatedObject = {
                overallData: overallData,
                overallDataPostFix: overallDataPostFix,
                bottomValue: bottomValue,
                tooltip: tooltip,
                startTime: startTime,
                endTime: endTime,
              };
              return { ...staticObject, ...updatedObject };
            } else {
              return staticObject;
            }
          })
        );
      });
    } else {
      console.log("Status failed");
      // const code = resultJSON.error.code;
      // if (code == "E1206") {
      //   ToastService.errormsg(displayText.ER_1206);
      //   setTimeout(() => {
      //     window.location.href = "/contacts-profile";
      //   }, 3500);
      // }
    }
  };

  let getAlertSummary = async () => {
    setShowLoader((current) => current + 1);
    var alertsType = "";
    var result = await AnalyticalController.alertSummaryWithRideCategory(
      locationParams.rideId,
      locationParams.divisionId,
      locationParams.userId,
      rideCategory // ridecategory added
    );
    setShowLoader((current) => current - 1);
    var alertValidate = 0;
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      var alertSummeryXAxis = [];
      var alertSummeryData = [];
      var alertValueHighRisk = [];
      var highRiskValueData = [];
      var latLong = [];
      resultJSONData.map((value) => {
        var latLongTemp = [];
        alertValueHighRisk = [];
        alertsType =
          value.subCategory != "" && value.subCategory != null
            ? ValueFormat.capitalizeFirstLetterAndFormat(value.subCategory)
            : "";
        var deviceDataField = value.deviceDataField;
        var risk = deviceDataField.risk;
        alertSummeryXAxis.push(alertsType);
        alertSummeryData.push(risk);
        if (risk > 90) {
          alertValidate = alertValidate + 1;
          var highRiskValue = risk;
          var latitude = deviceDataField.latitude;
          var longitude = deviceDataField.longitude;
          var createdTime = value.createdAtToTimeZone;
          // console.log("createdTime redbubble : " + createdTime)
          var highRiskType = alertsType;
          latLongTemp.push(latitude, longitude, 0.5);

          latLong.push(latLongTemp);
          alertValueHighRisk.push(
            highRiskType,
            new Date(createdTime),
            highRiskValue
          );
          highRiskValueData.push(alertValueHighRisk);
        }
      });

      var alertSummaryResult = {
        xaxis: alertSummeryXAxis,
        data: alertSummeryData,
      };
      setSummaryData(
        Object.values(alertSummeryData).some((value) => value !== 0)
      );
      setAlertValiDate(alertValidate > 0 ? true : false);
      setAlertSummaryXAxis(alertSummaryResult);

      setRedBubblePlot(highRiskValueData);
      var mapContainer = document.getElementById("map");

      var mapLeaflet = L.map(mapContainer).setView([20.0, 77.0], 4);

      L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mapLeaflet);

      const points = latLong
        ? latLong.map((p) => {
          return [p[0], p[1]];
        })
        : [];

      L.heatLayer(points, {
        minOpacity: 0.6,
        maxZoom: 20,
        gradient: {
          0.4: "#0077be",
          0.6: "#00b3f0",
          0.7: "#00ff00",
          0.8: "#ffff00",
          1.0: "#ff0000",
        },
        blur: 15,
      }).addTo(mapLeaflet);

      setHeatmapData(latLong);
    } else {
      var code = resultJSON.error.code;
      if (code == "E1206") {
        setSummaryData("failed");
        setAlertValiDate("failed");
      }
    }
  };

  const getSummaryData = async () => {
    setShowLoader((current) => current + 1);
    try {
      var riskSlot1count = "0",
        riskSlot2count = "0",
        riskSlot3count = "0",
        riskSlot4count = "0",
        riskSlot5count = "0";
      var riskSlot6count = "0",
        riskSlot7count = "0",
        riskSlot8count = "0",
        riskSlot9count = "0",
        riskSlot10count = "0";
      var rdResult = await AnalyticalController.getRiskDistributionSummary(
        locationParams.divisionId,
        locationParams.userId,
        locationParams.rideId,
        rideCategory // ridecategory added
      );
      setShowLoader((current) => current - 1);
      var resultJSON = JSON.parse(rdResult);
      var status = resultJSON.status;
      if (status == "SUCCESS") {
        var resultJSONData = resultJSON.data;
        // console.log("riskCountSlot: " + JSON.stringify(resultJSONData));
        for (let rdc = 0; rdc < resultJSONData.length; rdc++) {
          var resultDetailTemp = resultJSONData[rdc];
          if (resultDetailTemp.riskAlertDetailCount != null) {
            var riskObjet = resultDetailTemp.riskAlertDetailCount;
            riskSlot1count =
              riskObjet.riskSlot1count != null
                ? riskObjet.riskSlot1count
                : riskSlot1count;
            riskSlot2count =
              riskObjet.riskSlot2count != null
                ? riskObjet.riskSlot2count
                : riskSlot2count;
            riskSlot3count =
              riskObjet.riskSlot3count != null
                ? riskObjet.riskSlot3count
                : riskSlot3count;
            riskSlot4count =
              riskObjet.riskSlot4count != null
                ? riskObjet.riskSlot4count
                : riskSlot4count;
            riskSlot5count =
              riskObjet.riskSlot5count != null
                ? riskObjet.riskSlot5count
                : riskSlot5count;
            riskSlot6count =
              riskObjet.riskSlot6count != null
                ? riskObjet.riskSlot6count
                : riskSlot6count;
            riskSlot7count =
              riskObjet.riskSlot7count != null
                ? riskObjet.riskSlot7count
                : riskSlot7count;
            riskSlot8count =
              riskObjet.riskSlot8count != null
                ? riskObjet.riskSlot8count
                : riskSlot8count;
            riskSlot9count =
              riskObjet.riskSlot9count != null
                ? riskObjet.riskSlot9count
                : riskSlot9count;
            riskSlot10count =
              riskObjet.riskSlot10count != null
                ? riskObjet.riskSlot10count
                : riskSlot10count;
          }
        }
      } else {
        var code = resultJSON.error.code;
        if (code == "E1206") {
          setHistogramData("failed");
        }
      }
    } catch (err) {
      // console.log(err);
    }
    var dataList = [];

    // Define the data directly inside the component
    const data = {
      "90 - 100": riskSlot10count,
      "80 - 90": riskSlot9count,
      "70 - 80": riskSlot8count,
      "60 - 70": riskSlot7count,
      "50 - 60": riskSlot6count,
      "40 - 50": riskSlot5count,
      "30 - 40": riskSlot4count,
      "20 - 30": riskSlot3count,
      "10 - 20": riskSlot2count,
      "0 - 10": riskSlot1count,
    };

    const data2 = {
      "90 - 100": riskSlot10count,
      "80 - 90": riskSlot9count,
      "70 - 80": riskSlot8count,
      "60 - 70": riskSlot7count,
      "50 - 60": riskSlot6count,
      "40 - 50": riskSlot5count,
      "30 - 40": riskSlot4count,
      "20 - 30": riskSlot3count,
      "10 - 20": riskSlot2count,
    };

    // console.log("data2", data2);

    if (
      parseFloat(riskSlot2count) === 0 &&
      parseFloat(riskSlot3count) === 0 &&
      parseFloat(riskSlot4count) === 0 &&
      parseFloat(riskSlot5count) === 0 &&
      parseFloat(riskSlot6count) === 0 &&
      parseFloat(riskSlot7count) === 0 &&
      parseFloat(riskSlot8count) === 0 &&
      parseFloat(riskSlot9count) === 0 &&
      parseFloat(riskSlot10count) === 0
    ) {
      data2["0 - 10"] = riskSlot1count;
    }

    // Calculate the total count combining all slots
    const totalCount = Object.values(data).reduce(
      (sum, count) => sum + count,
      0
    );

    const slotLabels = Object.entries(data2).map(
      ([range, count]) => `${range} (${count})`
    );
    const slotLabels1 = Object.entries(data2).map(
      ([time, count]) => `${"Time"} : ${(count / 20).toFixed(2)} seconds`
    );

    // Calculate the percentage for each slot count
    const slotNames = Object.keys(data2);
    const slotPercentages = slotNames.map((slot) => {
      if (totalCount === 0) {
        return 0; // or any other default value you prefer
      } else {
        return ((data2[slot] / totalCount) * 100).toFixed(2);
      }
    });
    slotPercentages.map((value, i) => {
      var custom = slotLabels1[i];
      var result = { value: value, customData: custom };
      dataList.push(result);
    });
    var result = { value: slotPercentages, customData: slotLabels1 };
    const resultDistributionData = {
      xaxis: slotLabels,
      data: dataList,
      customData: slotLabels1,
    };

    setDistributionData(resultDistributionData);
    setHistogramData(
      Object.values(slotPercentages).some(
        (value) => !isNaN(value) && value !== 0
      )
    );
  };

  const calculateSumOfDifferences = (durationArray) => {
    let sum = 0;
    for (let i = 1; i < durationArray.length; i++) {
      sum += durationArray[i] - durationArray[i - 1];
    }
    return sum;
  };

  const [stateData, setstateData] = useState([]);
  const [styleData, setstyleData] = useState([]);
  // console.log("stateData",stateData);
  // console.log("styleData",styleData);

  const getRiskRideDetail = async () => {
    setShowLoader((current) => current + 1);

    setDetectionData(null);
    setStyleEstimationData(null);

    var result = await MyRideDetailController.getDashboardRiskRideDataList(
      locationParams.rideId,
      locationParams.divisionId,
      locationParams.userId,
      rideCategory // ridecategory added
    );
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONDataParse = resultJSON.data;


      if (resultJSONDataParse.length > 0) {
        setDetectionData(null);
        setStyleEstimationData(null);

        //Parsing the travel time alone to ascending order
        var resultJSONData = resultJSONDataParse;
        // console.log(
        // "TripDeviceDataList Risk symbol component : " +
        //   JSON.stringify(resultJSONData)
        // );
        let ff = resultJSONDataParse
          .map((val, i) => parseFloat(val.deviceDataField.travelTime) || 0)
          .sort((a, b) => a - b);
        resultJSONData = resultJSONData.map((val, i) => {
          return {
            ...val,
            deviceDataField: { ...val.deviceDataField, travelTime: ff[i] + "" },
          };
        });

        var riskStyleDataTemp = [];
        var drivingStyleData = [];
        var driverStateData = [];
        var kmValue = [];
        var lastKmValue = 0;
        var riskStyleAnticipation = 0;
        var riskStyleExport = 0;
        var riskStyleRisky = 0;
        var riskStyleUnknown = 0;
        var driverNormal = 0;
        var driverTried = 0;
        var driverAngry = 0;
        var driverUnknown = 0;
        var totalStateCount = 0;

        var distanceStateUnknown = 0;
        var distanceStateNormal = 0;
        var distanceStateTired = 0;
        var distanceStateAngry = 0;

        var durationStateUnknown = 0;
        var durationStateNormal = 0;
        var durationStateTired = 0;
        var durationStateAngry = 0;

        var distanceStyleSportive = 0;
        var distanceStyleAggressive = 0;
        var distanceStyleChaotic = 0;
        var distanceStyleNormal = 0;
        var distanceStyleEco = 0;
        var distanceStyleUnknown = 0;

        var durationStyleSportive = 0;
        var durationStyleAggressive = 0;
        var durationStyleChaotic = 0;
        var durationStyleNormal = 0;
        var durationStyleEco = 0;
        var durationStyleUnknown = 0;

        var totalStyleCount = 0;

        var drivingSportive = 0;
        var drivingAggressive = 0;
        var drivingChaotic = 0;
        var drivingNormal = 0;
        var drivingEco = 0;
        var drivingUnknown = 0;

        let stateData = [];
        let styleData = [];
        let previousTravelTime = null;
        for (let gs = 0; gs < resultJSONData.length; gs++) {
          var dDataField = resultJSONData[gs].deviceDataField;
          var category = resultJSONData[gs].category;
          var dDataField1 = resultJSONData[gs].deviceDataField1;
          var driverState =
            dDataField1.driverState != null
              ? parseFloat(dDataField1.driverState).toFixed(0)
              : 0;
          var riskStyle =
            dDataField1.riskStyle != null
              ? parseFloat(dDataField1.riskStyle).toFixed(0)
              : 0;

          var driverStyle =
            dDataField1.drivingStyle != null
              ? parseFloat(dDataField1.drivingStyle).toFixed(0)
              : 0;

          var kmValueTemp =
            dDataField.kiloMeter >= 0
              ? parseFloat(dDataField.kiloMeter).toFixed(1)
              : 0;

          // var currentTravelTime = ValueFormat.parseAsString(dDataField.travelTime);
          // var travelTime = parseFloat(currentTravelTime).toFixed(2);
          const travelTimeValue = parseFloat(dDataField.travelTime);
          const travelTime =
            travelTimeValue !== undefined || travelTimeValue !== null
              ? travelTimeValue.toFixed(2)
              : 0;

          var travelTimeDifference =
            previousTravelTime !== null
              ? travelTime - previousTravelTime
              : travelTime - 0;

          previousTravelTime = travelTime;

          stateData.push({
            driverState: driverState,
            travelTime: travelTime,
            travelTimeDifference: travelTimeDifference,
          });

          styleData.push({
            driverStyle: driverStyle,
            travelTime: travelTime,
            travelTimeDifference: travelTimeDifference,
          });

          if(category == "DISTANCE_DATA"){
          switch (driverState) {
            case "0":
              durationStateUnknown += travelTimeDifference;
              break;
            case "1":
              durationStateNormal += travelTimeDifference;
              break;
            case "2":
              durationStateTired += travelTimeDifference;
              break;
            case "3":
              durationStateAngry += travelTimeDifference;
              break;
            default:
              break;
          }
        }
        if(category == "DISTANCE_DATA"){
          switch (driverStyle) {
            case "0":
              durationStyleUnknown += travelTimeDifference;
              break;
            case "1":
              durationStyleNormal += travelTimeDifference;
              break;
            case "2":
              durationStyleSportive += travelTimeDifference;
              break;
            case "3":
              durationStyleEco += travelTimeDifference;
              break;
            case "4":
              durationStyleChaotic += travelTimeDifference;
              break;
            case "5":
              durationStyleAggressive += travelTimeDifference;
              break;
            default:
              break;
          }
        }

          riskStyle == 1
            ? (riskStyleAnticipation = riskStyleAnticipation + 1)
            : riskStyle == 2
              ? (riskStyleExport = riskStyleExport + 1)
              : riskStyle == 3
                ? (riskStyleRisky = riskStyleRisky + 1)
                : (riskStyleUnknown = riskStyleUnknown + 1);

          if (category == "DISTANCE_DATA") {
            driverState == 1
              ? (driverNormal = driverNormal + 1)
              : driverState == 2
                ? (driverTried = driverTried + 1)
                : driverState == 3
                  ? (driverAngry = driverAngry + 1)
                  : (driverUnknown = driverUnknown + 1);
          }

          if (category == "DISTANCE_DATA") {
            driverStyle == 1
              ? (drivingNormal = drivingNormal + 1)
              : driverStyle == 2
                ? (drivingSportive = drivingSportive + 1)
                : driverStyle == 3
                  ? (drivingEco = drivingEco + 1)
                  : driverStyle == 4
                    ? (drivingChaotic = drivingChaotic + 1)
                    : driverStyle == 5
                      ? (drivingAggressive = drivingAggressive + 1)
                      : (drivingUnknown = drivingUnknown + 1);
          }

          riskStyleDataTemp.push(riskStyle);
          drivingStyleData.push(driverStyle);
          driverStateData.push(driverState);
          kmValue.push(kmValueTemp);
          lastKmValue = Math.max(...kmValue);
          // lastKmValue = drivingStatus.totalKilometer;

          var resultObject = {
            xaxis: kmValue,
            riskStyleData: riskStyleDataTemp,
            driverStateData: driverStateData,
            driverStyleData: drivingStyleData,
            driverStyleData: drivingStyleData,
          };

          var riskStyleObject = {
            riskStyleAnticipation: riskStyleAnticipation,
            riskStyleExpert: riskStyleExport,
            riskStyleRisky: riskStyleRisky,
            riskStyleUnknown: riskStyleUnknown,
          };
          totalStateCount =
            driverNormal + driverTried + driverAngry + driverUnknown;

          distanceStateUnknown = (
            (driverUnknown / totalStateCount) *
            lastKmValue
          ).toFixed(2);
          distanceStateNormal = (
            (driverNormal / totalStateCount) *
            lastKmValue
          ).toFixed(2);
          distanceStateTired = (
            (driverTried / totalStateCount) *
            lastKmValue
          ).toFixed(2);
          distanceStateAngry = (
            (driverAngry / totalStateCount) *
            lastKmValue
          ).toFixed(2);

          var driverStateObject = {
            driverNormal: driverNormal,
            driverTried: driverTried,
            driverAngry: driverAngry,
            driverUnknown: driverUnknown,

            distanceStateUnknown: distanceStateUnknown,
            distanceStateNormal: distanceStateNormal,
            distanceStateTired: distanceStateTired,
            distanceStateAngry: distanceStateAngry,

            durationStateUnknown: durationStateUnknown,
            durationStateNormal: durationStateNormal,
            durationStateTired: durationStateTired,
            durationStateAngry: durationStateAngry,

            lastKmValue: lastKmValue,
            totalStateCount: totalStateCount,
          };

          var driverStateObjectLength =
            driverNormal + driverTried + driverAngry + driverUnknown;
          setDriverStateDetection(driverStateObjectLength > 0 ? true : false);

          totalStyleCount =
            drivingSportive +
            drivingAggressive +
            drivingChaotic +
            drivingNormal +
            drivingEco +
            drivingUnknown;

          distanceStyleSportive = (
            (drivingSportive / totalStyleCount) *
            lastKmValue
          ).toFixed(2);
          distanceStyleAggressive = (
            (drivingAggressive / totalStyleCount) *
            lastKmValue
          ).toFixed(2);
          distanceStyleChaotic = (
            (drivingChaotic / totalStyleCount) *
            lastKmValue
          ).toFixed(2);
          distanceStyleNormal = (
            (drivingNormal / totalStyleCount) *
            lastKmValue
          ).toFixed(2);
          distanceStyleEco = (
            (drivingEco / totalStyleCount) *
            lastKmValue
          ).toFixed(2);
          distanceStyleUnknown = (
            (drivingUnknown / totalStyleCount) *
            lastKmValue
          ).toFixed(2);

          var driverStyleObject = {
            drivingSportive: drivingSportive,
            drivingAggressive: drivingAggressive,
            drivingChaotic: drivingChaotic,
            drivingNormal: drivingNormal,
            drivingEco: drivingEco,
            drivingUnknown: drivingUnknown,

            distanceStyleSportive: distanceStyleSportive,
            distanceStyleAggressive: distanceStyleAggressive,
            distanceStyleChaotic: distanceStyleChaotic,
            distanceStyleNormal: distanceStyleNormal,
            distanceStyleEco: distanceStyleEco,
            distanceStyleUnknown: distanceStyleUnknown,

            durationStyleSportive: durationStyleSportive,
            durationStyleAggressive: durationStyleAggressive,
            durationStyleChaotic: durationStyleChaotic,
            durationStyleNormal: durationStyleNormal,
            durationStyleEco: durationStyleEco,
            durationStyleUnknown: durationStyleUnknown,

            lastKmValue: lastKmValue,
            totalStyleCount: totalStyleCount,
          };
          var driverStyleObjectLength =
            drivingSportive +
            drivingAggressive +
            drivingChaotic +
            drivingNormal +
            drivingEco +
            drivingUnknown;
          setDrivingStyleDataCount(driverStyleObjectLength > 0 ? true : false);
          const hasNonZeroValue = Object.values(drivingStyleData).some(
            (value) => value !== 0
          );
          setStyleEstimationData(hasNonZeroValue);

          setDriverData(resultObject);

          setRiskStyleList((preData) => {
            return { ...preData, ...riskStyleObject };
          });

          setDriverStateList((staticData) => {
            return { ...staticData, ...driverStateObject };
          });

          setDriverStyleList((staticData) => {
            return { ...staticData, ...driverStyleObject };
          });
          setDetectionData(true);
          setStyleEstimationData(true);
          setstyleData(styleData);
          setstateData(stateData);
        }
      } else {
        setDriverStateDetection(false);
        setDrivingStyleDataCount(false);

        setDetectionData(false);
        setStyleEstimationData(false);
      }
    } else {
      const code = resultJSON.error.code;
      if (code == "E1206") {
        setDriverStateDetection("failed");
        setDetectionData("failed");
        setDrivingStyleDataCount("failed");
      }
    }
  };
  let capture = (resultContainer, map, ui) => {
    // Capturing area of the map is asynchronous, callback function receives HTML5 canvas
    // element with desired map area rendered on it.
    // We also pass an H.ui.UI reference in order to see the ScaleBar in the output.
    // If dimensions are omitted, whole veiw port will be captured
    document.getElementById("mapContainerDisplay").style.display = "none";
    map.capture(
      function (canvas) {
        if (canvas) {
          resultContainer.innerHTML = "";
          resultContainer.appendChild(canvas);
        } else {
          // For example when map is in Panorama mode
          resultContainer.innerHTML = "Capturing is not supported";
        }
      },
      [ui]
    );
  };
  const containerIds = ["container1"];
  const getRiskAlertsDataList = async () => {
    setShowLoader((current) => current + 1);
    setRiskAlertsDataDisplay(null);
    setMapDisplay(null);
    var result = await MyRideDetailController.getTripProfileRiskAlertDataListWithRideCateory(
      locationParams.rideId,
      locationParams.divisionId,
      locationParams.userId,
      rideCategory // ridecategory added
    );
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    // console.log("mapDetails :", resultJSON);
    var status = resultJSON.status;
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      setRiskAlertsDataDisplay(resultJSONData.length > 0 ? true : false);
      setMapDisplay(resultJSONData.length > 0 ? true : false);
      // console.log(resultJSONData, "fhjdfhj");
      var alertDataCount = 0;
      var tripIcon = "";
      var alertImage = "";
      // console.log("getRiskAlertsDataList:" + JSON.stringify(resultJSONData));

      var tripRiskList = [];
      var tripRiskLatList = [];
      var tripRiskLonList = [];
      var tripRiskSigList = [];
      var tripRiskLatLongList = [];
      var mapTootipDetails = [];
      var tripStartLatLong = "";
      var tripEndLatLong = "";
      var tripLatLngObjectList = [];

      var centerLatLong = { lat: 11.004556, lng: 76.961632 };
      var startLng = 0;
      var endLng = 0;
      var startLat = 0;
      var endLat = 0;

      if (resultJSONData.length > 0) {
        const lastEntry = resultJSONData[resultJSONData.length - 1];
        const lastDataa = resultJSONData[0].lastDeviceData;
        if (lastEntry.category !== "END_DATA") {
          const endDataEntry = {
            ...lastEntry,
            category: "END_DATA",
            subCategory: "",
            createdAtToTimeZone: lastDataa.createdAtToTimeZone,
            latitude: ValueFormat.parseAsString(lastDataa.latitude),
            longitude: ValueFormat.parseAsString(lastDataa.longitude),
            deviceDataField: {
              ...lastEntry.deviceDataField,
              kiloMeter: lastDataa.kiloMeter,
              speed: lastDataa.speed,
              latitude: ValueFormat.parseAsString(lastDataa.latitude),
              longitude: ValueFormat.parseAsString(lastDataa.longitude),
            },
            zipCode: lastDataa.zipCode,
            pointOfInterest: lastDataa.pointOfInterest,
          };
          resultJSONData.push(endDataEntry);
        }
      }

      // In case the first data's category is not START_DATA but second data's category is "START_DATA" means swap the data
      // if (
      //   resultJSONData.length > 1 &&
      //   resultJSONData[0].category !== "START_DATA" &&
      //   resultJSONData[1].category === "START_DATA"
      // ) {
      //   const temp = resultJSONData[0];
      //   resultJSONData[0] = resultJSONData[1];
      //   resultJSONData[1] = temp;
      // }

      if (resultJSONData.length > 0) {
        for (let rdCount = 0; rdCount < resultJSONData.length; rdCount++) {
          tripIcon = "";
          try {
            var createdAtToTimeZone =
              resultJSONData[rdCount].createdAtToTimeZone;
            var category = resultJSONData[rdCount].category;
            var subCategory = resultJSONData[rdCount].subCategory;
            var deviceDataField = resultJSONData[rdCount].deviceDataField;
            var dDataField = ValueFormat.parseAsObject(deviceDataField);
            var riskValueTemp = ValueFormat.parseAsInt(dDataField.risk);
            var rideTimeTemp = ValueFormat.parseAsFloat(dDataField.rideTime, 0);
            var alertKMTemp = ValueFormat.parseAsFloat(dDataField.kiloMeter, 2);
            var speedTemp = ValueFormat.parseAsFloat(dDataField.speed, 0);
            var zipCodeTemp = ValueFormat.parseAsFloat(dDataField.zipCode, 0);
            var alertValTemp = ValueFormat.parseAsFloat(dDataField.alert, 0);
            var previousSpeedTemp = ValueFormat.parseAsFloat(
              dDataField.previousSpeed,
              0
            );
            var pointOfInterest = ValueFormat.parseAsFloat(
              resultJSONData[rdCount].pointOfInterest,
              2
            );

            var setspeedValue = "-";
            if (category == "ALERT_DATA") {
              //||category == "STRESS_STRAIN_DATA"
              alertDataCount = alertDataCount + 1;
              if (
                subCategory == "SEVERE_BRAKING" ||
                subCategory == "SEVERE_ACCELERATION"
              ) {
                setspeedValue = previousSpeedTemp;
              } else if (
                subCategory == "PEDESTRIAN_CROSSING" ||
                subCategory == "SCHOOL_ZONE"
              ) {
                setspeedValue = 25;
                if (speedTemp >= 25) {
                  speedTemp = speedTemp;
                } else {
                  // speedTemp = "-";
                  speedTemp = speedTemp;
                }
              } else if (subCategory == "OVER_SPEED") {
                setspeedValue = alertValTemp > 0 ? alertValTemp : "-";
              }
            }
            rideTimeTemp = UtilDateTime.formatTimeAsHHMMSS(createdAtToTimeZone);
            var latitudeTempMap =
              dDataField.latitude.length > 0
                ? parseFloat(dDataField.latitude).toFixed(5)
                : "";

            var longitudeTempMap =
              dDataField.longitude.length > 0
                ? parseFloat(dDataField.longitude).toFixed(5)
                : "";

            var latitudeTemp =
              // dDataField.latitude.length > 0
              //   ?
              parseFloat(dDataField.latitude).toFixed(5);
            // : "-";
            var longitudeTemp =
              // dDataField.longitude.length > 0
              //   ?
              parseFloat(dDataField.longitude).toFixed(5);
            // : "-";

            var latLongTempMap = latitudeTemp + "," + longitudeTemp;

            tripRiskLatList.push(latitudeTempMap);
            tripRiskLonList.push(longitudeTempMap);
            const latLng = { lat: latitudeTemp, lng: longitudeTemp };
            var alertType = "";
            if (category != "STRESS_STRAIN_DATA") {
              if (subCategory.length > 0) {
                alertType = subCategory;
              } else {
                alertType = category;
              }
              var tooltipDetails = {
                alertType: alertType,
                speed: speedTemp,
                time: rideTimeTemp,
              };
              tripRiskSigList.push(tooltipDetails);
            }

            if (rdCount == 0) {
              startLat = latitudeTemp;
              startLng = longitudeTemp;
              tripStartLatLong = latLongTempMap;
            } else if (rdCount == resultJSONData.length - 1) {
              endLat = latitudeTemp;
              endLng = longitudeTemp;
              tripEndLatLong = latLongTempMap;
            } else if (category != "STRESS_STRAIN_DATA") {
              tripRiskLatLongList.push(latLongTempMap);
              tripLatLngObjectList.push(latLng);
            }

            if (subCategory == alertDataType.ANIMAL_CROSSING) {
              alertImage = "t_animal_crossing.png";
            } else if (subCategory == alertDataType.CAUTION) {
              alertImage = "t_caution.png";
            } else if (subCategory == alertDataType.CURVE) {
              alertImage = "t_curves.png";
            } else if (subCategory == alertDataType.SEVERE_ACCELERATION) {
              alertImage = "t_force_acceleration.png";
            } else if (subCategory == alertDataType.HILL_DOWNWARDS) {
              alertImage = "t_hill_downwards.png";
            } else if (subCategory == alertDataType.HILL_UPWARDS) {
              alertImage = "t_hill_upwards.png";
            } else if (subCategory == alertDataType.INTERSECTION) {
              alertImage = "t_intersection.png";
            } else if (subCategory == alertDataType.LANE_MERGE) {
              alertImage = "t_lane_merge.png";
            } else if (subCategory == alertDataType.LOW_GEAR_AREA) {
              alertImage = "t_low_gear_area.png";
            } else if (subCategory == alertDataType.NARROW_ROAD) {
              alertImage = "t_narrow_road.png";
            } else if (subCategory == alertDataType.MOBILE_USE) {
              alertImage = "t_mob_use.png";
            } else if (subCategory == alertDataType.NO_OVERTAKING) {
              alertImage = "t_no_overtaking.png";
            } else if (subCategory == alertDataType.OVER_SPEED) {
              alertImage = "t_over_speed.png";
            } else if (subCategory == alertDataType.P_CROSSING) {
              alertImage = "t_pedestrian_crossing.png";
            } else if (subCategory == alertDataType.R_CROSSING) {
              alertImage = "t_railway_crossing.png";
            } else if (subCategory == alertDataType.SCHOOL_ZONE) {
              alertImage = "t_school_zone.png";
            } else if (subCategory == alertDataType.STOP_SIGN) {
              alertImage = "t_stop.png";
            } else if (subCategory == alertDataType.SEVERE_BRAKING) {
              alertImage = "t_sudden_break.png";
            } else if (subCategory == alertDataType.TRAFFIC_LIGHT) {
              alertImage = "t_traffic_light.png";
            } else if (subCategory == alertDataType.WINDING_ROAD) {
              alertImage = "t_winding_road.png";
            } else if (subCategory == "YIELD") {
              alertImage = "t_yield.png";
            } else if (subCategory == alertDataType.PRIORITY) {
              alertImage = "t_priority.png";
            } else if (subCategory == alertDataType.ICY_CONDITIONS) {
              alertImage = "t_icy_conditions.png";
            } else if (subCategory == alertDataType.WIND) {
              alertImage = "t_wind.png";
            } else if (subCategory == alertDataType.HILL) {
              alertImage = "t_hill.png";
            } else if (subCategory == alertDataType.SLIPPERY_ROADS) {
              alertImage = "t_slippery_road.png";
            } else if (subCategory == alertDataType.RISK_OF_GROUNDING) {
              alertImage = "t_risk_of_grouping.png";
            } else if (subCategory == alertDataType.SEVERE_CORNERING) {
              alertImage = "sCornering.svg";
            } else if (subCategory == alertDataType.ROUND_ABOUT) {
              alertImage = "Round-about.svg";
            } else if (subCategory == alertDataType.NO_OVERTAKING_TRUCKS) {
              alertImage = "No-overtaking-trucks.svg";
            } else {
              alertImage = "not-avl.png";
            }
            if (category == alertDataType.START_DATA) {
              alertImage = "start.png";
              tripIcon = "alert_start.png";
            } else if (category == alertDataType.END_DATA) {
              alertImage = "finish.png";
              tripIcon = "alert_end.png";
            } else {
              if (riskValueTemp <= 90 && riskValueTemp > 70) {
                tripIcon = "alert_risk_amber.png";
              } else if (riskValueTemp <= 70) {
                tripIcon = "alert_start.png";
              } else {
                tripIcon = "alert_risk_red.png";
              }
            }

            if (tripEndLatLong == "" && resultJSONData.length == 1) {
              tripEndLatLong = tripStartLatLong;
            }

            var deviceDataObject = {
              alertImage: alertImage,
              tripIcon: tripIcon,
              category: category,
              subCategory: subCategory,
              setspeedValue: setspeedValue,
              timeVal: rideTimeTemp,
              distanceVal: alertKMTemp,
              speedVal: speedTemp,
              riskValue: riskValueTemp,
              pointOfInterest: pointOfInterest,
              zipcodeVal: zipCodeTemp,
            };
            if (
              (subCategory == "" &&
                (category == "START_DATA" || category == "END_DATA")) ||
              (subCategory != "" && category == "ALERT_DATA")
            ) {
              if (
                category != "STRESS_STRAIN_DATA" ||
                category != "MANUAL_DATA"
              ) {
                // if (speedTemp != "-") {
                tripRiskList.push(deviceDataObject);
                // }
              }
            }
          } catch (errMess) {
            // console.log("rdCount  3: ", rdCount + " errMess: " + errMess);
          }
        }
        setRiskDataTable(tripRiskList);
        // addEndDataIfMissing(tripRiskList, resultJSONData[0].lastDeviceData);
        // console.log(tripRiskList, "listtt")
        const isDataAvailable =
          tripRiskList && tripRiskList.length > 0
            ? true
            : tripRiskList && tripRiskList.length <= 0
              ? false
              : null;
        setRiskAlertsDataDisplay(isDataAvailable);
        setAlertCount(alertDataCount);
        setMapData((prev) => {
          return {
            ...prev,
            tripLatLngArray: tripRiskLatLongList,
            tripLatLongList: tripLatLngObjectList,
            tripStartLatLong: tripStartLatLong,
            tripEndLatLong: tripEndLatLong,
            subCategory: tripRiskSigList,
          };
        });

        if (startLng != 0 && startLat != 0 && endLng != 0 && endLat != 0) {
          centerLatLong = {
            // lat: (parseFloat(startLat) + parseFloat(endLat)) / 2,
            // lng: (parseFloat(startLng) + parseFloat(endLng)) / 2,
            lat: parseFloat(startLat),
            lng: parseFloat(startLng),
          };
        } else if (startLng != 0 && startLat != 0) {
          centerLatLong = {
            lat: parseFloat(startLat),
            lng: parseFloat(startLng),
          };
        }
        // console.log(centerLatLong, "centerLatLong");
        const platform = new H.service.Platform({
          apikey: "pAuj6jqBlpXZUMzd30mtgujRlfWV2jA6qZJPF3JP5is",
        });
        const defaultLayers = platform.createDefaultLayers();

        var map = new H.Map(
          document.getElementById("mapContainerDisplay"),
          defaultLayers.vector.normal.map.setMin(3),
          {
            center: centerLatLong,
            zoom: 11,
            pixelRatio: window.devicePixelRatio || 2,
            pview: "ind",
            style: "fleet",
          }
        );
        map.getViewModel().setLookAtData({ tilt: 40, heading: 180 });

        var ui = H.ui.UI.createDefault(map, defaultLayers);

        var routingParameters = {
          routingMode: "fast",
          transportMode: "car",
          origin: tripStartLatLong,
          destination: tripEndLatLong,
          zoomControl: true,
          via: new H.service.Url.MultiValueQueryParameter(tripRiskLatLongList),
          return: "polyline",
        };
        // Create container for the "Capture" button
        // Step 6: Create "Capture" button and place for showing the captured area
        var resultContainer = document.getElementById("panel");

        // Create the "Capture" button

        setPanelId(resultContainer);
        setMapContainer(map);
        setMapUi(ui);

        var onResult = function (result) {
          // ensure that at least one route was found

          if (result.routes.length) {
            let sectionTemp = 0;
            let noOfSection = result.routes[0].sections.length;

            result.routes[0].sections.forEach((section) => {
              // Create a linestring to use as a point source for the route line
              let linestring = H.geo.LineString.fromFlexiblePolyline(
                section.polyline
              );

              // Create a polyline to display the route:
              let routeOutline = new H.map.Polyline(linestring, {
                style: {
                  lineWidth: 8,
                  strokeColor: "#007788",
                  lineTailCap: "arrow-tail",
                  lineHeadCap: "arrow-head",
                },
              });

              // Create a patterned polyline:
              var routeArrows = new H.map.Polyline(linestring, {
                style: {
                  lineWidth: 8,
                  fillColor: "white",
                  strokeColor: "#ffff",
                  lineDash: [0, 1],
                  lineTailCap: "arrow-tail",
                  lineHeadCap: "arrow-head",
                },
              });

              // create a group that represents the route line and contains
              // outline and the pattern
              var routeLine = new H.map.Group();
              routeLine.addObjects([routeOutline, routeArrows]);

              // Define a variable holding SVG mark-up that defines an icon image:
              var svgMarkup =
                '<svg width="24" height="24" ' +
                'xmlns="http://www.w3.org/2000/svg">' +
                '<rect stroke="white" fill="#1b468d" x="1" y="1" width="12" ' +
                'height="12" /><text x="7" y="11" font-size="8pt" ' +
                'font-family="Arial" font-weight="bold" text-anchor="middle" ' +
                'fill="white">P</text></svg>';

              var alertSubCategory = "";
              var imageFileName = "";

              let startMarker;
              let endMarker;
              var icon;
              var tooltipContent = "";
              var bubble;
              if (sectionTemp == 0) {
                alertSubCategory = tripRiskSigList[sectionTemp];

                if (alertSubCategory.alertType == "START_DATA") {
                  imageFileName = "start.png";
                } else if (alertSubCategory.alertType == "MOBILE_SCREEN") {
                  imageFileName = "mobileScreen.svg";
                } else {
                  imageFileName = "start.png";
                }

                svgMarkup = require("../../assets/images/" + imageFileName);
                icon = new H.map.Icon(svgMarkup, {
                  size: { w: 40, h: 40 },
                });
                startMarker = new H.map.Marker(
                  section.departure.place.location,
                  {
                    icon: icon,
                  }
                );

                sectionTemp = sectionTemp + 1;
                alertSubCategory = tripRiskSigList[sectionTemp];
                if (alertSubCategory == undefined) {
                  alertSubCategory = { alertType: "" };
                }
                if (alertSubCategory.alertType == alertDataType.END_DATA) {
                  imageFileName = "finish.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.START_DATA
                ) {
                  imageFileName = "start.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.ANIMAL_CROSSING
                ) {
                  imageFileName = "t_animal_crossing.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.CAUTION
                ) {
                  imageFileName = "t_caution.png";
                } else if (alertSubCategory.alertType == alertDataType.CURVE) {
                  imageFileName = "t_curves.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.HILL_DOWNWARDS
                ) {
                  imageFileName = "t_hill_downwards.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.HILL_UPWARDS
                ) {
                  imageFileName = "t_hill_upwards.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.INTERSECTION
                ) {
                  imageFileName = "t_intersection.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.LANE_MERGE
                ) {
                  imageFileName = "t_lane_merge.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.LOW_GEAR_AREA
                ) {
                  imageFileName = "t_low_gear_area.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.NARROW_ROAD
                ) {
                  imageFileName = "t_narrow_road.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.MOBILE_USE
                ) {
                  imageFileName = "t_no_mob_use.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.NO_OVERTAKING
                ) {
                  imageFileName = "t_no_overtaking.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.OVER_SPEED
                ) {
                  imageFileName = "t_over_speed.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.P_CROSSING
                ) {
                  imageFileName = "t_pedestrian_crossing.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.R_CROSSING
                ) {
                  imageFileName = "t_railway_crossing.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.SCHOOL_ZONE
                ) {
                  imageFileName = "t_school_zone.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.STOP_SIGN
                ) {
                  imageFileName = "t_stop.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.SUDDEN_BRAKING
                ) {
                  imageFileName = "t_sudden_break.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.TRAFFIC_LIGHT
                ) {
                  imageFileName = "t_traffic_light.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.WINDING_ROAD
                ) {
                  imageFileName = "t_winding_road.png";
                } else if (alertSubCategory.alertType == alertDataType.YIELD) {
                  imageFileName = "t_yield.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.PRIORITY
                ) {
                  imageFileName = "t_priority.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.ICY_CONDITIONS
                ) {
                  imageFileName = "t_icy_conditions.png";
                } else if (alertSubCategory.alertType == alertDataType.WIND) {
                  imageFileName = "t_wind.png";
                } else if (alertSubCategory.alertType == alertDataType.HILL) {
                  imageFileName = "t_hill.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.RISK_OF_GROUNDING
                ) {
                  imageFileName = "t_risk_of_grouping.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.SLIPPERY_ROADS
                ) {
                  imageFileName = "t_slippery_road.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.ROUND_ABOUT
                ) {
                  imageFileName = "Round-about.svg";
                } else if (
                  alertSubCategory.alertType ==
                  alertDataType.NO_OVERTAKING_TRUCKS
                ) {
                  imageFileName = "No-overtaking-trucks.svg";
                }

                if (alertSubCategory.alertType == alertDataType.OVER_SPEED) {
                  imageFileName = "overspeed.svg";
                } else if (alertSubCategory.alertType == "MOBILE_USE") {
                  imageFileName = "mobilecallImage.svg";
                } else if (alertSubCategory.alertType == "MOBILE_SCREEN") {
                  imageFileName = "mobileScreen.svg";
                }

                if (imageFileName.length > 0) {
                  svgMarkup = require("../../assets/images/" + imageFileName);
                  icon = new H.map.Icon(svgMarkup, { size: { w: 40, h: 40 } });
                  endMarker = new H.map.Marker(section.arrival.place.location, {
                    icon: icon,
                  });
                  tooltipContent =
                    '<div style="min-width:180px;> <div style="display:flex">Alert :' +
                    ValueFormat.capitalizeFirstLetterAndFormat(
                      alertSubCategory.alertType
                    ) +
                    "</div>" +
                    '<div style="display:flex">Speed :' +
                    ValueFormat.capitalizeFirstLetterAndFormat(
                      alertSubCategory.speed
                    ) +
                    "</div>" +
                    '<div style="display:flex">Time :' +
                    ValueFormat.capitalizeFirstLetterAndFormat(
                      alertSubCategory.time
                    ) +
                    "</div>" +
                    "</div>";
                  endMarker.setData(tooltipContent);
                  endMarker.addEventListener(
                    "pointerenter",
                    function (evt) {
                      // console.log("routline event : ", evt)
                      // event target is the marker itself, group is a parent event target
                      // for all objects that it contains
                      bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                        // read custom data
                        content: evt.target.getData(),
                      });
                      // show info bubble
                      ui.addBubble(bubble);
                    },
                    false
                  );
                  endMarker.addEventListener(
                    "pointerleave",
                    function (evt) {
                      bubble.close();
                    },
                    false
                  );
                }

                map.addObjects([routeLine, startMarker, endMarker]);
              }
              // else if (sectionTemp == noOfSection - 1) {
              //   alertSubCategory = tripRiskSigList[sectionTemp];

              //   svgMarkup = require("../../assets/images/finish.png");
              //   icon = new H.map.Icon(svgMarkup, { size: { w: 40, h: 40 } });
              //   endMarker = new H.map.Marker(section.arrival.place.location, {
              //     icon: icon,
              //   });

              //   map.addObjects([routeLine, endMarker]);
              //   sectionTemp = sectionTemp + 1;
              //   alertSubCategory = tripRiskSigList[sectionTemp];
              // }
              else {
                sectionTemp = sectionTemp + 1;

                // Create an icon, an object holding the latitude and longitude, and a marker:
                alertSubCategory = tripRiskSigList[sectionTemp];
                if (alertSubCategory.alertType == alertDataType.START_DATA) {
                  imageFileName = "start.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.END_DATA
                ) {
                  imageFileName = "finish.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.ANIMAL_CROSSING
                ) {
                  imageFileName = "t_animal_crossing.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.CAUTION
                ) {
                  imageFileName = "t_caution.png";
                } else if (alertSubCategory.alertType == alertDataType.CURVE) {
                  imageFileName = "t_curves.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.FORCE_ACCELERATION
                ) {
                  imageFileName = "t_force_acceleration.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.HILL_DOWNWARDS
                ) {
                  imageFileName = "t_hill_downwards.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.HILL_UPWARDS
                ) {
                  imageFileName = "t_hill_upwards.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.INTERSECTION
                ) {
                  imageFileName = "t_intersection.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.LANE_MERGE
                ) {
                  imageFileName = "t_lane_merge.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.LOW_GEAR_AREA
                ) {
                  imageFileName = "t_low_gear_area.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.NARROW_ROAD
                ) {
                  imageFileName = "t_narrow_road.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.MOBILE_USE
                ) {
                  imageFileName = "t_no_mob_use.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.NO_OVERTAKING
                ) {
                  imageFileName = "t_no_overtaking.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.OVER_SPEED
                ) {
                  imageFileName = "t_over_speed.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.P_CROSSING
                ) {
                  imageFileName = "t_pedestrian_crossing.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.R_CROSSING
                ) {
                  imageFileName = "t_railway_crossing.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.SCHOOL_ZONE
                ) {
                  imageFileName = "t_school_zone.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.STOP_SIGN
                ) {
                  imageFileName = "t_stop.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.SUDDEN_BRAKING
                ) {
                  imageFileName = "t_sudden_break.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.TRAFFIC_LIGHT
                ) {
                  imageFileName = "t_traffic_light.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.WINDING_ROAD
                ) {
                  imageFileName = "t_winding_road.png";
                } else if (alertSubCategory.alertType == alertDataType.YIELD) {
                  imageFileName = "t_yield.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.PRIORITY
                ) {
                  imageFileName = "t_priority.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.ICY_CONDITIONS
                ) {
                  imageFileName = "t_icy_conditions.png";
                } else if (alertSubCategory.alertType == alertDataType.WIND) {
                  imageFileName = "t_wind.png";
                } else if (alertSubCategory.alertType == alertDataType.HILL) {
                  imageFileName = "t_hill.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.RISK_OF_GROUNDING
                ) {
                  imageFileName = "t_risk_of_grouping.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.SLIPPERY_ROADS
                ) {
                  imageFileName = "t_slippery_road.png";
                } else if (
                  alertSubCategory.alertType == alertDataType.ROUND_ABOUT
                ) {
                  imageFileName = "Round-about.svg";
                } else if (
                  alertSubCategory.alertType ==
                  alertDataType.NO_OVERTAKING_TRUCKS
                ) {
                  imageFileName = "No-overtaking-trucks.svg";
                }

                if (alertSubCategory.alertType == alertDataType.OVER_SPEED) {
                  imageFileName = "overspeed.svg";
                } else if (alertSubCategory.alertType == "MOBILE_USE") {
                  imageFileName = "mobilecallImage.svg";
                } else if (alertSubCategory.alertType == "MOBILE_SCREEN") {
                  imageFileName = "mobileScreen.svg";
                }

                if (imageFileName.length > 0) {
                  svgMarkup = require("../../assets/images/" + imageFileName);
                  icon = new H.map.Icon(svgMarkup, { size: { w: 40, h: 40 } });
                  endMarker = new H.map.Marker(section.arrival.place.location, {
                    icon: icon,
                  });
                  tooltipContent =
                    '<div style="min-width:180px;> <div style="display:flex">Alert :' +
                    ValueFormat.capitalizeFirstLetterAndFormat(
                      alertSubCategory.alertType
                    ) +
                    "</div>" +
                    '<div style="display:flex">Speed :' +
                    ValueFormat.capitalizeFirstLetterAndFormat(
                      alertSubCategory.speed
                    ) +
                    "</div>" +
                    '<div style="display:flex">Time :' +
                    ValueFormat.capitalizeFirstLetterAndFormat(
                      alertSubCategory.time
                    ) +
                    "</div>" +
                    "</div>";

                  endMarker.setData(tooltipContent);
                  endMarker.addEventListener(
                    "pointerenter",
                    function (evt) {
                      // console.log("routline event : ", evt)
                      // event target is the marker itself, group is a parent event target
                      // for all objects that it contains
                      bubble = new H.ui.InfoBubble(evt.target.getGeometry(), {
                        // read custom data
                        content: evt.target.getData(),
                      });
                      // show info bubble
                      ui.addBubble(bubble);
                    },
                    false
                  );
                  endMarker.addEventListener(
                    "pointerleave",
                    function (evt) {
                      bubble.close();
                    },
                    false
                  );

                  map.addObjects([routeLine, endMarker]);
                } else {
                  endMarker = new H.map.Marker(section.arrival.place.location);

                  map.addObjects([routeLine, endMarker]);
                }
              }
              // map.setCenter({lat:startLat, lng:startLng});

              // map.setZoom(10);
              // Add the route polyline and the two markers to the map:

              // Set the map's viewport to make the whole route visible:
              var boundsData = new H.geo.Rect(
                startLat,
                startLng,
                endLat,
                endLng
              );
              map.getViewModel().setLookAtData({
                bounds: boundsData,
                position: { lat: startLat, lng: startLng },
                zoom: 11,
              });
            });
          }
        };
        var router = platform.getRoutingService(null, 8);
        router.calculateRoute(routingParameters, onResult, function (error) {
          var hmError = error.message;
          if (hmError.length > 0) {
            console.log("Heremap Error: " + error.message);
          }
        });
        // var behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        // Add interactive behavior
        new H.mapevents.Behavior(new H.mapevents.MapEvents(map));
        // Clean up resources
        return () => {
          map.dispose();
        };
      }
    } else {
      console.log("Status failed");
    }
  };

  // const getMyRideDetails = async () => {
  //   setShowLoader((current) => current + 1);
  //   var riskAlertType = "";
  //   var result = await MyRideDetailController.riskAlertController(
  //     riskAlertType,
  //     locationParams.rideId,
  //     locationParams.divisionId,
  //     locationParams.userId,
  //     rideCategory // ridecategory added

  //   );
  //   setShowLoader((current) => current - 1);
  //   var ANIMAL_CROSSING = [];
  //   var CAUTION = [];
  //   var CURVE = [];
  //   var ROUND_ABOUT = [];
  //   var HILL = [];
  //   var HILL_DOWNWARDS = [];
  //   var HILL_UPWARDS = [];
  //   var ICY_CONDITIONS = [];
  //   var INTERSECTION = [];
  //   var LANE_MERGE = [];
  //   var LOW_GEAR_AREA = [];
  //   var MOBILE_USE = [];
  //   var NARROW_ROAD = [];
  //   var NO_OVERTAKING = [];
  //   var OVER_SPEED = [];
  //   var PEDESTRIAN_CROSSING = [];
  //   var PRIORITY = [];
  //   var RAILWAY_CROSSING = [];
  //   var RISK_OF_GROUNDING = [];
  //   var SCHOOL_ZONE = [];
  //   var SLIPPERY_ROADS = [];
  //   var STOP_SIGN = [];
  //   var NO_OVERTAKING_TRUCKS = [];
  //   var TRAFFIC_LIGHT = [];
  //   var WIND = [];
  //   var WINDING_ROAD = [];
  //   var YIELD = [];

  //   var resultJSON = JSON.parse(result);
  //   var status = resultJSON.status;
  //   // console.log("riskAlerts: ", resultJSON.data)
  //   if (status == "SUCCESS") {
  //     var resultJSONData = resultJSON.data;
  //     var totalRiskAlerts = 0;
  //     var mobileUseCount = 0;
  //     var mobileScreenCount = 0;
  //     var overSpeedCount = 0;
  //     var riskDuration = 0;
  //     var counts = 0;
  //     var mobileScreenOnDuration = 0;
  //     var mobileScreenOnKiloMeter = 0;
  //     var mobileUseCallKiloMeter = 0;
  //     var mobileUseCallDuration = 0;
  //     var overSpeedDuration = 0;
  //     var overSpeedKiloMeter = 0;
  //     console.log("getMyRideDetails: " + JSON.stringify(resultJSON));

  //     try {
  //       for (let i = 0; i < resultJSONData.length; i++) {
  //         var rData = resultJSONData[i];
  //         var riskAlertDetailCount = ValueFormat.parseAsArray(
  //           rData.riskAlertDetailCount
  //         );
  //         var rideSummary = ValueFormat.parseAsObject(rData.rideSummary);

  //         mobileScreenOnDuration = ValueFormat.parseAsInt(
  //           rideSummary.mobileScreenOnDuration
  //         );
  //         mobileUseCallDuration = ValueFormat.parseAsInt(
  //           rideSummary.mobileUseCallDuration
  //         );
  //         overSpeedDuration = ValueFormat.parseAsInt(
  //           rideSummary.overSpeedDuration
  //         );
  //         mobileScreenOnKiloMeter = ValueFormat.parseAsInt(
  //           rideSummary.mobileScreenOnKiloMeter
  //         );
  //         mobileUseCallKiloMeter = ValueFormat.parseAsInt(
  //           rideSummary.mobileUseCallKiloMeter
  //         );
  //         overSpeedKiloMeter = ValueFormat.parseAsInt(
  //           rideSummary.overSpeedKiloMeter
  //         );

  //         for (let count = 0; count < riskAlertDetailCount.length; count++) {
  //           var riskData = riskAlertDetailCount[count];
  //           var category = ValueFormat.parseAsString(riskData.category);
  //           var subCategory = ValueFormat.parseAsString(riskData.subCategory);
  //           var riskValueList = ValueFormat.parseAsArray(
  //             riskData.riskValueList
  //           );
  //           var noOfRecords = ValueFormat.parseAsString(riskData.noOfRecords);
  //           // var activityKiloMeter = ValueFormat.parseAsInt(riskData.activityKiloMeter);
  //           // var activityDuration = ValueFormat.parseAsInt(riskData.activityDuration);
  //           if (category == "ALERT_DATA") {
  //             totalRiskAlerts = riskValueList.length + totalRiskAlerts;
  //           }
  //           if (subCategory == "ANIMAL_CROSSING") {
  //             ANIMAL_CROSSING.push(...riskValueList);
  //           } else if (subCategory == "CAUTION") {
  //             CAUTION.push(...riskValueList);
  //           } else if (subCategory == "TRAFFIC_LIGHT") {
  //             TRAFFIC_LIGHT.push(...riskValueList);
  //           } else if (subCategory == "CURVE") {
  //             CURVE.push(...riskValueList);
  //           } else if (subCategory == "INTERSECTION") {
  //             INTERSECTION.push(...riskValueList);
  //           } else if (subCategory == "NARROW_ROAD") {
  //             NARROW_ROAD.push(...riskValueList);
  //           } else if (subCategory == "PEDESTRIAN_CROSSING") {
  //             PEDESTRIAN_CROSSING.push(...riskValueList);
  //           } else if (subCategory == "RAILWAY_CROSSING") {
  //             RAILWAY_CROSSING.push(...riskValueList);
  //           } else if (subCategory == "WINDING_ROAD") {
  //             WINDING_ROAD.push(...riskValueList);
  //             riskLabel = "Winding Road";
  //           } else if (subCategory == "WIND") {
  //             WIND.push(...riskValueList);
  //           } else if (subCategory == "HILL_DOWNWARDS") {
  //             HILL_DOWNWARDS.push(...riskValueList);
  //           } else if (subCategory == "HILL_UPWARDS") {
  //             HILL_UPWARDS.push(...riskValueList);
  //           } else if (subCategory == "YIELD") {
  //             YIELD.push(...riskValueList);
  //           } else if (subCategory == "SCHOOL_ZONE") {
  //             SCHOOL_ZONE.push(...riskValueList);
  //           } else if (subCategory == "ROUND_ABOUT") {
  //             ROUND_ABOUT.push(...riskValueList);
  //           } else if (subCategory == "LANE_MERGE") {
  //             LANE_MERGE.push(...riskValueList);
  //           } else if (subCategory == "NO_OVERTAKING") {
  //             NO_OVERTAKING.push(...riskValueList);
  //           } else if (subCategory == "STOP_SIGN") {
  //             STOP_SIGN.push(...riskValueList);
  //           } else if (subCategory == "HILL") {
  //             HILL.push(...riskValueList);
  //           } else if (subCategory == "ICY_CONDITIONS") {
  //             ICY_CONDITIONS.push(...riskValueList);
  //           } else if (subCategory == "PRIORITY") {
  //             PRIORITY.push(...riskValueList);
  //           } else if (subCategory == "RISK_OF_GROUNDING") {
  //             RISK_OF_GROUNDING.push(...riskValueList);
  //           } else if (subCategory == "NO_OVERTAKING_TRUCKS") {
  //             NO_OVERTAKING_TRUCKS.push(...riskValueList);
  //           } else if (subCategory == "LOW_GEAR_AREA") {
  //             LOW_GEAR_AREA.push(...riskValueList);
  //           } else if (subCategory == "SLIPPERY_ROADS") {
  //             SLIPPERY_ROADS.push(...riskValueList);
  //           } else if (subCategory == "MOBILE_USE") {
  //             // mobileUseCallDuration = activityDuration;
  //             // mobileUseCallKiloMeter = activityKiloMeter;
  //             mobileUseCount = mobileUseCount + noOfRecords;
  //           } else if (subCategory == "OVER_SPEED") {
  //             overSpeedCount = overSpeedCount + noOfRecords;
  //             // overSpeedDuration = activityDuration;
  //             // overSpeedKiloMeter = activityKiloMeter;
  //           } else if (subCategory == "MOBILE_SCREEN") {
  //             // mobileScreenOnDuration = activityDuration;
  //             // mobileScreenOnKiloMeter = activityKiloMeter;
  //             mobileScreenCount = mobileScreenCount + noOfRecords;
  //           }
  //         }
  //       }

  //       var otherRiskCount = { count: mobileScreenCount };
  //       // setOtherRiskData(
  //       //   otherRiskData.map((value) => {
  //       //     var count = 0;
  //       //     var kilometer = 0;
  //       //     var duration = 0;

  //       //     if (value.type == "OVER_SPEED") {
  //       //       count = overSpeedCount;
  //       //       kilometer = overSpeedCount > 0 ? overSpeedKiloMeter : 0;
  //       //       duration = overSpeedCount > 0 ? overSpeedDuration : 0;
  //       //     } else if (value.type == "MOBILE_CALL_USE") {
  //       //       count = mobileUseCount;
  //       //       kilometer = mobileUseCount > 0 ? mobileUseCallKiloMeter : 0;
  //       //       duration = mobileUseCount > 0 ? mobileUseCallDuration : 0;
  //       //     } else if (value.type == "MOBILE_SCREEN_USE") {
  //       //       count = mobileScreenCount;
  //       //       kilometer = mobileScreenCount > 0 ? mobileScreenOnKiloMeter : 0;
  //       //       duration = mobileScreenCount > 0 ? mobileScreenOnDuration : 0;
  //       //     } else {
  //       //       return value;
  //       //     }

  //       //     var result = {
  //       //       count: count,
  //       //       kilometer: kilometer,
  //       //       duration: duration,
  //       //     };
  //       //     setCategoryLineChart(
  //       //       Object.values(result).some((value) => value !== 0)
  //       //     );
  //       //     return { ...value, ...result };
  //       //   })
  //       // );

  //       // setRiskDataList(
  //       //   (riskDataList || []).map((staticData) => {
  //       //     let dataLength = 0;
  //       //     let dataList = [];
  //       //     var isMatched = false;

  //       //     if (staticData.type === "ANIMAL_CROSSING") {
  //       //       isMatched = true;
  //       //       dataList = ANIMAL_CROSSING;
  //       //       dataLength = ANIMAL_CROSSING.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "CAUTION") {
  //       //       isMatched = true;
  //       //       dataList = CAUTION;
  //       //       dataLength = CAUTION.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "CURVE") {
  //       //       isMatched = true;
  //       //       dataList = CURVE;
  //       //       dataLength = CURVE.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "ROUND") {
  //       //       isMatched = true;
  //       //       dataList = ROUND_ABOUT;
  //       //       dataLength = ROUND_ABOUT.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "HILL") {
  //       //       isMatched = true;
  //       //       dataList = HILL;
  //       //       dataLength = HILL.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "HILL_DOWN") {
  //       //       isMatched = true;
  //       //       dataList = HILL_DOWNWARDS;
  //       //       dataLength = HILL_DOWNWARDS.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "HILL_UP") {
  //       //       isMatched = true;
  //       //       dataList = HILL_UPWARDS;
  //       //       dataLength = HILL_UPWARDS.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "ICY") {
  //       //       isMatched = true;
  //       //       dataList = ICY_CONDITIONS;
  //       //       dataLength = ICY_CONDITIONS.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "INTERSECTION") {
  //       //       isMatched = true;
  //       //       dataList = INTERSECTION;
  //       //       dataLength = INTERSECTION.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "LANE_MERGE") {
  //       //       isMatched = true;
  //       //       dataList = LANE_MERGE;
  //       //       dataLength = LANE_MERGE.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "LOW_GEAR") {
  //       //       isMatched = true;
  //       //       dataList = LOW_GEAR_AREA;
  //       //       dataLength = LOW_GEAR_AREA.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "MOBILE") {
  //       //       isMatched = true;
  //       //       dataList = MOBILE_USE;
  //       //       dataLength = MOBILE_USE.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "NARROW_ROAD") {
  //       //       isMatched = true;
  //       //       dataList = NARROW_ROAD;
  //       //       dataLength = NARROW_ROAD.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "OVERTAKING") {
  //       //       isMatched = true;
  //       //       dataList = NO_OVERTAKING;
  //       //       dataLength = NO_OVERTAKING.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "OVER_SPEED") {
  //       //       isMatched = true;
  //       //       dataList = OVER_SPEED;
  //       //       dataLength = OVER_SPEED.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "CROSSING") {
  //       //       isMatched = true;
  //       //       dataList = PEDESTRIAN_CROSSING;
  //       //       dataLength = PEDESTRIAN_CROSSING.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "PRIORITY") {
  //       //       isMatched = true;
  //       //       dataList = PRIORITY;
  //       //       dataLength = PRIORITY.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "RAILWAY") {
  //       //       isMatched = true;
  //       //       dataList = RAILWAY_CROSSING;
  //       //       dataLength = RAILWAY_CROSSING.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "RISK_GROUNDING") {
  //       //       isMatched = true;
  //       //       dataList = RISK_OF_GROUNDING;
  //       //       dataLength = RISK_OF_GROUNDING.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "SCHOOL") {
  //       //       isMatched = true;
  //       //       dataList = SCHOOL_ZONE;
  //       //       dataLength = SCHOOL_ZONE.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "SLIPPERY_ROADS") {
  //       //       isMatched = true;
  //       //       dataList = SLIPPERY_ROADS;
  //       //       dataLength = SLIPPERY_ROADS.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "STOP") {
  //       //       isMatched = true;
  //       //       dataList = STOP_SIGN;
  //       //       dataLength = STOP_SIGN.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "OVERTAKING_TRUCKS") {
  //       //       isMatched = true;
  //       //       dataList = NO_OVERTAKING_TRUCKS;
  //       //       dataLength = NO_OVERTAKING_TRUCKS.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "TRAFFIC_LIGHT") {
  //       //       isMatched = true;
  //       //       dataList = TRAFFIC_LIGHT;
  //       //       dataLength = TRAFFIC_LIGHT.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "WIND") {
  //       //       isMatched = true;
  //       //       dataList = WIND;
  //       //       dataLength = WIND.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "WINDING_ROAD") {
  //       //       isMatched = true;
  //       //       dataList = WINDING_ROAD;
  //       //       dataLength = WINDING_ROAD.length;
  //       //       counts = counts + dataLength;
  //       //     } else if (staticData.type === "YIELD") {
  //       //       isMatched = true;
  //       //       dataList = YIELD;
  //       //       dataLength = YIELD.length;
  //       //       counts = counts + dataLength;
  //       //     }

  //       //     if (isMatched === true) {
  //       //       var result = {
  //       //         overallData: dataLength,
  //       //         currentMonthGraphData: dataList,
  //       //       };

  //       //       return { ...staticData, ...result };
  //       //     } else {
  //       //       return staticData;
  //       //     }
  //       //   })
  //       // );

  //       // setRiskDataListleng(counts > 0 ? true : false);

  //       // setTotalAlerts(totalRiskAlerts);
  //     } catch (errMess) {
  //       // console.log(errMess);
  //     }
  //   } else {
  //     const code = resultJSON.error.code;
  //     if (code == "E1206") {
  //       setCategoryLineChart("failed");
  //       setRiskDataListleng("failed");
  //     }
  //   }
  // };

  const newManualLertDetails = async () => {
    try {
      setShowLoader((current) => current + 1);
      var result = await MyRideDetailController.manualAlertController(
        locationParams.rideId,
        locationParams.divisionId,
        locationParams.userId,
        rideCategory // ridecategory added
      );
      var resultJSON = JSON.parse(result);
      var status = resultJSON.status;
      setCategoryLineChart(null);
      if (status === "SUCCESS") {
        var resultJSONData = resultJSON.data;
        if (resultJSONData.length > 0) {
          const rideSummary = resultJSONData[0].rideSummary;
          const {
            accidentCount,
            animalCrossingCount,
            cautionCount,
            congestionCount,
            curveCount,
            hillCount,
            hillDownwardsCount,
            hillUpwardsCount,
            icyConditionsCount,
            intersectionCount,
            laneMergeCount,
            lowGearAreaCount,
            mobileScreenScreenOnCount,
            mobileScreenScreenOnDistance,
            mobileScreenScreenOnDuration,
            mobileUseInAcceptedCount,
            mobileUseInAcceptedDistance,
            mobileUseInAcceptedDuration,
            mobileUseOutAcceptedCount,
            mobileUseOutAcceptedDistance,
            mobileUseOutAcceptedDuration,
            narrowRoadCount,
            noOfRecords,
            noOvertakingCount,
            noOvertakingTrucksCount,
            overSpeedCount,
            overSpeedDistance,
            overSpeedDuration,
            pedestrianCrossingCount,
            priorityCount,
            priorityToOncomingTrafficCount,
            railwayCrossingCount,
            riskOfGroundingCount,
            roundAboutCount,
            schoolZoneCount,
            severeAccelerationHighCount,
            severeAccelerationLowCount,
            severeAccelerationMediumCount,
            severeBrakingHighCount,
            severeBrakingLowCount,
            severeBrakingMediumCount,
            severeCorneringHighCount,
            severeCorneringLowCount,
            severeCorneringMediumCount,
            slipperyRoadsCount,
            stopSignCount,
            trafficLightCount,
            tramwayCrossingCount,
            windCount,
            windingRoadCount,
            yieldCount,
          } = rideSummary;

          const updatedData = data.map((item) => {
            switch (item.type) {
              case "ANIMAL_CROSSING":
                return { ...item, overallData: animalCrossingCount };
              case "CAUTION":
                return { ...item, overallData: cautionCount };
              case "CURVE":
                return { ...item, overallData: curveCount };
              case "ROUND":
                return { ...item, overallData: roundAboutCount };
              case "HILL":
                return { ...item, overallData: hillCount };
              case "HILL_DOWN":
                return { ...item, overallData: hillDownwardsCount };
              case "HILL_UP":
                return { ...item, overallData: hillUpwardsCount };
              case "ICY":
                return { ...item, overallData: icyConditionsCount };
              case "INTERSECTION":
                return { ...item, overallData: intersectionCount };
              case "LANE_MERGE":
                return { ...item, overallData: laneMergeCount };
              case "LOW_GEAR":
                return { ...item, overallData: lowGearAreaCount };
              case "NARROW_ROAD":
                return { ...item, overallData: narrowRoadCount };
              case "OVERTAKING":
                return { ...item, overallData: noOvertakingCount };
              case "CROSSING":
                return { ...item, overallData: pedestrianCrossingCount };
              case "PRIORITY":
                return { ...item, overallData: priorityCount };
              case "RAILWAY":
                return { ...item, overallData: railwayCrossingCount };
              case "RISK_GROUNDING":
                return { ...item, overallData: riskOfGroundingCount };
              case "SCHOOL":
                return { ...item, overallData: schoolZoneCount };
              case "SLIPPERY_ROADS":
                return { ...item, overallData: slipperyRoadsCount };
              case "STOP":
                return { ...item, overallData: stopSignCount };
              case "TRAFFIC_LIGHT":
                return { ...item, overallData: trafficLightCount };
              case "WIND":
                return { ...item, overallData: windCount };
              case "WINDING_ROAD":
                return { ...item, overallData: windingRoadCount };
              case "YIELD":
                return { ...item, overallData: yieldCount };
              default:
                return item;
            }
          });

          
          setRiskDataList(splitArrayIntoChunks(updatedData) || []);
          setTotalAlerts(updatedData.reduce((sum, item) => sum + item.overallData, 0));
          setRiskDataListleng(updatedData.reduce((sum, item) => sum + item.overallData, 0) > 0 ? true : false);


          setOtherRiskData(
            otherRiskData.map((value) => {
              var count = 0;
              var kilometer = 0;
              var duration = 0;

              if (value.type == "OVER_SPEED") {
                count = overSpeedCount;
                kilometer = overSpeedCount > 0 ? overSpeedDistance : 0;
                duration = overSpeedCount > 0 ? overSpeedDuration : 0;
              } else if (value.type == "MOBILE_CALL_USE") {
                count = ValueFormat.parseAsInt(mobileUseInAcceptedCount) + ValueFormat.parseAsInt(mobileUseOutAcceptedCount);
                kilometer =
                  mobileUseInAcceptedDistance > 0
                    ? mobileUseInAcceptedDistance
                    : 0;
                duration =
                  mobileUseInAcceptedDuration > 0
                    ? mobileUseInAcceptedDuration
                    : 0;
              } else if (value.type == "MOBILE_SCREEN_USE") {
                count = mobileScreenScreenOnCount;
                kilometer =
                  mobileScreenScreenOnDistance > 0
                    ? mobileScreenScreenOnDistance
                    : 0;
                duration =
                  mobileScreenScreenOnDuration > 0
                    ? mobileScreenScreenOnDuration
                    : 0;
              } else {
                return value;
              }

              var result = {
                count: count,
                kilometer: kilometer,
                duration: duration,
              };
              setCategoryLineChart(Object.values(result).some((value) => value !== 0));
              return { ...value, ...result };
            })
          );
        } else {
          console.log("Data not available");
          setCategoryLineChart(true);
        }
      } else {
        const code = resultJSON.error.code;
        if (code == "E1206") {
          setCategoryLineChart("failed");
          setRiskDataListleng("failed");
        } else {
          setCategoryLineChart(true);
        }
      }
    } catch (e) {
      console.log("catch error: ", e);
    } finally {
      setShowLoader((current) => current - 1);
    }
  };

  const getStressStrain = async () => {
    setShowLoader((current) => current + 1);
    var result = await MyRideDetailController.stressStrainController(
      locationParams.rideId,
      locationParams.divisionId,
      locationParams.userId,
      rideCategory // ridecategory added
    );
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    // console.log("stressStrain :", resultJSON.data);
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      var stressStrain = {};
      // console.log("getStressStrain : " + JSON.stringify(resultJSONData));
      var sCorneringLow = 0;
      var sCorneringMedium = 0;
      var sCorneringHigh = 0;
      var sAccelerationLow = 0;
      var sAccelerationMedium = 0;
      var sAccelerationHigh = 0;
      var sBrakingLow = 0;
      var sBrakingMedium = 0;
      var sBrakingHigh = 0;

      try {
        resultJSONData.map((value) => {
          var deviceDataCountList = value.deviceDataCountList;
          deviceDataCountList.map((result) => {
            var subCategory = ValueFormat.parseAsString(result.subCategory);

            var subCategoryLevel = ValueFormat.parseAsString(
              result.subCategoryLevel
            );
            var noOfRecords = ValueFormat.parseAsInt(result.noOfRecords);

            if (
              subCategory == "SEVERE_CORNERING" &&
              subCategoryLevel == "LOW"
            ) {
              sCorneringLow = noOfRecords;
            } else if (
              subCategory == "SEVERE_CORNERING" &&
              subCategoryLevel == "MEDIUM"
            ) {
              sCorneringMedium = noOfRecords;
            } else if (
              subCategory == "SEVERE_CORNERING" &&
              subCategoryLevel == "HIGH"
            ) {
              sCorneringHigh = noOfRecords;
            }

            if (
              subCategory == "SEVERE_ACCELERATION" &&
              subCategoryLevel == "LOW"
            ) {
              sAccelerationLow = noOfRecords;
            } else if (
              subCategory == "SEVERE_ACCELERATION" &&
              subCategoryLevel == "MEDIUM"
            ) {
              sAccelerationMedium = noOfRecords;
            } else if (
              subCategory == "SEVERE_ACCELERATION" &&
              subCategoryLevel == "HIGH"
            ) {
              sAccelerationHigh = noOfRecords;
            }

            if (subCategory == "SEVERE_BRAKING" && subCategoryLevel == "LOW") {
              sBrakingLow = noOfRecords;
            } else if (
              subCategory == "SEVERE_BRAKING" &&
              subCategoryLevel == "MEDIUM"
            ) {
              sBrakingMedium = noOfRecords;
            } else if (
              subCategory == "SEVERE_BRAKING" &&
              subCategoryLevel == "HIGH"
            ) {
              sBrakingHigh = noOfRecords;
            }
          });

          var count =
            sCorneringLow +
            sCorneringMedium +
            sCorneringHigh +
            sAccelerationLow +
            sAccelerationMedium +
            sAccelerationHigh +
            sBrakingLow +
            sBrakingMedium +
            sBrakingHigh;
          setAccelerationData(count > 0 ? true : false);
          stressStrain = {
            sCorneringLow: sCorneringLow,
            sCorneringMedium: sCorneringMedium,
            sCorneringHigh: sCorneringHigh,
            sAccelerationLow: sAccelerationLow,
            sAccelerationMedium: sAccelerationMedium,
            sAccelerationHigh: sAccelerationHigh,
            sBrakingLow: sBrakingLow,
            sBrakingMedium: sBrakingMedium,
            sBrakingHigh: sBrakingHigh,
          };

          setStressStrainData((pre) => {
            return { ...pre, ...stressStrain };
          });
        });
      } catch (e) {
        // console.log("getStressStrainError: " + e);
      }
    } else {
      var code = resultJSON.error.code;
      if (code == "E1206") {
        setAccelerationData("failed");
      }
    }
  };

  // console.log("bell", riskDataList);
  useEffect(() => {
    setHistogramData();
    setSummaryData();
    setAcbTableData();
    setAlertValiDate();
    getAlertSummary();
    getSummaryData();
    getRiskAlertsDataList();
    getRiskRideDetail();
    // getMyRideDetails();
    getStressStrain();
    newManualLertDetails();
  }, [locationParams]);
  useEffect(() => {
    leaderBoardData();
  }, [locationParams, redRiskDistanceData]);

  const typeMapping = {
    "Severe acceleration": "Acc",
    "Severe cornering": "Cor",
    "Severe braking": "Brak",
  };
  const [overSpeedTableData, setOverSpeedTableData] = useState([]);
  const [mobileUseTableData, setMobileUseTableData] = useState([]);
  const [mobileScreenTableData, setMobileScreenTableData] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleOpen = () => setDropdownOpen((prevState) => !prevState);

  const handleOverSpeedTableData = (data) => {
    setOverSpeedTableData(data);
  };
  const handleMobileUseTableData = (data) => {
    setMobileUseTableData(data);
  };
  const handleMobileScreenTableData = (data) => {
    setMobileScreenTableData(data);
  };
  const handleRedRiskDistanceData = (data) => {
    setRedRiskDistanceData(data);
  };

  const [fuelEfficiencyData, setFuelEfficiencyData] = useState(0);
  const [fuelConsumptionData, setFuelConsumptionData] = useState(0);
  const [maxSpeedData, setMaxSpeedData] = useState(0);
  const [avgSpeedData, setAvgSpeedData] = useState(0);
  const [idleHoursData, setIdleHoursData] = useState(0);

  const handlefuelEfficiencyData = (data) => {
    setFuelEfficiencyData(data);
  };
  const handlefuelConsumptionData = (data) => {
    setFuelConsumptionData(data);
  };
  const handlemaxSpeedData = (data) => {
    setMaxSpeedData(data);
  };
  const handleavgSpeedData = (data) => {
    setAvgSpeedData(data);
  };
  const handleidleHoursData = (data) => {
    setIdleHoursData(data);
  };

  const getSuffix = (rideName) => {
    const lastDigit = rideName % 10;
    const secondLastDigit = Math.floor(rideName / 10) % 10;

    if (secondLastDigit === 1) {
      return "th";
    } else if (lastDigit === 1) {
      return "st";
    } else if (lastDigit === 2) {
      return "nd";
    } else if (lastDigit === 3) {
      return "rd";
    } else {
      return "th";
    }
  };

  const levelDescription = {
    L0: "L0 - Denotes a ride involving zero distractions and safe vehicle maneuvering throughout the ride. Indicates the driver having very minimum chance to make a crash",
    L1: "L1 - Denotes a ride involving less distractions throughout the ride. Indicates the driver having less chance to make a crash",
    L2: "L2 - Denotes a ride involving moderate level of distractions throughout the ride. Indicates the driver having moderate chance to make a crash",
    L3: "L3 - Denotes a ride involving high distractions throughout the ride. Indicates the driver having high chance to make a crash",
    L4: "L4 - Denotes a ride involving very high distractions throughout the ride. Indicates the driver having very high chance to make a crash",
    L5: "L5 - Denotes a ride involving extremely high distractions throughout the ride. Indicates the driver having extremely high chance to make a crash",
    L6: "L6 - Denotes a ride involving maximum distractions throughout the ride. Indicates the driver having maximum chance to make a crash",
  };

  const levelConfig = {
    L0: { color: "#7ABA78", percentage: 100 },
    L1: { color: "#ff9b9b", percentage: 16.6 },
    L2: { color: "#ff8585", percentage: 33.2 },
    L3: { color: "#ff6e6e", percentage: 49.8 },
    L4: { color: "#ff5757", percentage: 66.4 },
    L5: { color: "#ff4040", percentage: 83 },
    L6: { color: "#ff2929", percentage: 100 },
  };

  const LevelProgressBar = ({ level }) => {
    const { color, percentage } = levelConfig[level] || {};

    return (
      <Progress
        value={percentage}
        // style={{ backgroundColor: '#000', color: color }}
        style={{ height: "25px", "--bs-progress-bar-bg": color }}
      // label={level}
      // variant="none"
      >
        {cppFunction(cppValue).value}
      </Progress>
    );
  };

  const level = cppFunction(cppValue).value;

  let cppFS = "50px";
  if (cppValue > 999) {
    cppFS = "35px";
  } else {
    cppFS = "50px";
  }

  return (
    <React.Fragment>
      {showLoader > 0 && <Loader />}
      <div className="page-content" id="content-to-export">
        <Container fluid>
          <div id="container1">
            <Breadcrumbs title="Ride Details" breadcrumbItem="Ride Details" />
            <Row className="mt-2" style={{marginBottom:"0px"}}>
              <Col sm={6} className="sub_heading">
                {loginType == "ADMIN" || loginType == "SUPER_ADMIN" ? (
                 
                  <h5 className="heading_New_data">{rideNameData}</h5>
                ) : (
                  <h5 className="heading_New_data">{rideNameData}</h5>
                )}
              </Col>
              <Col sm={6}>
                <div className="d-flex justify-content-end">
                <Dropdown isOpen={dropdownOpen} toggle={toggleOpen}>
        <DropdownToggle  style={{
    backgroundColor: "transparent", // Set the background to transparent
    border: "none",                 // Remove border
    padding: 0,                     // Remove padding
    boxShadow: "none"               // Remove any shadow
  }}>
        <img
                                        src={DownloadIcon}
                                        alt="icons2"
                                        style={{
                                          height: "20px",
                                          // marginLeft: "-2px",
                                        }}
                                        // data-tooltip-id={`SUMMARY${indexTable}`}
                                      />
        </DropdownToggle>

        <DropdownMenu>
          {/* Export PDF Option */}
          <DropdownItem
            onClick={() => {
             
              ExportScreenDownloadPdf.generatePDF(containerIds, loginType === "ADMIN" || loginType === "SUPER_ADMIN"
                ? userFirstName + "'s ride detail"
                : userName + " th detail")
            }}
          >
            Export Screen
          </DropdownItem>

          {/* View PDF Option */}
          <DropdownItem
            onClick={() => {
            
              ViewPdf({
                topWidgetData,
                topWidgetDataPdf,
                safetyData,
                drivingStatus,
                dayNightPercentage,
                otherRiskData,
                distributionData,
                driverStateList,
                driverStyleList,
                stressStrainData,
                riskDataList,
                overSpeedTableData,
                mobileUseTableData,
                mobileScreenTableData,
                riskDataTable,
                stressData,
              });
            }}
          >
            Download PDF
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
                </div>
              </Col>
            </Row>

            {/* <Row className="mt-2">
              {(topWidgetData || []).map((widget, key) => (
                <Col xl={2} md={6} key={key}>
                  <Card className="card-h-100">
                    <CardBody>
                      <Row className="align-items-center">
                        <Col xs={12}>
                          <span
                            data-tooltip-id={key + 1}
                            className="text-muted mb-3 d-block text-truncate sub_heading"
                          >
                            {widget.title}
                            <ReactTooltip
                              id={key + 1}
                              place="top"
                              content={widget.title}
                              style={{ background: "black", zIndex: 1 }}
                            />
                          </span>
                          <h4 className="mb-3 sub_heading">
                           
                            <span
                              data-tooltip-id={widget.title}
                              className="counter-value"
                            >
                              <CountUp
                                start={0}
                                end={widget.overallData}
                                decimals={widget.type === "OTHERALERTS" ? 2 : 0}
                                decimal="."
                                duration={1}
                              />
                              {widget.title === "Driving Score" ? (
                                <ReactTooltip
                                  id="Driving Score"
                                  place="top"
                                  content={widget.tooltip}
                                  style={{
                                    background: "black",
                                    fontSize: "12px",
                                    zIndex: 1,
                                  }}
                                />
                              ) : (
                                ""
                              )}
                              <span
                                style={{ color: "#2ab57d", fontSize: "16px" }}
                              >
                                {widget.overallDataPostFix}
                              </span>
                              {widget.postFix}
                            </span>
                          </h4>
                        </Col>
                      </Row>
                      <div className="text-nowrap body_text" style={{ height: '25px' }}>
                        <span>
                          {widget.type === "SCORE" ? (
                            <span data-tooltip-id={`tooltip-cpp-${key}`} style={{ fontSize: "13.5px" }}>
                              <div className="d-flex justify-content-start m-0 p-0">
                                {widget.bottomValue === "Poor" && (
                                  <button className="btn" style={{ backgroundColor: "#E21212", width: "35px", height: "20px" }} />
                                )}
                                {widget.bottomValue === "Fair" && (
                                  <button className="btn" style={{ backgroundColor: "#FFBF00", width: "35px", height: "20px" }} />
                                )}
                                {widget.bottomValue === "Good" && (
                                  <button className="btn" style={{ backgroundColor: "#19543E", width: "35px", height: "20px" }} />
                                )}
                                <p className="mb-0 ms-2" style={{ fontSize: "14px" }}>{widget.bottomValue}</p>
                              </div>
                            </span>
                          ) : (
                            <span data-tooltip-id={`tooltip-cpp-${key}`} style={{ fontSize: "13.5px" }}>
                              {widget.bottomValue}
                            </span>
                          )}
                        </span>
                        {widget.type === "RISKHISTOGRAM" ? (
                          <ReactTooltip
                            id={`tooltip-cpp-${key}`}
                            place="top"
                            content={"Crash Probability Point Level"}
                            style={{
                              background: "black",
                              fontSize: "12px",
                              zIndex: 1,
                            }}
                          />
                        ) : null}
                        <span className="ms-1 text-muted font-size-13"></span>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            </Row> */}

            {/* <TimeBatteryEventJourneyView timeBatteryEventJourneyViewObj={timeBatteryEventJourneyViewObj} /> */}

            {/* <FuelSpeedData fuelEfficiencyData={fuelEfficiencyData} fuelConsumptionData={fuelConsumptionData}
            maxSpeedData={maxSpeedData} avgSpeedData={avgSpeedData} idleHoursData={idleHoursData}/> */}

            {/* <CarSimulation/> */}
            <Row style={{marginTop:"-10px"}}>
              <Col xs={12}>
              <CardBody style={{ padding: "0px" }}>
                {/* <Card className="border-0"> */}
                  {/* <CardHeader
                    className="cardHeader"
                    style={{ paddingLeft: "12px" }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <h5 className="sub_heading_new m-0">Driving Score</h5>
                        <ReactTooltip
                          id={"info"}
                          place="top"
                       
                          multiline={true}
                          style={{
                            backgroundColor: "black",
                            height: "auto",
                            width: "40vw",
                            display: "flex",
                            alignItems: "center",
                            fontSize: "14px",
                            padding: "20px",
                          }}
                        />
                      </div>

                   
                    </div>
                  </CardHeader> */}
                  {/* <CardBody className="p-0"> */}
                    <div
                      className="mb-4"
                      // style={{ height: 525, overflowY: "auto" }}
                    >
                      <div
                        style={{
                          height: "auto",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "14px",
                          padding: "10px",
                        }}
                      >
                        {/* {displayText.DRIVER_BEHAVIOUR_DESCRIPTION} */}
                      </div>
                      <Table bordered style={TableStyle.table}>
                        <thead>
                          {/* <tr>
                                                        
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Rating</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Driving<br />Score</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Ride <br />Name</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="4">Driving Risk Data</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="7">Driving Behavior Data</th>
                                                      <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Travel<br />Period</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Battery %</th>
                                                        <th className="text-nowrap cardHeaderForDBRDFVNew" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "top" }} colSpan="1">Event</th>
                                                    </tr> */}
                          <tr>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Ride<br/>Details
                              {/* <br/><br/><img src={i3} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving<br/> Score
                              {/* <br/><br/><img src={UBIScoreImage} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} />  */}
                            </th>
                            {/* <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br/><br/><br/><img src={ride_name} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} /> </th> */}
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving<br/>Risk
                              {/* <br /><img src={category_image} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} />  */}
                            </th>

                            {/* <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} >{" "}<br />Driving <br />Points<br /><img src={UBIDrivingScore} alt="UBIDrivingScore" style={{ marginTop: "5px", height: "20px" }} /></th> */}
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving Risk
                              <br />
                              Data
                              {/* <img src={UBIAnticipation} alt="UBIAnticipation" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            {/* <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br/>Self <br />Confidence <br /><img src={UBISelfConfidence} alt="UBISelfConfidence" style={{ marginTop: "5px", height: "20px" }} /></th>
                                                        <th className="text-nowrap cardHeaderForDBRDFV" style={{ ...TableStyle.th, textAlign: "center", verticalAlign: "middle" }} ><br/>Driving <br />Skill <br /><img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /></th> */}
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving <br />
                              Behaviour
                              {/* <img src={UBIDrivingSkill} alt="UBIDrivingSkill" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Driving Behaviour <br />
                              Data
                              {/* <img src={UBIDrivingStyle} alt="UBIDrivingStyle" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Crash <br />
                              Probability
                              {/* <img src={UBIDrivingState} alt="UBIDrivingState" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                           
                           
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Acc/Brak/Cor
                              <br />
                              Counts
                              {/* <img src={UBIKmsDriven} alt="UBIKmsDriven" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Over Speed
                              <br />
                              Data
                              {/* <img src={UBIStressStrain} alt="UBIStressStrain" style={{ marginTop: "5px", height: "23px" }} />  */}
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Mobile
                              <br />
                              Usage
                              {/* <img src={UBIMobileCallUse} alt="UBIMobileCallUse" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Red Risk <br />
                              Data
                              {/* <img src={UBIMobileScreenUse} alt="UBIMobileScreenUse" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>

                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Others
                              {/* <img src={clock} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                            <th
                              className="text-nowrap cardHeaderForDBRDFV"
                              style={{
                                ...TableStyle.th,
                                textAlign: "center",
                                verticalAlign: "middle",
                              }}
                            >
                              Context
                              {/* <img src={clock} alt="icons1" style={{ marginTop: "5px", height: "20px" }} /> */}
                            </th>
                          </tr>
                        </thead>
                        {/* {dataLength > 0 && ( */}
                          <tbody>
                            {dataLength > 0 ? (
                            (apiData || []).map((apiData, indexTable) => (
                              <tr key={indexTable}>
                                <td
                                  style={{
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                 <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                  {/* <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={UBIInfoIcon}
                                        alt="icons2"
                                        style={{
                                          height: "20px",
                                          marginLeft: "-2px",
                                        }}
                                        data-tooltip-id={`SUMMARY${indexTable}`}
                                      />
                                      <ReactTooltip
                                      id={`SUMMARY${indexTable}`}
                                      place="top"
                                      content={apiData.summaryData}
                                      style={{ backgroundColor: "black" }}
                                    />
                                    </div> */} 
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "12px",
                                        fontSize: "11px",
                                      }}
                                    >
                                  <Link
                                    // to={`/ridedetails?userId=${encodeURIComponent(
                                    //   Converter.encrypt(apiData.userId)
                                    // )}&divisionId=${encodeURIComponent(
                                    //   Converter.encrypt(apiData.divisionId)
                                    // )}&rideId=${encodeURIComponent(
                                    //   Converter.encrypt(apiData.rideId)
                                    // )}&rideCategory=${encodeURIComponent(
                                    //   Converter.encrypt(apiData.rideCategory)
                                    // )}`}
                                  >
                                    <p
                                      style={{
                                        fontSize: "11px",
                                        color: "#5156BE",
                                        marginBottom: "0px",
                                        fontWeight: 700,
                                        cursor:"pointer"
                                      }}
                                    >{`${apiData.rideName}`}</p>
                                  </Link>
                                  </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.tKiloMeter + " Kms"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.tTravelTime + " Mins"}
                                    </div>
                                  </div>
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={steeringWheel}
                                        alt="icons2"
                                        style={{ height: "20px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "10px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.calculatedDrivingScore >= 0 &&
                                      apiData.calculatedDrivingScore <= 80
                                        ? "Poor"
                                        : apiData.calculatedDrivingScore >=
                                            81 &&
                                          apiData.calculatedDrivingScore <= 93
                                        ? "Fair"
                                        : "Good"}
                                    </div>
                                  </div> */}
                                </td>
                                {/* <td style={{ textAlign: "center", verticalAlign: "middle" }}>{apiData.calculatedDrivingScore >= 0 && apiData.calculatedDrivingScore <= 80 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Poor</p><button className="btn" style={{ backgroundColor: '#E21212' }} /></div> :
                                                                    apiData.calculatedDrivingScore >= 81 && apiData.calculatedDrivingScore <= 93 ? <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Fair</p><button className="btn" style={{ backgroundColor: '#FFBF00' }} /></div> :
                                                                        <div className="d-flex flex-column justify-content-center align-items-center"><p className="mb-0" style={{ fontSize: '10px' }}>Good</p><button className="btn" style={{ backgroundColor: '#19543E' }} /></div>}
                                                                </td> */}
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                  // data-tooltip-id={`ETVN${indexTable}`}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={apiData.calculatedDrivingScore}
                                        text={`${apiData.calculatedDrivingScore}%`}
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "27px",
                                          fontWeight: 700,
                                          pathColor:
                                            apiData.calculatedDrivingScore >=
                                              0 &&
                                            apiData.calculatedDrivingScore <= 80
                                              ? "#E21212"
                                              : apiData.calculatedDrivingScore >=
                                                  81 &&
                                                apiData.calculatedDrivingScore <=
                                                  93
                                              ? "#FFBF00"
                                              : "#19543e",
                                          trailColor: "#0095FF",
                                          textColor: "#5156BE",
                                        })}
                                        className="progress-bar-rideList"
                                      ></CircularProgressbar>
                                    </div>
                                    {/* <ReactTooltip
                                      id={`ETVN${indexTable}`}
                                      place="top"
                                      content={apiData.totalEfficiencyTVNew}
                                      style={{ backgroundColor: "black" }}
                                    /> */}
                                    
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                     justifyContent:"center",
                                     marginTop:"20px"
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={steeringWheel}
                                        alt="icons2"
                                        style={{ height: "20px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",                                        
                                        fontSize: "11px",
                                        marginLeft:"5px"
                                        
                                      }}
                                    >
                                      {apiData.drivingCategory}
                                    </div>
                                  </div>
                                </td>
                                {/* <td className="lightBlueColor text-nowrap" style={{ textAlign: "center", verticalAlign: "middle" }}>
                                                                    <Link
                                                                        to={`/ridedetails?userId=${encodeURIComponent(Converter.encrypt(apiData.userId))}&divisionId=${encodeURIComponent(Converter.encrypt(apiData.divisionId))}&rideId=${encodeURIComponent(Converter.encrypt(apiData.rideId))}&rideCategory=${encodeURIComponent(Converter.encrypt(apiData.rideCategory))}`}>
                                                                        <span
                                                                            data-tooltip-id={`rideTooltip_${indexTable}`}
                                                                            style={{ fontSize: "14px" }}
                                                                        >
                                                                            {apiData.rideName}
                                                                        </span>
                                                                    </Link>
                                                                    <ReactTooltip
                                                                        id={`rideTooltip_${indexTable}`}
                                                                        place="top"
                                                                        effect="solid"
                                                                        backgroundColor="black"
                                                                        content={apiData.StatusTemp}
                                                                    />
                                                                </td> */}
                                {/* <td className="lightBlueColor text-nowrap" data-tooltip-id={`categoryTooltip_${indexTable}`} style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}>{`${apiData.categoryTooltip}`} */}
                                {/* <ReactTooltip
                                                                        id={`categoryTooltip_${indexTable}`}
                                                                        place="top"
                                                                        effect="solid"
                                                                        backgroundColor="black"
                                                                        content={apiData.categoryTooltip}
                                                                    /> */}
                                {/* </td> */}
                                <td
                                  className="text-nowrap"
                                  data-tooltip-id={`categoryTooltip_${indexTable}`}
                                  style={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    borderRight: "0px",
                                    fontWeight: 700,
                                  }}
                                  dangerouslySetInnerHTML={{
                                    __html: `${ValueFormat.formatDecimalIfRounded(
                                      apiData.drivingScore
                                    )}`,
                                  }} // This will render the <br/> as an actual line break
                                />
                                {/* <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{"-"}</div></td> */}
                                {/* <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{"-"}</div></td> */}
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderLeft: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIAnticipation}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Anticipation
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.overallAnticipation
                                      )}`}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBISelfConfidence}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "7px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Self Confidence
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallSelfConfidence
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingSkill}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving Skill
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.overallDrivingSkill
                                      )}
                                    </div>
                                  </div>
                                </td>
                                {/* <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallSelfConfidence)}`}</div></td>
                                                                <td style={{ textAlign: "center", fontSize: "14px", verticalAlign: "middle" }}><div>{`${ValueFormat.formatDecimalIfRounded(apiData.overallDrivingSkill)} `}</div></td> */}
                                <td
                                  style={{
                                    textAlign: "center",
                                    fontSize: "13px",
                                    verticalAlign: "middle",
                                    borderRight: "0px",
                                    fontWeight: 700,
                                    fontSize: "11px",
                                  }}
                                >
                                  <div style={{fontSize: "13px"}}>{`${apiData.drivingBehaviourScore}`}</div>
                                </td>
                                <td
                                  className="text-nowrap"
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    borderLeft: "0px",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingStyle}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving Style
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {`${ValueFormat.formatDecimalIfRounded(
                                        apiData.DSTP
                                      )}`}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIDrivingState}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Driving State
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.DSP
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIMobileCallUse}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "9px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Mobile Usage
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.mobileUsage}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Over Speed
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.totalKmSPoint
                                      )}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={UBIStressStrain}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "10px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Acc/Brak/Cor
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {ValueFormat.formatDecimalIfRounded(
                                        apiData.ABCPoint
                                      )}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  className="text-nowrap"
                                  style={{
                                    textAlign: "left",
                                    // fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div
                                      style={{
                                        height: 60,
                                        width: 60,
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <CircularProgressbar
                                        value={apiData.cppLevel}
                                        text={`${apiData.cppLevel}`}
                                        styles={buildStyles({
                                          width: "10px",
                                          textSize: "27px",
                                          fontWeight: 700,
                                          pathColor:apiData.cppLevelColor,                                          
                                          trailColor: "#0095FF",
                                          textColor: "#5156BE",
                                        })}
                                        className="progress-bar-rideList"
                                      ></CircularProgressbar>
                                    </div>
                                    
                                   
                                  </div>
                                  <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                  <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"15px",
                                        marginBottom:"0px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>{"CPP - "}{Math.round(apiData?.cpp)}</p>

                                    </div>
                                    </div>
                                  <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                      
                                      <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"-5px",
                                        marginBottom:"0px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>L0 is Safe</p>

                                    </div>
                                    
                                      
                                    </div>
                                    <div style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}>
                                    <div
                                      style={{
                                       
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginTop:"-5px"
                                      }}
                                    ><p style={{fontSize: "11px"}}>L6 is Unsafe</p>

                                    </div>
                                    </div>
                                  {/* <div>
                                    Level{" - "}
                                    {apiData.cppLevel}
                                  </div> */}
                                  {/* <div style={{ marginTop: "15px" }}>
                                    Point(s){" - "}
                                    {apiData.crashProbabilityScore || 0}
                                  </div> */}
                                </td>
                                
                               
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={AccelerationIcon}
                                          alt="totalAccelerationCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Acc
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalAccelerationCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={BreakingIcon}
                                          alt="totalBrakingCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Brak
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalBrakingCount}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <img
                                          src={CorneringIcon}
                                          alt="totalCorneringCount"
                                          style={{ height: "25px" }}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            marginLeft: "8px",
                                            fontSize: "11px",
                                          }}
                                        >
                                          Cor
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {" - "}
                                      {apiData.totalCorneringCount}
                                    </div>
                                  </div>
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      
                                      {apiData.totalOverSpeedCount}{" "}{"Count"}
                                    </div>
                                  </div>           

                                
                                  
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalOverSpeedDuration}{" "}{"Mins"}
                                    </div>
                                  </div>
                                  {/* <div style={{ marginTop: "15px" }}>
                                    Duration{" - "}
                                    
                                  </div> */}
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                  }}
                                >
                                   <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={ScreenCountIcon}
                                        alt="icons2"
                                        style={{ height: "24px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileScreenScreenOnCount}{" "}{"Count"}
                                    </div>
                                    </div>

                                    <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileScreenScreenOnDuration}{" "}{"Mins"}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CallCountIconNewOne}
                                        alt="icons2"
                                        style={{ height: "26px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileUseInAcceptedCount}{" "}{"Count"}
                                    </div>
                                  </div>

                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "2px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.totalMobileUseInAcceptedCount}{" "}{"Mins"}
                                    </div>
                                  </div>



                                  


                                  {/* <div style={{ display: "flex" }}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={ScreenCountIcon}
                                        alt="icons2"
                                        style={{ height: "24px" }}
                                      />
                                    </div>
                                    <div style={{ marginLeft: "7px",fontSize: "11px", }}>
                                     {" "}
                                      {apiData.totalMobileScreenScreenOnCount}
                                      <br />
                                      {" "}
                                      {
                                        apiData.totalMobileScreenScreenOnDuration
                                       }{" "}{"Mins"}
                                    </div>
                                  </div> */}
                                  {/* <div
                                    style={{
                                      display: "flex",
                                      marginTop: "15px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CallCountIcon}
                                        alt="icons2"
                                        style={{ height: "26px" }}
                                      />
                                    </div>
                                    <div style={{ marginLeft: "7px" ,fontSize: "11px",}}>
                                      {" "}
                                      {apiData.totalMobileUseInAcceptedDuration}
                                      <br />
                                     {" "}
                                      {apiData.totalMobileUseInAcceptedCount}{" "}{"Mins"}
                                    </div>
                                  </div> */}
                                </td>
                                <td
                                  style={{
                                    textAlign: "left",
                                    fontSize: "10px",
                                    verticalAlign: "middle",
                                    fontSize: "11px",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      // marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "5px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.riskSlot10count}{" "}{"Count"}
                                    </div>
                                  </div>           

                                  
                                  
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                          marginLeft: "-2px",
                                        }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                      {apiData.riskSlot10Distance}{" "}{"Met"}
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: "flex",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                      }}
                                    >
                                      <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px",marginLeft: "-4px" }}
                                      />
                                    </div>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        flexDirection: "column",
                                        marginLeft: "8px",
                                        fontSize: "11px",
                                      }}
                                    >
                                     {apiData.riskSlot10Duration}{" "}{"Sec"}
                                    </div>
                                  </div>
                                  {/* <div style={{ marginTop: "15px" }}>
                                    Medium{" - "}
                                    {apiData.totalMediumRiskCount}
                                  </div> */}
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                  <div style={{display:"flex",marginBottom:"0px"}}>
                                    <div>
                                  <img
                                    data-tooltip-id={`time_${indexTable}`}
                                    data-tooltip-html={`Start Time: ${apiData.startTime} <br/> End Time: ${apiData.endTime}`}
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                  />
                                  </div>
                                  <div style={{display:"flex",justifyContent:"center",flexDirection:"column",fontSize:"11px"}}>RD</div>
                                          </div>
                                 <div style={{display:"flex",marginTop: "10px",marginBottom:"0px"}}>
                                 <div>
                                  <img
                                    data-tooltip-id={`battery_${indexTable}`}
                                    data-tooltip-html={`Battery Consumption | ${apiData.batterylevelTotalValue}%`}
                                    src={battery}
                                    alt="icons1"
                                    style={{                                     
                                      height: "25px",
                                    }}
                                  />
                                  </div>
                                  <div style={{display:"flex",justifyContent:"center",flexDirection:"column",fontSize:"11px"}}>BC</div>
                                  </div>
                                  <div style={{display:"flex",marginTop: "10px",marginBottom:"0px"}}>
                                    <div>
                                  <Link
                                    to="/journeyView"
                                    state={{
                                      userId: apiData.userId,
                                      divisionId: apiData.divisionId,
                                      rideId: apiData.rideId,
                                      rideNo: apiData.rideNo,
                                    }}
                                    className="m-0 p-0"
                                  >
                                  <img
                                    src={DetailViewd}
                                    alt="icons1"
                                    style={{
                                      // marginTop: "15px",
                                      height: "25px",
                                      cursor:"pointer"
                                    }}
                                  />
                                  </Link>
                                  </div>
                                  <div style={{display:"flex",justifyContent:"center",flexDirection:"column",fontSize:"11px",marginLeft:"2px"}}>JV</div>
                                  </div>
                                    
                                  {/* <img
                                    data-tooltip-id={`event_${indexTable}`}
                                    src={BatteryCosumIcon}
                                    alt="icons1"
                                    style={{
                                      marginTop: "15px",
                                      height: "25px",
                                    }}
                                  /> */}
                                 <div>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginTop: "10px",
                                          }}
                                        >
                                          {apiData.event}
                                        </p>

                                 </div>
                                  
                                 
                                  <ReactTooltip
                                    id={`time_${indexTable}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    multiline={true}
                                    style={{ textAlign: "left" }}
                                  />
                                  <ReactTooltip
                                    id={`event_${indexTable}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    content={apiData.longEvent}
                                  />
                                  <ReactTooltip
                                    id={`battery_${indexTable}`}
                                    place="top"
                                    effect="solid"
                                    backgroundColor="black"
                                    multiline={true}
                                    style={{ textAlign: "left" }}
                                  />
                                </td>
                                <td
                                  style={{
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                  }}
                                >
                                 
                                  <div style={{display:"flex"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            fontWeight:700
                                            // marginTop: "10px",
                                          }}
                                        >
                                          U{" - "}
                                        </p>
                                        
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.urbanKilometers}{" "}{"Kms"}
                                        </p>

                                 </div>
                                  <div style={{display:"flex",marginTop: "10px"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            // marginTop: "10px",
                                            fontWeight:700
                                          }}
                                        >
                                          R{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.ruralKilometers}{" "}{"Kms"}
                                        </p>

                                 </div>
                                  
                                 <div style={{display:"flex",marginTop: "10px"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",                                            
                                            fontWeight:700
                                          }}
                                        >
                                          H{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.highwayKilometers}{" "}{"Kms"}
                                        </p>

                                 </div>

                                 <div style={{display:"flex",marginTop: "10px"}}>
                                 <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",                                            
                                            fontWeight:700
                                          }}
                                        >
                                          D{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.day}{" "}{"%"}
                                        </p>

                                 </div>         
                                 <div style={{display:"flex",marginTop: "10px"}}>
                                 
                                  <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",                                            
                                            fontWeight:700
                                          }}
                                        >
                                          N{" - "}
                                        </p>
                                        <p
                                          style={{                                            
                                            fontSize: "11px",
                                            marginBottom:"0px",
                                            marginLeft:"3px"
                                           
                                          }}
                                        >
                                        {apiData.night}{" "}{"%"}
                                        </p>

                                 </div>                                  
                                 
                                  
                                </td>
                              </tr>
                            ))) : dataLength === 0 ? (
                              <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "black",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.DATA_NOT_AVAILABLE}
                                </div>
                              </td>
                            </tr>

                            ) : dataLength === null ? (
                              <tr>
                              {" "}
                              <td colSpan="13">
                                <div
                                  style={{
                                    color: "green",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    height: "100px",
                                    width: "100%",
                                  }}
                                >
                                  {displayText.FETCHING_DATA}
                                </div>
                              </td>
                            </tr>

                            ) : ""}
                          </tbody>
                        {/* )} */}
                      </Table>

                      {/* {dataLength === 0 && (
                        <p
                          className="data_not_available"
                          style={{
                            height: 300,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      )} */}
                    </div>
                  {/* </CardBody> */}
                {/* </Card> */}

                
              </CardBody>
              </Col>
            </Row>
            {/* <Row>
              <Col xl={3}>
                <Card style={{ height: 280 }}>
                  <CardHeader className="cardHeader">
                    <h4 className="sub_heading">Ride Summary</h4>
                  </CardHeader>
                  <CardBody
                    style={{
                      height: 230,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <span>{summary}</span>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={5}>
                <Card style={{ height: 280 }}>
                  <CardHeader className="cardHeader">
                    <h4 className="sub_heading">Seatbelt / Helmet</h4>
                  </CardHeader>
                  <CardBody
                    style={{
                      height: 230,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Row style={{ padding: "0px", width: "100%" }}>
                      <Col
                        xs={2}
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                          borderRight: "1px solid #000",
                        }}
                      >
                        <img
                          src={require("../../assets/images/safetyProtection/" +
                            safetyData.subImage)}
                          style={{ height: "40px" }}
                        />
                      </Col>
                      <Col
                        xs={10}
                        style={{
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <p style={{ color: "black", margin: "0px" }}>
                          {safetyData.text}
                        </p>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={4}>
                <Card style={{ height: 280 }}>
                  <CardHeader className="cardHeader">
                    <h4 className="sub_heading">Crash Probability Point</h4>
                  </CardHeader>
                  <CardBody
                    style={{
                      height: 230,
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <Row style={{ padding: "0px", width: "100%" }}>
                      <Col xs={8} className="d-flex align-items-center">
                        {cppFunction(cppValue).value === "L0" ? (
                          <p className="mb-0" style={{ color: "black" }}>
                            {levelDescription.L0}
                          </p>
                        ) : null}
                        {cppFunction(cppValue).value === "L1" ? (
                          <p className="mb-0" style={{ color: "black" }}>
                            {levelDescription.L1}
                          </p>
                        ) : null}
                        {cppFunction(cppValue).value === "L2" ? (
                          <p className="mb-0" style={{ color: "black" }}>
                            {levelDescription.L2}
                          </p>
                        ) : null}
                        {cppFunction(cppValue).value === "L3" ? (
                          <p className="mb-0" style={{ color: "black" }}>
                            {levelDescription.L3}
                          </p>
                        ) : null}
                        {cppFunction(cppValue).value === "L4" ? (
                          <p className="mb-0" style={{ color: "black" }}>
                            {levelDescription.L4}
                          </p>
                        ) : null}
                        {cppFunction(cppValue).value === "L5" ? (
                          <p className="mb-0" style={{ color: "black" }}>
                            {levelDescription.L5}
                          </p>
                        ) : null}
                        {cppFunction(cppValue).value === "L6" ? (
                          <p className="mb-0" style={{ color: "black" }}>
                            {levelDescription.L6}
                          </p>
                        ) : null}
                      </Col>
                      {cppValue == "-" ? (
                        <Col
                          xs={4}
                          style={{
                            justifyContent: "center",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <h5
                            className="mb-0"
                            style={{
                              fontSize: "25px",
                              fontWeight: 800,
                              textAlign: "center",
                            }}
                          >
                            {" "}
                            No point available{" "}
                          </h5>
                        </Col>
                      ) : (
                        <>
                          <Col
                            xs={4}
                            style={{
                              // justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                              borderLeft: "1px solid #000",
                            }}
                          >
                            <div style={{ width: "100%" }}>
                              <h5
                                className="mb-0"
                                style={{ fontSize: cppFS, fontWeight: 800 }}
                              >
                                {Math.round(cppValue)}
                              </h5>
                              <LevelProgressBar level={level} />
                              <p
                                style={{
                                  color: "black",
                                  margin: "0px",
                                  fontSize: "12px",
                                }}
                              >
                                {" "}
                                {cppFunction(cppValue).levelDescription}{" "}
                              </p>
                            </div>
                          </Col>
                        </>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}

            {/* <Row>
              <Col xl={4} lg={6}>
                <Card style={{ height: 480 }}>
                  <CardHeader className="cardHeader">
                    <h4 className="sub_heading">Driving Points</h4>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xl={12}>
                        <div id="gauge-chart" className="e-chart">
                          <Gauge drivingStatus={drivingStatus} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={5} md={12} lg={6}>
                <Card style={{ height: 480 }}>
                  <CardHeader className="cardHeader">
                    <h4 className="sub_heading">Driving Attributes</h4>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xl={12}>
                        <Row>
                          <Col xl={6} md={6}>
                            <div style={{ width: "200px" }}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                Anticipation Points
                              </span>
                            </div>
                            <div
                              className="col-sm-6 col-lg-3"
                              style={{ width: "200px", height: "185px" }}
                            >
                              <Guages drivingStatus={drivingStatus} />
                            </div>
                          </Col>
                          <Col xl={6} md={6}>
                            <div style={{ width: "200px" }}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                Driving Skill Points
                              </span>
                            </div>
                            <div
                              className="col-sm-6 col-lg-3 xl-3 p-"
                              style={{ width: "200px", height: "185px" }}
                            >
                              <GuagesTwo drivingStatus={drivingStatus} />
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col xl={6} md={6}>
                            <div style={{ width: "200px" }}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                Self Confidence Points
                              </span>
                            </div>
                            <div
                              className="col-sm-6 col-lg-3"
                              style={{ width: "200px", height: "170px" }}
                            >
                              <GuagesThree drivingStatus={drivingStatus} />
                            </div>
                          </Col>
                          <Col xl={6} md={6}>
                            <div style={{ width: "200px" }}>
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  fontSize: "16px",
                                  fontWeight: 500,
                                }}
                              >
                                Kilometers Driven
                              </span>
                            </div>
                            <div
                              className="col-sm-6 col-lg-3 xl-3 p-"
                              style={{ width: "200px", height: "170px" }}
                            >
                              <GuagesNumber drivingStatus={drivingStatus} />
                            </div>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} md={12}>
                <Card style={{ height: 480 }}>
                  <CardBody>
                    <Row>
                      <Col xl={12} lg={6}>
                        <div id="doughnut-chart" className="e-chart">
                          <Doughnut urhData={dayNightPercentage} />
                        </div>
                      </Col>
                      <Col xl={12} lg={6}>
                        <div id="doughnut-chart" className="e-chart">
                          <Doughnuts dayNightPercentage={dayNightPercentage} />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}

            {/* <br /> */}
            <Row style={{marginTop:"-15px"}}  >
              {/* <CSVRideData  redRiskDistanceDatas={handleRedRiskDistanceData}/> */}
             
             {/* <Col xl={9} > */}
             <SpeedandRiskViolation
                redRiskDistanceDataas={handleRedRiskDistanceData}
                handlefuelEfficiencyData={handlefuelEfficiencyData}
                handlefuelConsumptionData={handlefuelConsumptionData}
                handlemaxSpeedData={handlemaxSpeedData}
                handleavgSpeedData={handleavgSpeedData}
                handleidleHoursData={handleidleHoursData}
              />
             {/* </Col> */}
              
              <Col xl={3} >
                <Card style={{ height: 410}}>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title" >Risk Histogram</h4>
                  </CardHeader>
                  {histogramData === true ? (
                    <CardBody style={{padding:"2px"}}>
                      <LineColumnAreaTwo DistributionData={distributionData} />
                    </CardBody>
                  ) : histogramData === false ? (
                    <CardBody>
                      <p className="data_not_available">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                      <LineColumnAreaTwo DistributionData={distributionData} />
                    </CardBody>
                  ) : histogramData === "failed" ? (
                    <CardBody>
                      <p className="data_not_available">{displayText.ER_1206}</p>
                      <LineColumnAreaTwo DistributionData={distributionData} />
                    </CardBody>
                  ) : (
                    <CardBody>
                      <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                      <LineColumnAreaTwo DistributionData={distributionData} />
                    </CardBody>
                  )}
                </Card>
              </Col>
              
            </Row>
            <Row style={{marginTop:"-10px"}}>
              <Col xl={8}>
                <Card style={{ height: 480 }}>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title">Map View - Risk Alert</h4>
                    {/* <button id="Capture">Export Map</button> */}
                  </CardHeader>
                  <CardBody>
                    {mapDisplay === null ? (
                      <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                    ) : mapDisplay === false ? (
                      <p className="data_not_available">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                    ) : null}
                    <Row>
                      <Col
                        style={{
                          height: "400px",
                          position: "relative",
                          overflow: "hidden",
                        }}
                        xl={12}
                        id="mapContainerDisplay"
                      />

                      <Col
                        style={{
                          height: "400px",
                          position: "relative",
                          overflow: "hidden",
                          display: "none",
                        }}
                        xl={12}
                        id="panel"
                      />
                    </Row>

                    {/* <HereMap mapData={mapData}/>*/}
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4}>
                <Card style={{ height: 480 }}>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title">
                      Risk Alerts
                      <span style={{ fontSize: "11px", fontWeight: 400 }}>
                        {" "}
                        (Total Alerts - {alertCount})
                      </span>
                    </h4>
                  </CardHeader>
                  {riskAlertsDataDisplay == "failed" ? (
                    <CardBody>
                      <Table className="table table-hover mb-0">
                        <thead className="sticky-header">
                        <tr>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={TripIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertTripData00111111909090"
                                        />
                                        <ReactTooltip
                                id="riskAlertTripData00111111909090"
                                place="top"
                                content="Trip"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                             <img
                                          src={alertIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertAlertData0001010101010199"
                                        />
                                        <ReactTooltip
                                id="riskAlertAlertData0001010101010199"
                                place="top"
                                content="Alert"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                             <img
                                          src={clock}
                                          alt="icons2"
                                          style={{ height: "25px" }}

                                          data-tooltip-id="riskAlertclockData00011313131414"
                                        />
                                        <ReactTooltip
                                id="riskAlertclockData00011313131414"
                                place="top"
                                content="HH:MM:SS"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                               <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="riskAlertLocationData01112122323"
                                      />
                                      <ReactTooltip
                                id="riskAlertLocationData01112122323"
                                place="top"
                                content="Kms"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            {/* <th>
                  Set Speed
                  <br />
                  <span style={{ fontSize: "12px", fontWeight: 400 }}>
                  (km/h)  
                  </span>
                </th> */}
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                              data-tooltip-id="pointOfinterest110101"
                            >
                              <img
                                          src={poiIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                
                              <ReactTooltip
                                id="pointOfinterest110101"
                                place="top"
                                content="Meters"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                              <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertSpeedData022020202"
                                        />
                                         <ReactTooltip
                                id="riskAlertSpeedData022020202"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                              
                            </th>

                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={zipCodeIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertZipCode033030303"
                                        />
                                         <ReactTooltip
                                id="riskAlertZipCode033030303"
                                place="top"
                                content="Zipcode"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                      </Table>
                      <p className="data_not_available">{displayText.ER_1206}</p>
                    </CardBody>
                  ) : riskAlertsDataDisplay == false ? (
                    <CardBody>
                      <Table className="table table-hover mb-0">
                        <thead className="sticky-header">
                        <tr>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={TripIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertTripData00111111"
                                        />
                                        <ReactTooltip
                                id="riskAlertTripData00111111"
                                place="top"
                                content="Trip"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                             <img
                                          src={alertIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertAlertData0001010101010"
                                        />
                                        <ReactTooltip
                                id="riskAlertAlertData0001010101010"
                                place="top"
                                content="Alert"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                             <img
                                          src={clock}
                                          alt="icons2"
                                          style={{ height: "25px" }}

                                          data-tooltip-id="riskAlertclockData0001131313"
                                        />
                                        <ReactTooltip
                                id="riskAlertclockData0001131313"
                                place="top"
                                content="HH:MM:SS"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                               <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="riskAlertLocationData0111212"
                                      />
                                      <ReactTooltip
                                id="riskAlertLocationData0111212"
                                place="top"
                                content="Kms"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            {/* <th>
                  Set Speed
                  <br />
                  <span style={{ fontSize: "12px", fontWeight: 400 }}>
                  (km/h)  
                  </span>
                </th> */}
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                              data-tooltip-id="pointOfinterest1101"
                            >
                              <img
                                          src={poiIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                
                              <ReactTooltip
                                id="pointOfinterest1101"
                                place="top"
                                content="Meters"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                              <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertSpeedData02202"
                                        />
                                         <ReactTooltip
                                id="riskAlertSpeedData02202"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                              
                            </th>

                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={zipCodeIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertZipCode03303"
                                        />
                                         <ReactTooltip
                                id="riskAlertZipCode03303"
                                place="top"
                                content="Zipcode"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                      </Table>
                      <p className="data_not_available">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                    </CardBody>
                  ) : riskAlertsDataDisplay == null ? (
                    <CardBody>
                      <Table className="table table-hover mb-0">
                        <thead className="sticky-header">
                        <tr>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={TripIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertTripData00111"
                                        />
                                        <ReactTooltip
                                id="riskAlertTripData00111"
                                place="top"
                                content="Trip"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                             <img
                                          src={alertIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertAlertData0001010"
                                        />
                                        <ReactTooltip
                                id="riskAlertAlertData0001010"
                                place="top"
                                content="Alert"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                             <img
                                          src={clock}
                                          alt="icons2"
                                          style={{ height: "25px" }}

                                          data-tooltip-id="riskAlertclockData0001"
                                        />
                                        <ReactTooltip
                                id="riskAlertclockData0001"
                                place="top"
                                content="HH:MM:SS"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                               <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="riskAlertLocationData011"
                                      />
                                      <ReactTooltip
                                id="riskAlertLocationData011"
                                place="top"
                                content="Kms"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            {/* <th>
                  Set Speed
                  <br />
                  <span style={{ fontSize: "12px", fontWeight: 400 }}>
                  (km/h)  
                  </span>
                </th> */}
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                              data-tooltip-id="pointOfinterest11"
                            >
                              <img
                                          src={poiIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                
                              <ReactTooltip
                                id="pointOfinterest11"
                                place="top"
                                content="Meters"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                              <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertSpeedData022"
                                        />
                                         <ReactTooltip
                                id="riskAlertSpeedData022"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                              
                            </th>

                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={zipCodeIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertZipCode033"
                                        />
                                         <ReactTooltip
                                id="riskAlertZipCode033"
                                place="top"
                                content="Zipcode"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                      </Table>
                      <p className="fetch_data" style={{ height: "350px" }}>
                        {displayText.FETCHING_DATA}
                      </p>
                    </CardBody>
                  ) : (
                    <CardBody>
                      <div
                        className="table-responsive"
                        style={{ height: "380px" }}
                      >
                        <Table className="table table-hover mb-0">
                          <thead className="sticky-header">
                          <tr>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={TripIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertTripData001"
                                        />
                                        <ReactTooltip
                                id="riskAlertTripData001"
                                place="top"
                                content="Trip"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                             <img
                                          src={alertIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertAlertData000"
                                        />
                                        <ReactTooltip
                                id="riskAlertAlertData000"
                                place="top"
                                content="Alert"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                             <img
                                          src={clock}
                                          alt="icons2"
                                          style={{ height: "25px" }}

                                          data-tooltip-id="riskAlertclockData00"
                                        />
                                        <ReactTooltip
                                id="riskAlertclockData00"
                                place="top"
                                content="HH:MM:SS"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                               <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="riskAlertLocationData01"
                                      />
                                      <ReactTooltip
                                id="riskAlertLocationData01"
                                place="top"
                                content="Kms"
                                style={{ background: "black" }}
                              />
                             
                            </th>
                            {/* <th>
                  Set Speed
                  <br />
                  <span style={{ fontSize: "12px", fontWeight: 400 }}>
                  (km/h)  
                  </span>
                </th> */}
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                              data-tooltip-id="pointOfinterest1"
                            >
                              <img
                                          src={poiIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                        />
                                
                              <ReactTooltip
                                id="pointOfinterest1"
                                place="top"
                                content="Meters"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "middle",
                              }}
                            >
                              <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                           data-tooltip-id="riskAlertSpeedData02"
                                        />
                                         <ReactTooltip
                                id="riskAlertSpeedData02"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                              
                            </th>

                            <th
                              style={{
                                fontSize: "13px",
                                fontWeight: 600,
                                verticalAlign: "top",
                              }}
                            >
                              <img
                                          src={zipCodeIconNew}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="riskAlertZipCode03"
                                        />
                                         <ReactTooltip
                                id="riskAlertZipCode03"
                                place="top"
                                content="Zipcode"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                          </thead>
                          <tbody style={{ overflowY: "scroll" }}>
                            {(riskDataTable || []).map((apiData) => (
                              <tr
                                style={{
                                  fontSize: "12px",
                                  verticalAlign: "middle",
                                }}
                                key={apiData}
                                onClick={passLatlog}
                              >
                                <td style={{ verticalAlign: "middle" }}>
                                  <img
                                    src={require("../../assets/images/TripIcons/" +
                                      apiData.tripIcon)}
                                    width={12}
                                    alt="image"
                                  />{" "}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  <img
                                    src={require("../../assets/images/" +
                                      apiData.alertImage)}
                                    width={25}
                                    alt="image"
                                  />{" "}
                                </td>

                                <td style={{ verticalAlign: "middle" }}>
                                  {apiData.timeVal}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {apiData.distanceVal}
                                </td>
                                {/* <td style={{ verticalAlign: "middle" }}>
                    {apiData.setspeedValue}
                  </td> */}
                                <td style={{ verticalAlign: "middle" }}>
                                  {apiData.pointOfInterest}
                                </td>
                                <td style={{ textAlign: "end" }}>
                                  <div className=" w-50">
                                    {apiData.speedVal}
                                  </div>
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {apiData.zipcodeVal == 0
                                    ? "-"
                                    : apiData.zipcodeVal}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          {/* </div>
          <div id="container2" style={{marginTop:"-10px"}}> */}
            <Row >
              {/* <Col xl={12}> */}
                <CategoryLine
                  overSpeedTableData={handleOverSpeedTableData}
                  otherRiskData={otherRiskData}
                  categoryLineChart={categoryLineChart}
                  mobileScreenTableData={handleMobileScreenTableData}
                  mobileUseTableData={handleMobileUseTableData}
                />
              {/* </Col> */}
              <Col xl={2} lg={2}>
                <Card style={{ height: 350 }}>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title">
                      Acceleration / Cornering / Braking
                    </h4>
                  </CardHeader>
                  {acbTableData === true ? (
                    <CardBody
                      style={{ height: 300, overflow: "auto", padding: "0px" }}
                    >
                      <div>
                        <Table className="table mb-0">
                          <thead
                            style={{
                              zIndex: 1,
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                           <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="accBrakCorn01"
                                  />
                                  <ReactTooltip
                                id="accBrakCorn01"
                                place="top"
                                content="HH:MM"
                                style={{ background: "black" }}
                              /></th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={UBIStressStrain}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                         data-tooltip-id="accBrakCornTitle01"
                                      />
                                       <ReactTooltip
                                id="accBrakCornTitle01"
                                place="top"
                                content="Type"
                                style={{ background: "black" }}
                              /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={subTypeIcons}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01"
                                place="top"
                                content="Sub Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01Kms"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01Kms"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                            </th>
                          {/* <th style={{ fontSize: "11px", fontWeight: 600, }}><div className="d-flex flex-column"><div className="text-nowrap">Speed</div><span className="text-nowrap" style={{ fontSize: "9px", fontWeight: 400 }}>&nbsp;(km/h)</span></div></th> */}
                        </tr>
                          </thead>
                          <tbody>
                            {(stressData || []).map((apiData, key) => (
                              <tr key={key}>
                                <td style={{ fontSize: "11px" }}>
                                  {dayjs(apiData.date).format("HH:mm")}
                                </td>
                                <td style={{ fontSize: "11px" }}>
                                  {typeMapping[apiData.type] || apiData.type}
                                </td>
                                <td style={{ fontSize: "11px" }}>
                                  {apiData.subCategoryLevel
                                    ? apiData.subCategoryLevel
                                      .charAt(0)
                                      .toUpperCase() +
                                    apiData.subCategoryLevel
                                      .slice(1)
                                      .toLowerCase()
                                    : ""}
                                </td>
                                <td style={{ fontSize: "11px" }}>
                                  {apiData.speed}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  ) : acbTableData === false ? (
                    <CardBody
                      style={{ height: 390, overflow: "auto", padding: "0px" }}
                    >
                      <div>
                        <Table className="table mb-0">
                          <thead
                            style={{
                              zIndex: 1,
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                            <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="accBrakCorn01Not"
                                  />
                                  <ReactTooltip
                                id="accBrakCorn01Not"
                                place="top"
                                content="HH:MM"
                                style={{ background: "black" }}
                              /></th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={UBIStressStrain}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                         data-tooltip-id="accBrakCornTitle01Not"
                                      />
                                       <ReactTooltip
                                id="accBrakCornTitle01Not"
                                place="top"
                                content="Type"
                                style={{ background: "black" }}
                              /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={subTypeIcons}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01Not"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01Not"
                                place="top"
                                content="Sub Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01KmsNot"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01KmsNot"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                            </th>
                          {/* <th style={{ fontSize: "11px", fontWeight: 600, }}><div className="d-flex flex-column"><div className="text-nowrap">Speed</div><span className="text-nowrap" style={{ fontSize: "9px", fontWeight: 400 }}>&nbsp;(km/h)</span></div></th> */}
                        </tr>
                          </thead>
                        </Table>
                        <p
                          className="data_not_available"
                          style={{
                            height: 390,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.DATA_NOT_AVAILABLE}
                        </p>
                      </div>
                    </CardBody>
                  ) : acbTableData === "failed" ? (
                    <CardBody
                      style={{ height: 390, overflow: "auto", padding: "0px" }}
                    >
                      <div>
                        <Table className="table mb-0">
                          <thead
                            style={{
                              zIndex: 1,
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                             <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="accBrakCorn01Er_1206"
                                  />
                                  <ReactTooltip
                                id="accBrakCorn01Er_1206"
                                place="top"
                                content="HH:MM"
                                style={{ background: "black" }}
                              /></th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={UBIStressStrain}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                         data-tooltip-id="accBrakCornTitle01Er_1206"
                                      />
                                       <ReactTooltip
                                id="accBrakCornTitle01Er_1206"
                                place="top"
                                content="Type"
                                style={{ background: "black" }}
                              /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={subTypeIcons}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01Er_1206"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01Er_1206"
                                place="top"
                                content="Sub Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01KmsEr_1206"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01KmsEr_1206"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                            </th>
                          {/* <th style={{ fontSize: "11px", fontWeight: 600, }}><div className="d-flex flex-column"><div className="text-nowrap">Speed</div><span className="text-nowrap" style={{ fontSize: "9px", fontWeight: 400 }}>&nbsp;(km/h)</span></div></th> */}
                        </tr>
                          </thead>
                        </Table>
                        <p
                          className="data_not_available"
                          style={{
                            height: 390,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.ER_1206}
                        </p>
                      </div>
                    </CardBody>
                  ) : (
                    <CardBody
                      style={{ height: 380, overflow: "auto", padding: "0px" }}
                    >
                      <div>
                        <Table className="table mb-0">
                          <thead
                            style={{
                              zIndex: 1,
                              position: "sticky",
                              top: "0",
                              background: "white",
                            }}
                          >
                             <tr>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                    
                                    src={clock}
                                    alt="icons1"
                                    style={{ height: "25px" }}
                                    data-tooltip-id="accBrakCorn01Er_1206Fetch"
                                  />
                                  <ReactTooltip
                                id="accBrakCorn01Er_1206Fetch"
                                place="top"
                                content="HH:MM"
                                style={{ background: "black" }}
                              /></th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}><img
                                        src={UBIStressStrain}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                         data-tooltip-id="accBrakCornTitle01Er_1206Fetch"
                                      />
                                       <ReactTooltip
                                id="accBrakCornTitle01Er_1206Fetch"
                                place="top"
                                content="Type"
                                style={{ background: "black" }}
                              /></th>
                              </th>
                          <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={subTypeIcons}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01Er_1206Fetch"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01Er_1206Fetch"
                                place="top"
                                content="Sub Type"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th style={{ fontSize: "11px", fontWeight: 600, }}>
                          <img
                                          src={OverSpeedIcon}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="accBrakCornTitleSubType01KmsEr_1206Fetch"
                                        />
                                        <ReactTooltip
                                id="accBrakCornTitleSubType01KmsEr_1206Fetch"
                                place="top"
                                content="Kms/hr"
                                style={{ background: "black" }}
                              />
                            </th>
                          {/* <th style={{ fontSize: "11px", fontWeight: 600, }}><div className="d-flex flex-column"><div className="text-nowrap">Speed</div><span className="text-nowrap" style={{ fontSize: "9px", fontWeight: 400 }}>&nbsp;(km/h)</span></div></th> */}
                        </tr>
                          </thead>
                        </Table>
                        <p
                          className="fetch_data"
                          style={{
                            height: 380,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {displayText.FETCHING_DATA}
                        </p>
                      </div>
                    </CardBody>
                  )}
                </Card>
              </Col>
              <Col xl={2} lg={2}>
                <Card style={{ height: 350 }}>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title">Driver State Detection</h4>
                  </CardHeader>
                  <CardBody
                    style={{
                      padding: "20px 10px",
                    }}
                  >
                    {driverStateDetectionData === true ? (
                      ""
                    ) : driverStateDetectionData === false ? (
                      <p className="data_not_available mb-2">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                    ) : driverStateDetectionData === "failed" ? (
                      <p className="data_not_available mb-2">
                        {displayText.ER_1206}
                      </p>
                    ) : (
                      <p className="fetch_data mb-2">
                        {displayText.FETCHING_DATA}
                      </p>
                    )}
                    <div className="table-responsive">
                      <Table
                        id="riskstyles"
                        className="table table-stripe mb-0 text-center"
                      >
                        <thead>
                          <tr>
                            
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                                padding: "0px 20px 12px 13px",
                                whiteSpace:"nowrap"
                              }}
                            >
                              <img
                                          src={UBIDrivingState}
                                          alt="icons2"
                                          style={{ height: "25px" }}
                                          data-tooltip-id="DriverStateDetectionNormalIconDriverStateNew01"

                                        />
                                        <ReactTooltip
                                id="DriverStateDetectionNormalIconDriverStateNew01"
                                place="top"
                                content="Driver State"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                              <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px"}}
                                         data-tooltip-id="DriverStateDetectionNormalIconDriverCount"
                                      />
                                      <ReactTooltip
                                id="DriverStateDetectionNormalIconDriverCount"
                                place="top"
                                content="Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                             <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="DriverStateDetectionNormalIconDriverKms"
                                      />
                                      <ReactTooltip
                                id="DriverStateDetectionNormalIconDriverKms"
                                place="top"
                                content="Kms"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                             <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px"}}
                                         data-tooltip-id="DriverStateDetectionNormalIconDriverMins"
                                      />
                                      <ReactTooltip
                                id="DriverStateDetectionNormalIconDriverMins"
                                place="top"
                                content="Mins"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr>
                            <th
                              style={{
                                textAlign: "start",
                                fontSize: "13px",
                                fontWeight: 600,
                              }}
                              scope="row"
                            >
                              0
                            </th>
                            <td
                              style={{ textAlign: "start", fontSize: "12px" }}
                            >
                              <img
                                src={UnknownImg}
                                style={{ width: "18px" }}
                                className="d-inline risk-value-text me-2"
                              />
                              <div
                                className="d-inline risk-value-text"
                                style={{
                                  paddingLeft: "10px",
                                }}
                              >
                                Unknown
                              </div>
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "12px" }}
                            >
                              {driverStateList.driverUnknown}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "12px" }}
                            >
                              {driverStateList.distanceStateUnknown}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "12px" }}
                            >
                              {driverStateList.durationStateUnknown.toFixed(2)}
                            </td>
                          </tr> */}
                          <tr>
                           
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={NormalImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                data-tooltip-id="driverStateNormalIcon"

                              />
                              <ReactTooltip
                                id="driverStateNormalIcon"
                                place="top"
                                content="Normal"
                                style={{ background: "black" }}
                              />
                              {/* <div
                                className="d-inline risk-value-text"
                                style={{
                                  fontSize: "11px",
                                  paddingLeft: "11px",
                                }}
                              >
                                
                              </div> */}
                            </td>

                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.driverNormal}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.distanceStateNormal}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.durationStateNormal.toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                           
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={TiredImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                 data-tooltip-id="driverStateTiredIcon"
                              />

<ReactTooltip
                                id="driverStateTiredIcon"
                                place="top"
                                content="Tired"
                                style={{ background: "black" }}
                              />
                              
                            </td>

                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.driverTried}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.distanceStateTired}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.durationStateTired.toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={AngryImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                data-tooltip-id="driverStateAngryIcon"
                              />

<ReactTooltip
                                id="driverStateAngryIcon"
                                place="top"
                                content="Angry"
                                style={{ background: "black" }}
                              />
                              
                            </td>

                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.driverAngry}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.distanceStateAngry}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStateList.durationStateAngry.toFixed(2)}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>             

              <Col xl={2} lg={2}>
                <Card style={{ height: 350 }}>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title">
                      Driving Style Estimation
                    </h4>
                  </CardHeader>
                  <CardBody style={{ padding: "20px 10px" }}>
                    {drivingStyleDataCount === true ? (
                      ""
                    ) : drivingStyleDataCount === false ? (
                      <p className="data_not_available mb-2">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                    ) : drivingStyleDataCount === "failed" ? (
                      <p className="data_not_available mb-2">
                        {displayText.ER_1206}
                      </p>
                    ) : (
                      <p className="fetch_data mb-2">
                        {displayText.FETCHING_DATA}
                      </p>
                    )}
                    <div className="table-responsive">
                      <Table
                        id="riskstyles"
                        className="table table-stripe mb-0 text-center"
                      >
                        <thead>
                        <tr>
                            
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                                padding: "0px 20px 12px 13px",
                                whiteSpace:"nowrap"
                              }}
                            >
                             <img
                                            src={UBIDrivingStyle}
                                            alt="icons2"
                                            style={{ height: "25px" }}
                                            data-tooltip-id="DriverStyleDetectionNormalIconDriverStyle"
                                          />
                                          <ReactTooltip
                                id="DriverStyleDetectionNormalIconDriverStyle"
                                place="top"
                                content="Driving Style"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                              <img
                                        src={CountImage}
                                        alt="icons2"
                                        style={{ height: "25px"}}
                                         data-tooltip-id="DriverStyleDetectionNormalIconDriverCount"
                                      />
                                      <ReactTooltip
                                id="DriverStyleDetectionNormalIconDriverCount"
                                place="top"
                                content="Count"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                             <img
                                        src={locationIcon}
                                        alt="icons2"
                                        style={{
                                          height: "25px",
                                         
                                        }}
                                        data-tooltip-id="DriverStyleDetectionNormalIconDriverKms"
                                      />
                                      <ReactTooltip
                                id="DriverStyleDetectionNormalIconDriverKms"
                                place="top"
                                content="Kms"
                                style={{ background: "black" }}
                              />
                            </th>
                            <th
                              className="pt-0"
                              style={{
                                textAlign: "start",
                                fontSize: "11px",
                                fontWeight: 600,
                              }}
                            >
                             <img
                                        src={minsIcon}
                                        alt="icons2"
                                        style={{ height: "25px"}}
                                         data-tooltip-id="DriverStyleDetectionNormalIconDriverMins"
                                      />
                                      <ReactTooltip
                                id="DriverStyleDetectionNormalIconDriverMins"
                                place="top"
                                content="Mins"
                                style={{ background: "black" }}
                              />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr>
                            <th
                              style={{
                                textAlign: "start",
                                fontSize: "13px",
                                fontWeight: 600,
                              }}
                              scope="row"
                            >
                              0
                            </th>
                            <td
                              style={{ textAlign: "start", fontSize: "12px" }}
                            >
                              <img
                                src={UnknownImg}
                                style={{ width: "18px" }}
                                className="d-inline risk-value-text me-2"
                              />
                              <div
                                className="d-inline risk-value-text"
                                style={{
                                  paddingLeft: "10px",
                                }}
                              >
                                Unknown
                              </div>
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "12px" }}
                            >
                              {driverStyleList.drivingUnknown}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "12px" }}
                            >
                              {driverStyleList.distanceStyleUnknown}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "12px" }}
                            >
                              {driverStyleList.durationStyleUnknown.toFixed(2)}
                            </td>
                          </tr> */}
                          <tr>
                           
                            <td
                              style={{ textAlign: "start", fontSize: "12px" }}
                            >
                              <img
                                src={NormalImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                data-tooltip-id="driverStyleNormalIcon"
                              />

                             <ReactTooltip
                                id="driverStyleNormalIcon"
                                place="top"
                                content="Normal"
                                style={{ background: "black" }}
                              />
                              
                            </td>

                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.drivingNormal}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.distanceStyleNormal}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.durationStyleNormal.toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={SportiveImge}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                              data-tooltip-id="driverStyleSportiveIcon"
                              />

<ReactTooltip
                                id="driverStyleSportiveIcon"
                                place="top"
                                content="Sportive"
                                style={{ background: "black" }}
                              />
                              
                            </td>

                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.drivingSportive}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.distanceStyleSportive}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.durationStyleSportive.toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={EcoImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                data-tooltip-id="driverStyleEcoIcon"
                             />
                              <ReactTooltip
                                id="driverStyleEcoIcon"
                                place="top"
                                content="Eco"
                                style={{ background: "black" }}
                              />
                              
                            </td>

                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.drivingEco}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.distanceStyleEco}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.durationStyleEco.toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={ChaoticImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                 data-tooltip-id="driverStyleChaoticIcon"
                              />

<ReactTooltip
                                id="driverStyleChaoticIcon"
                                place="top"
                                content="Chaotic"
                                style={{ background: "black" }}
                              />
                              
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.drivingChaotic}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.distanceStyleChaotic}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.durationStyleChaotic.toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              <img
                                src={AggressiveImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                                data-tooltip-id="driverStyleAggressiveIcon"
                             />

<ReactTooltip
                                id="driverStyleAggressiveIcon"
                                place="top"
                                content="Aggressive"
                                style={{ background: "black" }}
                              />
                              
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.drivingAggressive}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.distanceStyleAggressive}
                            </td>
                            <td
                              style={{ textAlign: "start", fontSize: "11px" }}
                            >
                              {driverStyleList.durationStyleAggressive.toFixed(
                                2
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              
            </Row>

            {/* <br /> */}
           
        
           {/* <div id="container3"> */}
            {/* <Row>
              <Col xl={12} lg={12}>
                <Card style={{ height: "520px" }}>
                  <CardHeader className="cardHeader">
                    <h4 className="sub_heading">Alert's Risk Value Summary</h4>
                  </CardHeader>
                  {summaryData === true ? (
                    <CardBody>
                      <LineTwo alertSummaryXAxis={alertSummaryXAxis} />
                    </CardBody>
                  ) : summaryData === false ? (
                    <CardBody>
                      <p className="data_not_available">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                      <LineTwo alertSummaryXAxis={alertSummaryXAxis} />
                    </CardBody>
                  ) : summaryData === "failed" ? (
                    <CardBody>
                      <p className="data_not_available">{displayText.ER_1206}</p>
                      <LineTwo alertSummaryXAxis={alertSummaryXAxis} />
                    </CardBody>
                  ) : (
                    <CardBody>
                      <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                      <LineTwo alertSummaryXAxis={alertSummaryXAxis} />
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row> */}
            {/* <Row> */}
              {/* <Col xl={5} lg={12}>
                <Card style={{ height: 550 }}>
                  <CardHeader className="cardHeader">
                    <h4 className="sub_heading">
                      Acceleration / Cornering / Braking
                    </h4>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xl={12} lg={12}>
                        <h4
                          style={{
                            fontSize: "12px",
                            fontWeight: 600,
                            textAlign: "center",
                          }}
                        >
                          Acceleration
                        </h4>
                        {accelerationData === true ? (
                          ""
                        ) : accelerationData === false ? (
                          " "
                        ) : accelerationData === "failed" ? (
                          <p className="data_not_available">
                            {displayText.ER_1206}
                          </p>
                        ) : (
                          <p className="fetch_data">
                            {displayText.FETCHING_DATA}
                          </p>
                        )}
                        <div
                          className="mb-3"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            className="border-green p-2 text-center"
                            style={{
                              borderRadius: "100px",
                              width: "7em",
                              height: "7em",
                            }}
                          >
                            <Avatar
                              round
                              size="40"
                              // size = 48
                              src={severeAccelerationGreen}
                              className="bg-white"
                            />
                            <h6
                              className="table-data"
                              style={{ fontSize: "12px", fontWeight: 600 }}
                            >
                              Low
                            </h6>
                            <p className="table-data">
                              {stressStrainData.sAccelerationLow}
                            </p>
                          </div>

                          <div
                            className="border-yellow p-2 text-center"
                            style={{
                              borderRadius: "100px",
                              width: "7em",
                              height: "7em",
                            }}
                          >
                            <Avatar
                              round
                              size="40"
                              src={severeAccelerationYellow}
                              className="bg-white"
                            />
                            <h6
                              className="table-data"
                              style={{ fontSize: "12px", fontWeight: 600 }}
                            >
                              Medium
                            </h6>
                            <p className="table-data">
                              {stressStrainData.sAccelerationMedium}{" "}
                            </p>
                          </div>

                          <div
                            className="border-red p-2 text-center"
                            style={{
                              borderRadius: "100px",
                              width: "7em",
                              height: "7em",
                            }}
                          >
                            <Avatar
                              round
                              size="40"
                              src={severeAccelerationRed}
                              className="bg-white"
                            />
                            <h6
                              className="table-data"
                              style={{ fontSize: "12px", fontWeight: 600 }}
                            >
                              High
                            </h6>
                            <p className="table-data">
                              {stressStrainData.sAccelerationHigh}{" "}
                            </p>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ borderTop: "1px solid #DAD9D4" }}>
                      <div style={{ marginTop: "2%", display: "flex" }}>
                        <Col xl={12} lg={12}>
                          <h4
                            style={{
                              fontSize: "12px",
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            Cornering
                          </h4>
                          <div
                            className="mb-3"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="border-green p-2 text-center"
                              style={{
                                borderRadius: "100px",
                                width: "7em",
                                height: "7em",
                              }}
                            >
                              <Avatar
                                round
                                size="40"
                                src={sCorneringGreen}
                                className="bg-white"
                              />
                              <h6
                                className="table-data"
                                style={{ fontSize: "12px", fontWeight: 600 }}
                              >
                                Low
                              </h6>
                              <p className="table-data">
                                {stressStrainData.sCorneringLow}{" "}
                              </p>
                            </div>

                            <div
                              className="border-yellow p-2 text-center"
                              style={{
                                borderRadius: "100px",
                                width: "7em",
                                height: "7em",
                              }}
                            >
                              <Avatar
                                round
                                size="40"
                                src={sCorneringYellow}
                                className="bg-white"
                              />
                              <h6
                                className="table-data"
                                style={{ fontSize: "12px", fontWeight: 600 }}
                              >
                                Medium
                              </h6>
                              <p className="table-data">
                                {stressStrainData.sCorneringMedium}
                              </p>
                            </div>

                            <div
                              className="border-red p-2 text-center"
                              style={{
                                borderRadius: "100px",
                                width: "7em",
                                height: "7em",
                              }}
                            >
                              <Avatar
                                round
                                size="40"
                                src={sCorneringRed}
                                className="bg-white"
                              />
                              <h6
                                className="table-data"
                                style={{ fontSize: "12px", fontWeight: 600 }}
                              >
                                High
                              </h6>
                              <p className="table-data">
                                {stressStrainData.sCorneringHigh}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </Row>
                    <Row style={{ borderTop: "1px solid #DAD9D4" }}>
                      <div style={{ marginTop: "2%", display: "flex" }}>
                        <Col xl={12} lg={12}>
                          <h4
                            style={{
                              fontSize: "12px",
                              fontWeight: 600,
                              textAlign: "center",
                            }}
                          >
                            Braking
                          </h4>
                          <div
                            className="mb-3"
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <div
                              className="border-green p-2 text-center"
                              style={{
                                borderRadius: "100px",
                                width: "7em",
                                height: "7em",
                              }}
                            >
                              <Avatar
                                round
                                size="40"
                                src={sBrakingGreen}
                                className="bg-white"
                              />
                              <h6
                                className="table-data"
                                style={{ fontSize: "12px", fontWeight: 600 }}
                              >
                                Low
                              </h6>
                              <p className="table-data">
                                {stressStrainData.sBrakingLow}
                              </p>
                            </div>

                            <div
                              className="border-yellow p-2 text-center"
                              style={{
                                borderRadius: "100px",
                                width: "7em",
                                height: "7em",
                              }}
                            >
                              <Avatar
                                round
                                size="40"
                                src={sBrakingYellow}
                                className="bg-white"
                              />
                              <h6
                                className="table-data"
                                style={{ fontSize: "12px", fontWeight: 600 }}
                              >
                                Medium
                              </h6>
                              <p className="table-data">
                                {stressStrainData.sBrakingMedium}
                              </p>
                            </div>

                            <div
                              className="border-red p-2 text-center"
                              style={{
                                borderRadius: "100px",
                                width: "7em",
                                height: "7em",
                              }}
                            >
                              <Avatar
                                round
                                size="40"
                                src={sBrakingRed}
                                className="bg-white"
                              />
                              <h6
                                className="table-data"
                                style={{ fontSize: "12px", fontWeight: 600 }}
                              >
                                High
                              </h6>
                              <p className="table-data">
                                {stressStrainData.sBrakingHigh}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col> */}
             
            {/* </Row> */}

            {/* <Row style={{marginTop:"-20px"}}>
              

             
            </Row> */}
          {/* </div> */}
         
          {/* <div id="container3" > */}
            <Row style={{marginTop:"-10px"}}>
              <Col xl={12} lg={12}>
                <Card>
                  <CardHeader className="cardHeader" style={{padding:"5px"}}>
                    <h4 className="card-title">
                      Risk Alerts{" "}
                      <span style={{ fontWeight: 400, fontSize: "12px" }}>
                        (Total Alerts - {totalAlerts})
                      </span>
                    </h4>
                  </CardHeader>
                </Card>
              </Col>
            </Row>

            <Row>
              {riskDataListleng === true ? (
                ""
              ) : riskDataListleng === false ? (
                <p className="data_not_available mb-2">
                  {displayText.DATA_NOT_AVAILABLE}
                </p>
              ) : riskDataListleng === "failed" ? (
                <p className="data_not_available mb-2">{displayText.ER_1206}</p>
              ) : (
                <p className="fetch_data mb-2">{displayText.FETCHING_DATA}</p>
              )}
              {(riskDataList || []).map((widgets, key) => (
                <Col xl={3} md={4} sm={6} xs={12} key={key}>
                  <Row style={{marginBottom:"-11px"}}>
{widgets.map((widget) => (
  <Col xl={6} md={6} sm={6} xs={12} key={key}>

                  <Card className="card-h-100" style={{width:"100%"}}>
                    <CardBody style={{ padding: "5px" }}>
                      <Row className="align-items-center">
                        <Col xs={12}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              textAlign:"left"
                            }}
                          >
                            <div className="text-nowrap" style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                              <img
                                src={widget.image}
                                alt="cardOneImg"
                                height="25px"
                              />
                            </div>

                            <div style={{display:"flex",justifyContent:"center",flexDirection:"column", width:"70%"}}>
                              <span
                                // className="text-muted mb-3 d-block text-truncate"
                                style={{ fontSize: "10px",textAlign:"left"}}
                              >
                                {widget.title}
                              </span>
                             
                            </div>
                            <div style={{display:"flex",justifyContent:"center",flexDirection:"column"}}>
                            {/* <h4> */}
                               
                               <span
                                 className="counter-value"
                                 style={{
                                   textAlign: "center",
                                   alignItems: "center",
                                 }}
                               >
                                 <CountUp
                                   start={0}
                                   end={widget.overallData}
                                   
                                   duration={1}
                                 />
                                
                               </span>
                              
                             {/* </h4> */}
                            </div>
                            </div>
                           
                          
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  </Col>
                  ))}
                  </Row>
                </Col>
              ))}
            </Row>

            {/* <Row className="mt-2">
              <Col xl={12} lg={12}>
                <Card>
                  <CardHeader className="cardHeader">
                    <h4 className="sub_heading mt-1">
                      Red Alert's Bubble Plot
                    </h4>
                  </CardHeader>
                  {alertValidate === true ? (
                    <CardBody>
                      <RedAlertsBubble redBubblePlot={redBubblePlot} />
                    </CardBody>
                  ) : alertValidate === false ? (
                    <CardBody>
                      <p className="data_not_available">
                        {displayText.DATA_NOT_AVAILABLE}
                      </p>
                      <RedAlertsBubble redBubblePlot={redBubblePlot} />
                    </CardBody>
                  ) : alertValidate === "failed" ? (
                    <CardBody>
                      <p className="data_not_available">
                        {" "}
                        {displayText.ER_1206}
                      </p>
                      <RedAlertsBubble redBubblePlot={redBubblePlot} />
                    </CardBody>
                  ) : (
                    <CardBody>
                      <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                      <RedAlertsBubble redBubblePlot={redBubblePlot} />
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row> */}

            {/* <Row>
              <Col xl={3} lg={3}>
                <Card style={{ height: 430 }}>
                  <CardHeader className="cardHeader">
                    <h4 className="sub_heading mt-1" style={{ fontSize: "14px" }}>
                      Driving Style Estimation
                    </h4>
                  </CardHeader>
                  <CardBody>
                    {drivingStyleDataCount === true ? "" : drivingStyleDataCount === false ? <p className="data_not_available mb-2">
                      {displayText.DATA_NOT_AVAILABLE}
                    </p> : drivingStyleDataCount === "failed" ? <p className="data_not_available mb-2">
                      {displayText.ER_1206}
                    </p> : <p className="fetch_data mb-2">{displayText.FETCHING_DATA}</p>}
                    <div className="table-responsive">
                      <Table
                        id="riskstyles"
                        className="table table-stripe mb-0 text-center"
                      >
                        <thead >
                          <tr>
                            <th className="pt-0" scope="col" style={{ textAlign: "start", fontSize: "12px", }}>State</th>
                            <th className="pt-0" style={{ textAlign: "start", fontSize: "12px" }}>
                              Driving Style
                            </th>
                            <th className="pt-0" style={{ textAlign: "start", fontSize: "12px" }}>
                              Count
                            </th>
                            <th className="pt-0" style={{ textAlign: "start", fontSize: "12px" }}>
                              Distance
                            </th>
                            <th className="pt-0" style={{ textAlign: "start", fontSize: "12px" }}>
                              Duration
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th style={{ textAlign: "start" }} scope="row">0</th>
                            <td style={{ textAlign: "start" }}>
                              <img
                                src={UnknownImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                              />
                              <div
                                className="d-inline risk-value-text"
                                style={{ fontSize: "12px", paddingLeft: "10px" }}
                              >
                                Unknown
                              </div>
                            </td>
                            <td style={{ textAlign: "start" }}>
                              {driverStyleList.drivingUnknown}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "start" }} scope="row">1</th>
                            <td style={{ textAlign: "start" }}>
                              <img
                                src={NormalImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                              />
                              <div
                                className="d-inline risk-value-text"
                                style={{ fontSize: "12px", paddingLeft: "10px" }}
                              >
                                Normal
                              </div>
                           </td>

                            <td style={{ textAlign: "start" }}>
                              {driverStyleList.drivingNormal}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "start" }} scope="row">2</th>
                            <td style={{ textAlign: "start" }}>
                              <img
                                src={SportiveImge}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                              />
                              <div
                                className="d-inline risk-value-text"
                                style={{ fontSize: "12px", paddingLeft: "10px" }}
                              >
                                Sportive
                              </div>
                            </td>

                            <td style={{ textAlign: "start" }}>
                              {driverStyleList.drivingSportive}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "start" }} scope="row">3</th>
                            <td style={{ textAlign: "start" }}>
                              <img
                                src={EcoImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                              />
                              <div
                                className="d-inline risk-value-text"
                                style={{ fontSize: "12px", paddingLeft: "10px" }}
                              >
                                Eco
                              </div>
                            </td>

                            <td style={{ textAlign: "start" }}>
                              {driverStyleList.drivingEco}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "start" }} scope="row">4      </th>
                            <td style={{ textAlign: "start" }}>
                              <img
                                src={ChaoticImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                              />
                              <div
                                className="d-inline risk-value-text"
                                style={{ fontSize: "12px", paddingLeft: "10px" }}
                              >
                                Chaotic
                              </div>
                            </td>
                            <td style={{ textAlign: "start" }}>
                              {driverStyleList.drivingChaotic}
                            </td>
                          </tr>
                          <tr>
                            <th style={{ textAlign: "start" }} scope="row">5</th>
                            <td style={{ textAlign: "start" }}>
                              <img
                                src={AggressiveImg}
                                style={{ width: "20px" }}
                                className="d-inline risk-value-text me-2"
                              />
                              <div
                                className="d-inline risk-value-text"
                                style={{ fontSize: "12px", paddingLeft: "10px" }}
                              >
                                Aggressive
                              </div>
                            </td>
                            <td style={{ textAlign: "start" }}>
                              {driverStyleList.drivingAggressive}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={9} lg={9}>
                <Card style={{ height: 430 }}>
                  <CardHeader className="cardHeader">
                    <h4 className="sub_heading mt-1" style={{ fontSize: "14px" }}>
                      Driving Style Estimation
                    </h4>
                  </CardHeader>
                  {styleEstimationData === true ? (
                    <CardBody>
                      <DrivingStyleEstimation driverData={driverData} />
                    </CardBody>
                  ) : styleEstimationData === false ? (
                    <CardBody>
                      <p className="data_not_available">{displayText.DATA_NOT_AVAILABLE}</p>
                      <DrivingStyleEstimation driverData={driverData} />
                    </CardBody>
                  ) : styleEstimationData === "failed" ? (
                    <CardBody>
                      <p className="data_not_available">{displayText.ER_1206}</p>
                      <DrivingStyleEstimation driverData={driverData} />
                    </CardBody>
                  ) : (
                    <CardBody>
                      <p className="fetch_data">{displayText.FETCHING_DATA}</p>
                      <DrivingStyleEstimation driverData={driverData} />
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row> */}
          </div>
        </Container>
      </div>
      <ToastContainer style={{ width: "auto" }} />
    </React.Fragment>
  );
};

// Logout.propTypes = {
//   history: PropTypes.object,
// }
export default RideDetails;
