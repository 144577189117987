import React, { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Breadcrumbs from "../../../components/Common/Breadcrumb.js";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  CardHeader,
} from "reactstrap";
import {
  AdminDashboardRideBoxCount,
  AdminDashboardTopRowItems,
} from "../../../common/data/admin/dashboard.js";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ValueFormat from "../../../util/ValueFormat.js";
import AdminDashboardController from "../../../controller/Dashboard/AdminDasahboardController.js";
import OverallSummary from "../OverallSummary/overallSummary.js";
import DrivingBehaviour from "../Charts/multiuserDrivingBehaviour.js";
import { useSelector } from "react-redux";
import Loader from "../../../components/Common/loader.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ToastService from "../../../components/Common/toastService.js";
import ExportScreenPdf from "../../../components/ExportScreenPdf/index.js";
import { displayText } from "../../../constant/MessageConstant.js";
import {
  SC_SELECTED_USER_VALUE,
  SC_USER_FIRST_NAME,
} from "../../../constant/StorageConstant.js";
import ViewPdf from "../../../components/ExportScreenPdf/indexReact2Pdf.js";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateTimePickerPopup from "../../ModelPopup/DateTimePopup.js";
import OverallRiskSummary from "../OverallRiskSummary/overallRiskSummary.js";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import AdminDashboardSubMenu from "./AdminDashboardSubMenu.js";
import RidesKmHrsScoreCBar from "./RidesKmHrsScoreCBar.js";
import "./Submenu.css";
import ExportScreenDownloadPdf from "../../../components/ExportScreenPdf/exportScreenDownloadPdf.js";
import DownloadIcon from "../../../assets/images/adminDashboard/DownloadIconNew.svg";
import MonthlyWeeklySummary from "./MonthlyWeeklySummary.js";
import AddIcon from "../../../../src/assets/images/userAdd.svg";
import MyUsersNewTable from "../NewTab/tableDataMyuser.js";
import filterClockIcon from "../../../assets/images/common/filterClockIcon.svg";
import filterClockIconDe from "../../../assets/images/common/filterIconClockDeactive.svg";
import Checkbox from "@mui/material/Checkbox";
import { FormGroup, FormControlLabel, Radio, RadioGroup } from "@mui/material";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
const OverallSummaryy = () => {

  let drivingCategoryList = [{
    drivingSummaryTotalRides:0,
    badDrivingCount:0,
    dangerousDrivingCount:0,
    goodDrivingCount:0,
    quietSafeDrivingCount:0,
    noOfDays:0,
    totalDistance:0,
    totalDuration:0,
    totalHighRiskCountNew:0,
    goodDrivingPercentage:0,
    quitSafeDrivingPercentage:0,
    dangerousDrivingPercentage:0,
    badDrivingPercentage:0
}]

  const [ADTopRowItemsTemp, setADTopRowItemsTemp] = useState(
    AdminDashboardTopRowItems
  );
  const [ADRideBoxCount, setADRideBoxCount] = useState(
    AdminDashboardRideBoxCount
  );
  // console.log(ADRideBoxCount, "ADRideBoxCountADRideBoxCount");

  const [userUsagePer, setUserUsagePer] = useState([]);
  const [totalSafeRidePer, setTotalSafeRidePer] = useState(0);
  const [totalRidesCount, setTotalRidesCount] = useState(0);
  const [showLoader, setShowLoader] = useState(0);
  const [indexStart, setIndexStart] = useState(0);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [startDateTime, setStartDateTime] = useState("");
  const [endDateTime, setEndDateTime] = useState("");
  const [userFirstName, setUserFirstName] = useState(
    localStorage.getItem(SC_USER_FIRST_NAME)
  );
  const selectedUserValue = localStorage.getItem(SC_SELECTED_USER_VALUE) || "";

  const [filter, setFilter] = useState("");
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    durationFilter: "CURRENT_WEEK",
    startDateTime: "",
    endDateTime: "",
  });

  const [aggregateData, setAggregateData] = useState({
    noOfDays:0,
    drivingSummaryTotalRides:0,
    totalDistance:0,
    totalDuration:0,
    totalHighRiskCountNew:0,
    totalUsers:0,
  });
  const [totalDays, setTotalDays] = useState(0);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [modalIsOpenTwo, setIsOpenTwo] = React.useState(false);
  const [selectedValue, setSelectedValue] = useState("ACTIVE_USER_LIST");
 const [ drivingDataList, setDrivingDataList] = useState(drivingCategoryList);
  const toggleOpen = () => setDropdownOpen((prevState) => !prevState);
  const handleChange = (event) => {
    setSelectedValue(event.target.value); // Update the selected value on change
  };

  useEffect(() => {
    if (count == 1) {
      setFilters({
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        durationFilter: filter,
      });
    }
  }, [filter, startDateTime, endDateTime]);

  const getTotalValues = async () => {
    setCount(1);
    const transportType = selectedUserValue;
    setShowLoader((current) => current + 1);
    var result = await AdminDashboardController.adminDashboardController(
      filters.startDateTime,
      filters.endDateTime,
      filters.durationFilter,
      transportType
    );
    setShowLoader((current) => current - 1);
    var resultJSON = JSON.parse(result);
    var status = resultJSON.status;
    // console.log("admintopitemhfhgfgf", resultJSON)
    if (status == "SUCCESS") {
      var resultJSONData = resultJSON.data;
      var totalCount = 0;
      var totalKm = 0;
      var totalRide = 0;
      var safeRide = 0;
      var userCountPercentages = [];
      var getTheValueTopFore = [];
      // console.log("AdminTopRows:",(resultJSONData));
      let totalDays = 0;
      let totalRides = 0;
      let totalKilometer = 0;
      let totalHighRiskCount = 0;
      let totalAlertDataCount = 0;
      let totalMediumRiskCount = 0;
      let totalTravelTime = 0;
      let totalUser = 0;
      resultJSONData.map((apiResultTemp) => {
        var totalUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.totalUserCount)
        );
        var activeUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.activeUserCount)
        );

        var inactiveUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.inactiveUserCount)
        );
        var unregisteredUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.unregisteredUserCount)
        );

        var registeredUserCount = parseInt(
          ValueFormat.parseAsInt(apiResultTemp.registeredUserCount)
        );

        totalDays = parseInt(ValueFormat.parseAsInt(apiResultTemp.noOfDays));

        const overallRideBoxList = ValueFormat.parseAsArray(
          apiResultTemp.overallRideBoxList
        );
        overallRideBoxList.map((apiData) => {
          var rideBox = ValueFormat.parseAsInt(apiData.rideBox);
          totalRide += ValueFormat.parseAsInt(apiData.totalRides);
          if (rideBox == 1) {
            safeRide = apiData.totalRides;
          }
        });
        
        let sendTheData = [];
        let drivingSummary = apiResultTemp.drivingSummary;
        let drivingCategoryCount = apiResultTemp.drivingSummary.drivingCategoryCount;
        const drivingSummaryTotalRides = ValueFormat.parseAsString(drivingSummary.totalRides);
        const totalUsers = ValueFormat.parseAsString(drivingSummary.totalUsers);
        const badDrivingCount = ValueFormat.parseAsInt(drivingCategoryCount.badDrivingCount);
        const dangerousDrivingCount = ValueFormat.parseAsInt(drivingCategoryCount.dangerousDrivingCount);
        const goodDrivingCount = ValueFormat.parseAsInt(drivingCategoryCount.goodDrivingCount);
        const quietSafeDrivingCount = ValueFormat.parseAsInt(drivingCategoryCount.quietSafeDrivingCount);
        const noOfDays = ValueFormat.parseAsString(apiResultTemp.noOfDays);
        const totalDistance = ValueFormat.parseAsFloat(drivingSummary.tKiloMeter,2);
        // console.log(apiResultTemp.drivingSummary.tk,"totalDistance")
        const totalDuration = ValueFormat.parseAsFloat(drivingSummary.tTravelTime);
        const totalHighRiskCountNew = ValueFormat.parseAsFloat(drivingSummary.totalHighRiskCount);
        const goodDrivingPercentage = ((goodDrivingCount / drivingSummaryTotalRides)*100).toFixed(0);
        const quitSafeDrivingPercentage = ((quietSafeDrivingCount / drivingSummaryTotalRides)*100).toFixed(0);
        const dangerousDrivingPercentage = ((dangerousDrivingCount / drivingSummaryTotalRides)*100).toFixed(0);
        const badDrivingPercentage = ((badDrivingCount / drivingSummaryTotalRides)*100).toFixed(0);
        // let updateTheValue = [{
        //   noOfDays:noOfDays,
        //   drivingSummaryTotalRides:drivingSummaryTotalRides,
        //   totalDistance:totalDistance,
        //   totalDuration:totalDuration,
        //   totalHighRiskCountNew:totalHighRiskCountNew
        // }]
        
        // sendTheData.push(updateTheValue);
        setAggregateData({
          noOfDays,
          drivingSummaryTotalRides,
          totalDistance,
          totalDuration,
          totalHighRiskCountNew,
          totalUsers
        })
        
        const overAllRideBoxCountList = ValueFormat.parseAsArray(
          apiResultTemp.overallRideBoxCountList
        );
        // console.log(overAllRideBoxCountList,"overAllRideBoxCountList")
        overAllRideBoxCountList.forEach((apiData) => {
          totalRides += ValueFormat.parseAsInt(apiData.totalRides);
          totalKilometer +=
            parseFloat(apiData.totalKilometer) == undefined ||
            parseFloat(apiData.totalKilometer) == null
              ? 0
              : parseFloat(apiData.totalKilometer);
          totalHighRiskCount += ValueFormat.parseAsInt(
            apiData.totalHighRiskCount
          );
          totalAlertDataCount += ValueFormat.parseAsInt(
            apiData.totalAlertDataCount
          );
          totalMediumRiskCount += ValueFormat.parseAsInt(
            apiData.totalMediumRiskCount
          );
          totalTravelTime +=
            parseFloat(apiData.totalTravelTime) == undefined ||
            parseFloat(apiData.totalTravelTime) == null
              ? 0
              : parseFloat(apiData.totalTravelTime);
        });

        let overAllRideBoxTotalRidesCount = {
          totalRides: 0,
          count: [
            {
              totalRides: 0,
              rideBox: 1,
              percentage: 0,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 2,
              percentage: 0,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 3,
              percentage: 0,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 4,
              percentage: 0,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 5,
              percentage: 0,
              totalKm: 0,
              totalTravelTime: 0,
            },
            {
              totalRides: 0,
              rideBox: 6,
              percentage: 0,
              totalKm: 0,
              totalTravelTime: 0,
            },
          ],
        };

        // Calculate total rides
        let overAllTotalRides = 0;
        overAllRideBoxCountList.forEach((val) => {
          overAllTotalRides += ValueFormat.parseAsInt(val.totalRides);
        });
        // console.log(overAllRideBoxCountList,"overAllRideBoxCountList")
        overAllRideBoxTotalRidesCount.totalRides = overAllTotalRides;

        // Create a map for rideBox indices
        const rideBoxMap = {};
        overAllRideBoxTotalRidesCount.count.forEach((item, index) => {
          rideBoxMap[item.rideBox] = index;
        });
         
        // Update count array using rideBox values
        overAllRideBoxCountList.forEach((val) => {
          const rideBoxIndex = rideBoxMap[val.rideBox];
          // console.log(rideBoxIndex,"rideBoxIndex")
          if (rideBoxIndex !== undefined) {
            overAllRideBoxTotalRidesCount.count[rideBoxIndex] = {
              ...overAllRideBoxTotalRidesCount.count[rideBoxIndex],
              totalRides: val.totalRides,
              rideBox: val.rideBox,
              percentage: (
                (ValueFormat.parseAsInt(val.totalRides) /
                  overAllRideBoxTotalRidesCount.totalRides) *
                100
              ).toFixed(1),
              totalKm: val.totalKilometer,
              totalTravelTime: val.totalTravelTime,
            };
          } else {
            // console.warn(`rideBox ${val.rideBox} not found in count array`);
          }
        });

        setADRideBoxCount(overAllRideBoxTotalRidesCount);

        var totalSafeRideCount = ValueFormat.parseAsInt(safeRide);
        var totalDeactivatedUsersCount = ValueFormat.parseAsInt(
          apiResultTemp.deactivatedUserCount
        );
        setTotalRidesCount(totalRide);
        setTotalSafeRidePer(
          ValueFormat.parseAsFloat((safeRide / totalRide) * 100),
          0
        );
        var totalRegisteredUsersCount = activeUserCount + inactiveUserCount;
        
        let sendTheDataList = {
          drivingSummaryTotalRides:drivingSummaryTotalRides,
          badDrivingCount:badDrivingCount,
          dangerousDrivingCount:dangerousDrivingCount,
          goodDrivingCount:goodDrivingCount,
          quietSafeDrivingCount:quietSafeDrivingCount,
          noOfDays:noOfDays,
          totalDistance:totalDistance,
          totalDuration:totalDuration,
          totalHighRiskCountNew:totalHighRiskCountNew,
          goodDrivingPercentage:goodDrivingPercentage,
          quitSafeDrivingPercentage:quitSafeDrivingPercentage,
          dangerousDrivingPercentage:dangerousDrivingPercentage,
          badDrivingPercentage:badDrivingPercentage
        }

        getTheValueTopFore.push(sendTheDataList);

        userCountPercentages.push(
          activeUserCount,
          inactiveUserCount,
          unregisteredUserCount,
          registeredUserCount
        );

        setADTopRowItemsTemp(
          ADTopRowItemsTemp.map((staticObjectTemp) => {
            var isTypeMatched = false;
            var totalCount = 0;
            if (staticObjectTemp.type === "TOTAL") {
              isTypeMatched = true;
              totalCount = totalUserCount;
            } else if (staticObjectTemp.type === "ACTIVE") {
              isTypeMatched = true;
              totalCount = activeUserCount;
            } else if (staticObjectTemp.type === "INACTIVE") {
              isTypeMatched = true;
              totalCount = inactiveUserCount;
              // console.log("inac", inactiveUserCount);
            } else if (staticObjectTemp.type === "UNREGISTER") {
              isTypeMatched = true;
              totalCount = unregisteredUserCount;
            } else if (staticObjectTemp.type === "PENDING") {
              isTypeMatched = true;
              totalCount = registeredUserCount;
            } else if (staticObjectTemp.type === "CATEGORY") {
              isTypeMatched = true;
              totalCount = totalSafeRideCount;
            } else if (staticObjectTemp.type === "DEACTIVATED") {
              isTypeMatched = true;
              totalCount = totalDeactivatedUsersCount;
            } else if (staticObjectTemp.type === "REGISTERED") {
              isTypeMatched = true;
              totalCount = totalRegisteredUsersCount;
            }

            if (isTypeMatched === true) {
              let staticObjectTempReplace = {
                totalCount: totalCount,
              };
              return { ...staticObjectTemp, ...staticObjectTempReplace };
            } else {
              return staticObjectTemp;
            }
          })
        );
        // console.log("rideBoxListLen", ADTopRowItemsTemp)
      });
      // setAggregateData({
      //   totalRides,
      //   totalKilometer,
      //   totalHighRiskCount,
      //   totalAlertDataCount,
      //   totalMediumRiskCount,
      //   totalTravelTime,
      //   totalUsers,
      // });

      setTotalDays(totalDays);

      setUserUsagePer(userCountPercentages);
      setDrivingDataList(getTheValueTopFore);
    } else {
      // const code = resultJSON.error.code;
      // if (code == "E1206") {
      //   ToastService.errormsg(displayText.ER_1206);
      //   setTimeout(() => {
      //     window.location.href = "/contacts-profile";
      //   }, 3500);
      // }
      // console.log("Status failed");
    }
  };

  const containerIds = ["container1"];

  useEffect(() => {
    getTotalValues();
  }, [
    filters.durationFilter,
    indexStart,
    filters.startDateTime,
    filters.endDateTime,
  ]);

  const [summaryData, setSummaryData] = useState([]);
  const [riskSummaryData, setRiskSummaryData] = useState([]);
  const [aggregateData1, setAggregateData1] = useState(aggregateData);
  const [totalDays1, setTotalDays1] = useState(totalDays);
  const [filtersADOS, setFiltersADOS] = useState(filters);

  const handleSummaryData = (data) => {
    setSummaryData(data);
  };
  const handleRiskSummaryData = (data) => {
    setRiskSummaryData(data);
  };
  function openModal() {
    // setTimeout(() => {
    //   validation.setFieldValue("addUsername", "");
    //   validation.setFieldValue("addUserPassword", "");
    //   validation.setFieldValue("addFirstName", "");
    //   validation.handleReset();
    // }, 100);
    setIsOpen(true);
    setMobileNumber("");
    setPassword("");
    subscriptionData();
  }
  return (
    <React.Fragment>
      <div className="page-content">
        {/* <div className="ps-5 pe-5"> */}
        <Container fluid id="division">
          <div id="container1" style={{ margin: "0px", padding: "0px" }}>
            {/* Render Breadcrumbs */}
            <Breadcrumbs
              title="Admin Dashboard"
              breadcrumbItem="Admin Dashboard"
            />
            {showLoader > 0 && <Loader />}
            <ToastContainer style={{ width: "auto" }} />
            <Row className="adminmenu-height mb-2">
              <Col
                xs={12}
                style={{
                  position: "fixed",
                  zIndex: 1,
                  backgroundColor: "#fff",
                }}
                className="shadow-sm"
              >
                <Row>
                  <Col xl={7} className="py-2">
                    <div style={{ display: "flex" }}>
                      <AdminDashboardSubMenu />
                    </div>
                  </Col>

                  <Col
                    className="py-2"
                    xl={5}
                    style={{ alignContent: "center" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        paddingRight: "10px",
                      }}
                    >
                      <div className="ms-auto pe-2 border-end border-secondary">
                        <div style={{ float: "right" }}>
                          <button
                            type="button"
                            data-tooltip-id="cd"
                            style={{ fontWeight: 700 }}
                            className={
                              filters.durationFilter == "CURRENT_DAY" &&
                              !modalIsOpen
                                ? "btn btnColorStylesNew btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_DAY",
                              });
                            }}
                          >
                            CD
                            <ReactTooltip
                              id="cd"
                              place="bottom"
                              content="Current Day"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cw"
                            style={{ fontWeight: 700 }}
                            className={
                              filters.durationFilter == "CURRENT_WEEK" &&
                              !modalIsOpen
                                ? "btn btnColorStylesNew btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_WEEK",
                              });
                            }}
                          >
                            CW
                            <ReactTooltip
                              id="cw"
                              place="bottom"
                              content="Current Week"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cm"
                            style={{ fontWeight: 700 }}
                            className={
                              filters.durationFilter == "CURRENT_MONTH" &&
                              !modalIsOpen
                                ? "btn btnColorStylesNew btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_MONTH",
                              });
                            }}
                          >
                            CM
                            <ReactTooltip
                              id="cm"
                              place="bottom"
                              content="Current Month"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            data-tooltip-id="cy"
                            style={{ fontWeight: 700 }}
                            className={
                              filters.durationFilter == "CURRENT_YEAR" &&
                              !modalIsOpen
                                ? "btn btnColorStylesNew btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setCount(0);
                              setStartDateTime("");
                              setEndDateTime("");
                              setFilters({
                                ...filters,
                                durationFilter: "CURRENT_YEAR",
                              });
                            }}
                          >
                            CY
                            <ReactTooltip
                              id="cy"
                              place="bottom"
                              content="Current Year"
                              style={{ backgroundColor: "black" }}
                            />
                          </button>{" "}
                          <button
                            type="button"
                            className={
                              filters.durationFilter == "CUSTOM_DURATION" ||
                              modalIsOpen
                                ? "btn btnColorStylesNew btn-sm"
                                : "btn btn-soft-secondary btn-sm"
                            }
                            onClick={() => {
                              setIsOpen(true);
                              setCount(1);
                            }}
                            data-tooltip-id="dfdf"
                          >
                            {filter == "CUSTOM_DURATION" || modalIsOpen ? (
                              <img
                                src={filterClockIcon}
                                alt="clock"
                                style={{
                                  height: "18px",
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              />
                            ) : (
                              <img
                                src={filterClockIconDe}
                                alt="clock"
                                style={{
                                  height: "18px",
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              />
                            )}
                            <ReactTooltip
                              id="dfdf"
                              place="bottom"
                              content="Custom Date Selection"
                              style={{ background: "black" }}
                            />
                          </button>{" "}
                        </div>
                      </div>

                      <div className="d-flex justify-content-end">
                        <Dropdown isOpen={dropdownOpen} toggle={toggleOpen}>
                          <DropdownToggle
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                              padding: 0,
                              boxShadow: "none",
                            }}
                          >
                            <img
                              src={DownloadIcon}
                              alt="icons2"
                              style={{
                                height: "20px",
                              }}
                            />
                          </DropdownToggle>

                          <DropdownMenu>
                            {/* Export PDF Option */}
                            <DropdownItem
                              onClick={() => {
                                ExportScreenDownloadPdf.generatePDF(
                                  containerIds,
                                  userFirstName + "'s" + " Overall Summary"
                                );
                              }}
                            >
                              Export Screen
                            </DropdownItem>

                            {/* View PDF Option */}
                            <DropdownItem
                              onClick={() => {
                                ViewPdf({
                                  ADTopRowItemsTemp,
                                  ADRideBoxCount,
                                  summaryData,
                                  riskSummaryData,
                                  aggregateData1,
                                  totalDays1,
                                  filtersADOS,
                                });
                              }}
                            >
                              Download PDF
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      </div>

                      {/* <ExportScreenPdf
                        containerIds={containerIds}
                        fileName={userFirstName + "'s" + " Overall Summary"}
                      />
                      <ViewPdf
                        ADTopRowItemsTemp={ADTopRowItemsTemp}
                        ADRideBoxCount={ADRideBoxCount}
                        summaryData={summaryData}
                        riskSummaryData={riskSummaryData}
                        aggregateData1={aggregateData}
                        totalDays1={totalDays}
                        filtersADOS={filters}
                      /> */}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="adminmenu-mt">
              <Col  xl={3} lg={6} md={6}>
                <Card>
                  <CardBody style={{}} className="p-0 m-0 body_text">
                    <div className="table-responsive">
                      <Table className="table mb-0">
                        <tbody>
                          {ADTopRowItemsTemp.map((item, index) => (
                            <tr key={index} className="">
                              <td
                                style={{
                                  padding: "4.7px 20px",
                                  paddingLeft: "12px",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    verticalAlign: "middle",
                                    marginBottom: "0px",
                                    marginTop: "0px",
                                  }}
                                >
                                  <p
                                    style={{
                                      fontSize: "14px",
                                      marginBottom: "0px",
                                      marginLeft:
                                        index == 2 || index == 3
                                          ? "30px"
                                          : index == 1 ||
                                            index == 4 ||
                                            index == 5
                                          ? "15px"
                                          : "0px",
                                    }}
                                  >
                                    {item.title}
                                  </p>
                                  <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={selectedValue} // Controlled by state
                                    onChange={handleChange} // Handle changes
                                  >
                                    <FormControlLabel
                                      sx={{
                                        padding: "0px",
                                        display: index == 1 ? "none" : "block",
                                        marginBottom: "0px",
                                      }}
                                      value={item.filterKeyValue}
                                      control={
                                        <Radio
                                          size="small"
                                          sx={{ padding: "0px" }}
                                        />
                                      }
                                    />
                                  </RadioGroup>
                                  {/* <Checkbox sx={{padding:"0px",display: (index == 0) ? "none" : "block"}} 
                              defaultChecked  
                        size="small" 
                        // color="success" 
                        /> */}
                                </div>
                              </td>

                              <td
                                style={{
                                  padding: "4.7px 20px",
                                }}
                              >
                                <span
                                  style={{ float: "right", fontWeight: 700 }}
                                >
                                  {item.totalCount}
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col xl={9} lg={6} md={6}>
              {drivingDataList.map((val,i) => (
                <Row key={i}>
                  <Card style={{height:110}}>
                    <CardBody
                      className="body_text"
                      style={{ padding: "10px", height: "100px" }}
                    >
                      <div
                        className="mt-2"
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex" }}>
                          <div style={{ height: 70, width: 70 }}>
                            <span
                              style={{
                                position: "absolute",
                                height: 60,
                                width: 70,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              {/* <span
                           style={{textAlign: "center" }}
                         >
                           C1
                         </span> */}
                              <span></span>
                            </span>
                            <CircularProgressbar
                              value={val.goodDrivingPercentage}
                              text={`${val.goodDrivingPercentage == "NaN" ? "0" : val.goodDrivingPercentage}%`}
                              styles={buildStyles({
                                width: "20px",
                                textColor: "#000",
                                pathColor: "#19543e",
                                trailColor: "#0095FF",
                              })}
                            ></CircularProgressbar>
                          </div>
                          <span
                            style={{
                              height: 70,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "10px",
                              fontSize: "14px",
                              fontWeight: 700,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {/* Good<br/>Driving */}Good Driving
                            <br />
                            <span>
                              {val.goodDrivingCount} /{" "}
                              {val.drivingSummaryTotalRides}
                            </span>
                          </span>
                        </div>

                       

                        <div style={{ display: "flex" }}>
                          <div style={{ height: 70, width: 70 }}>
                            {/* <span
                         style={{
                           position: "absolute",
                           height: 60,
                           width: 70,
                           display: "flex",
                           flexDirection: "column",
                           justifyContent: "center",
                           textAlign: "center",
                         }}
                       >
                         
                         <span >
                           
                         </span>
                       </span> */}
                            <CircularProgressbar
                              value={val.quitSafeDrivingPercentage}
                              text={`${val.quitSafeDrivingPercentage == "NaN" ? "0" : val.quitSafeDrivingPercentage}%`}
                              styles={buildStyles({
                                width: "20px",
                                textColor: "#000",
                                pathColor: "#FFBF00",
                                trailColor: "#0095FF",
                              })}
                            ></CircularProgressbar>
                          </div>
                          <span
                            style={{
                              height: 70,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "10px",
                              whiteSpace: "nowrap",
                              fontSize: "14px",
                              fontWeight: 700,
                            }}
                          >
                            {/* Dangerous<br/>Driving */}Quite Safe Driving
                            <br />
                            <span>
                              {val.quietSafeDrivingCount +
                                " / " +
                                val.drivingSummaryTotalRides}
                            </span>
                          </span>
                        </div>

                      

                        <div style={{ display: "flex" }}>
                          <div style={{ height: 70, width: 70 }}>
                            <span
                              style={{
                                position: "absolute",
                                height: 60,
                                width: 70,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              {/* <span
                           style={{textAlign: "center" }}
                         >
                           C1
                         </span> */}
                              <span></span>
                            </span>
                            <CircularProgressbar
                              value={val.dangerousDrivingPercentage}
                              text={`${val.dangerousDrivingPercentage == "NaN" ? "0" : val.dangerousDrivingPercentage}%`}
                              styles={buildStyles({
                                width: "20px",
                                textColor: "#000",
                                pathColor: "#19543e",
                                trailColor: "#0095FF",
                              })}
                            ></CircularProgressbar>
                          </div>
                          <span
                            style={{
                              height: 70,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "10px",
                              fontSize: "14px",
                              fontWeight: 700,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {/* Good<br/>Driving */}Dangerous Driving
                            <br />
                            <span>
                              {val.dangerousDrivingCount} /{" "}
                              {val.drivingSummaryTotalRides}
                            </span>
                          </span>
                        </div>

                        <div style={{ display: "flex" }}>
                          <div style={{ height: 70, width: 70 }}>
                            <span
                              style={{
                                position: "absolute",
                                height: 60,
                                width: 70,
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              {/* <span
                           style={{textAlign: "center" }}
                         >
                           C1
                         </span> */}
                              <span></span>
                            </span>
                            <CircularProgressbar
                              value={val.badDrivingPercentage}
                              text={`${val.badDrivingPercentage == "NaN" ? "0" : val.badDrivingPercentage}%`}
                              styles={buildStyles({
                                width: "20px",
                                textColor: "#000",
                                pathColor: "#19543e",
                                trailColor: "#0095FF",
                              })}
                            ></CircularProgressbar>
                          </div>
                          <span
                            style={{
                              height: 70,
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              marginLeft: "10px",
                              fontSize: "14px",
                              fontWeight: 700,
                              whiteSpace: "nowrap",
                            }}
                          >
                            {/* Good<br/>Driving */} Bad Driving
                            <br />
                            <span>
                              {val.badDrivingCount} /{" "}
                              {val.drivingSummaryTotalRides}
                            </span>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Row>
                ))}
                
                <Row style={{marginTop:"-11px"}}>
                <RidesKmHrsScoreCBar
                    aggregateData={aggregateData}
                    totalDays={totalDays}
                  />

                </Row>
                  
                
              </Col>
              </Row>

              {/* <Col xl={3} lg={6} md={6}>
                <Card>
                  <CardHeader 
                    style={{
                      padding: "10px 10px",
                    }}
                    className="sub_heading cardHeader"
                  >
                    Red Zone
                    
                  </CardHeader>
                  <CardBody
                    style={{ padding: "10px", height: '205px' }}
                    className="body_text"
                  >
                    <div className="mt-3">
                    <div style={{ display: "flex" }}>
                      <span
                        style={{
                          position: "absolute",
                          height: 60,
                          width: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          textAlign: "center",
                        }}
                      >
                        <span style={{textAlign: "center" }}>
                          C5
                        </span>
                        <span >
                          {ADRideBoxCount.count[4].percentage}%
                        </span>
                      </span>
                      <div style={{ height: 70, width: 70 }}>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[4].percentage}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "block",
                            pathColor: "#FF0000",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace:'nowrap'
                        }}
                      >
                        Quite Dangerous Driving
                        <br />
                        <span >
                          {ADRideBoxCount.count[4].totalRides +
                            " / " +
                            ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>

                    <div style={{ display: "flex", marginTop: "12px" }}>
                      <div style={{ height: 70, width: 70 }}>
                        <span
                          style={{
                            position: "absolute",
                            height: 60,
                            width: 70,
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            textAlign: "center",
                          }}
                        >
                          <span>
                            C6
                          </span>
                          <span >
                            {ADRideBoxCount.count[5].percentage}%
                          </span>
                        </span>
                        <CircularProgressbar
                          value={ADRideBoxCount.count[5].percentage}
                          // text={`${15}%`}
                          styles={buildStyles({
                            width: "20px",
                            textColor: "#000",
                            pathColor: "#FF0000",
                            trailColor: "#0095FF",
                          })}
                        ></CircularProgressbar>
                      </div>
                      <span
                        style={{
                          height: 70,
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "10px",
                          whiteSpace:'nowrap'
                        }}
                      >
                        Dangerous Driving
                        <br />
                        <span >
                          {ADRideBoxCount.count[5].totalRides +
                            " / " +
                            ADRideBoxCount.totalRides}
                        </span>
                      </span>
                    </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <Row > */}

            {/* </Row> */}
         
          
        
          <OverallSummary
            filter={filters}
            receiveSummaryData={handleSummaryData}
            selectedUserValue={selectedUserValue}
            selectedValue={selectedValue}
          />

         
            {/* <OverallRiskSummary
              filter={filters}
              receiveRiskSummaryData={handleRiskSummaryData}
            /> */}

            <DrivingBehaviour
              loaderCount={setShowLoader}
              filter={filters}
              selectedUserValue={selectedUserValue}
            />
         
         
            {/* <MonthlyWeeklySummary /> */}
          </div>

          <DateTimePickerPopup
            modalOpen={modalIsOpen}
            modalClose={setIsOpen}
            setFilter={setFilter}
            setStartDateTime={setStartDateTime}
            setEndDateTime={setEndDateTime}
            filter={filters.durationFilter}
          />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OverallSummaryy;
